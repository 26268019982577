import React, { useState, useCallback } from "react"
import styled, { css } from "styled-components"
import Gallery from "react-photo-gallery"
import Media from "./Media"

import { MediaType } from "./MediaType"

interface Props {
    className?: string
    photos: MediaType[]
    columns?: number
    margin?: number
    direction?: `column` | `row`
    targetRowHeight?: number
}

const MediaGallery: React.FC<Props> = ({
    className,
    photos,
    columns = 3,
    margin = 2,
    direction = `column`,
    targetRowHeight,
}) => {
    const [selected, setSelected] = useState(null)

    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
            <Media
                selected={index === selected}
                key={key}
                margin="1px"
                index={index}
                photo={photo}
                left={left}
                top={top}
                direction={direction}
                onClick={() => setSelected(index)}
            />
        ),
        [selected, photos]
    )

    return (
        <div className={className} data-testid="media-gallery">
            <Gallery
                photos={photos}
                columns={direction === `column` ? columns : 0}
                direction={direction}
                margin={margin}
                renderImage={imageRenderer}
                targetRowHeight={targetRowHeight}
            />
        </div>
    )
}

export default styled(MediaGallery)<Props>`
    ${(props) => css`
        width: 100%;
    `}
`
