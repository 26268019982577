type LoaderFn<T> = () => Promise<T>
type CleanupFn<T> = (data: T) => void

interface QueueEntry {
    fulfill: (value?: unknown) => void
    reject: (reason?: any) => void
}

class DataLoader<T> {
    private loaderFn: LoaderFn<T>
    private cleanupFn?: CleanupFn<T> | null = null
    private loading: boolean
    private queue: QueueEntry[]

    private loadedData: T | undefined

    constructor(
        loaderFn: LoaderFn<T> | null = null,
        cleanupFn: CleanupFn<T> | null = null
    ) {
        if (typeof loaderFn !== `function`) {
            throw new Error(`Missing function for the loader`)
        }
        this.loaderFn = loaderFn
        this.cleanupFn = cleanupFn
        this.loading = false
        this.queue = []
    }

    reset() {
        if (this.queue.length > 0) {
            throw new Error(
                `Unable to reset data loader as there something waiting for the data`
            )
        }

        if (this.loadedData !== undefined && this.cleanupFn) {
            this.cleanupFn(this.loadedData)
        }

        this.loadedData = undefined
        this.loading = false
    }

    setData(newData: T) {
        this.loadedData = newData
    }

    getData() {
        return this.loadedData
    }

    async get() {
        // If it's already loaded, just return the data
        if (this.loadedData !== undefined) {
            return this.loadedData
        }

        // If it's loading, then wait for it to finish loading
        if (this.loading) {
            // Wait for it to load
            await new Promise((fulfill, reject) => {
                this.queue.push({ fulfill, reject })
            })
        }

        // If it's not loaded, then load it
        if (this.loadedData === undefined) {
            try {
                // Set the state to loading, so other's can wait for it
                this.loading = true

                // Load the data using the custom function
                this.loadedData = await this.loaderFn()

                // We have finished loading and the data is loaded
                this.loading = false

                // Notify all those waiting for the data
                for (let i = 0; i < this.queue.length; i += 1) {
                    this.queue[i].fulfill()
                }
            } catch (ex) {
                // We got an exception, tell all those waiting of that exception
                for (let i = 0; i < this.queue.length; i += 1) {
                    this.queue[i].reject(ex)
                }

                // Now throw the exception to the callee who initiated the loading
                throw ex
            } finally {
                // Whatever happens here, we can clear the queue and release the function
                this.queue = []
            }
        }

        // At this point, the data is fully loaded so we can return it
        return this.loadedData
    }
}

export default DataLoader
