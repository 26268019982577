import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    children: React.ReactNode
    selectedIndex?: number
    onChange?: (index: number) => void
    canDeselectAll?: boolean
    testId?: string
}

const IconToolbar: React.FC<Props> = ({
    className,
    children,
    selectedIndex = 0,
    onChange,
    canDeselectAll = true,
    testId = `icon-toolbar`,
}) => {
    const [_selectedIndex, _setSelectedIndex] = useState<number | null>(
        selectedIndex
    )

    useEffect(() => {
        _setSelectedIndex(selectedIndex)
    }, [selectedIndex])

    function _handleClick(index: number) {
        // Deselect if already selected, otherwise select it
        _setSelectedIndex(
            _selectedIndex === index && canDeselectAll ? null : index
        )

        onChange && onChange(index)
    }

    const items = React.Children.map(
        children,
        (child: React.ReactNode, index) =>
            React.cloneElement(
                child as React.DetailedReactHTMLElement<any, HTMLElement>,
                {
                    selected: index === _selectedIndex,
                    onClick: () => _handleClick(index),
                }
            )
    )

    return (
        <div className={className} data-testid={testId}>
            {items}
        </div>
    )
}

export default styled(IconToolbar)<Props>`
    ${({ theme }) => css`
        display: inline-block;
        border: 1px solid ${theme.iconToolbar.borderColor};
        border-radius: ${theme.iconToolbar.borderRadius};
    `}
`
