import React from "react"
import styled, { css } from "styled-components"

import Button from "../../../Button"
import Icon from "../../../Icon"

interface Props {
    ref?: React.Ref<HTMLButtonElement> | null
    forwardedRef?: React.Ref<HTMLButtonElement> | null
    className?: string
    onClick: () => void
}

const Control: React.FC<Props> = ({ forwardedRef, className, onClick }) => {
    return (
        <Button ref={forwardedRef} className={className} onClick={onClick}>
            <Icon name="add-circle" size="24px" color="#464646" />
        </Button>
    )
}

const _Control: React.ComponentType<Props> = React.forwardRef(
    (props: Props, ref?: React.Ref<HTMLButtonElement>) => (
        <Control {...props} forwardedRef={ref} />
    )
)

export default styled(_Control)<Props>``
