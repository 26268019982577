import { ContentBlock } from "draft-js"

import escapeRegExp from "lodash/escapeRegExp"

function createHeadingStrategy(prefix: string) {
    return (
        contentBlock: ContentBlock,
        callback: (begin: number, end: number) => void
    ): void => {
        // Get the number of hashes at the start of the line, plug a space then everything to the end of the line
        const regex = new RegExp(`^${escapeRegExp(prefix)} .*`, `g`)
        const text = contentBlock.getText()
        let result: RegExpExecArray | null
        while ((result = regex.exec(text)) !== null) {
            let start = result.index
            let end = start + result[0].length
            callback(start, end)
        }
    }
}

export default createHeadingStrategy
