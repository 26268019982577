import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"
import { Note } from "../../Note"

import { findBacklinks } from "./findBacklinks"
import { loadNoteBody } from "./loadNoteBody"
import { putNote } from "./putNote"
import { findNotesByTitle } from "./findNotesByTitle"
import { replaceLinkInNoteBody, extractMetadataFromNoteBody } from "./Utils"

interface ProcessNoteTitleChangeProps {
    collectionUUID: UUID
    oldTitle: string
    newTitle: string
    db: FlowtelicDatabase
}

export async function processNoteTitleChange({
    collectionUUID,
    oldTitle,
    newTitle,
    db,
}: ProcessNoteTitleChangeProps) {
    // First make sure that there is no other note with the old title
    const notes = await findNotesByTitle({
        db,
        collectionUUID,
        title: oldTitle,
    })

    if (notes.length > 0) {
        // Duplicate note, don't auto update links
        return
    }

    // First get all the notes that link to this
    const backlinks = await findBacklinks({
        db,
        collectionUUID,
        title: oldTitle,
    })
    // console.log(`finding backlinks for`, oldTitle, backlinks)

    // Now go through each backlink and update the title contents
    for (let i = 0; i < backlinks.length; i += 1) {
        const note = backlinks[i]
        const noteBody = await loadNoteBody({ uuid: note.uuid, db })
        if (noteBody !== null) {
            const updatedNoteBody = replaceLinkInNoteBody({
                noteBody,
                oldTitle,
                newTitle,
            })

            // Extract the new metadata
            const meta = extractMetadataFromNoteBody(updatedNoteBody.body)
            const updatedNote: Note = {
                ...note,
                title: meta.title,
                preview: meta.preview,
            }

            // Now put the updated note back
            await putNote({
                db,
                note: updatedNote,
                noteBody: updatedNoteBody,
            })
        }
    }
}
