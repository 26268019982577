import React, { ReactNode, useRef, useEffect } from "react"
import styled from "styled-components"

import { SuggestionStore } from "../../Store"

export interface SuggestionsPortalProps {
    className?: string
    children?: ReactNode

    //removed props
    decoratedText?: unknown
    dir?: unknown
    entityKey?: unknown
    getEditorState?: unknown
    offsetKey: string
    setEditorState?: unknown
    contentState?: unknown
    blockKey?: unknown

    store: SuggestionStore
}

const Span = styled.span`
    // border: 1px dotted grey;
    // display: inline-block;
    // padding: 5px;
    // margin: 3px 3px;
    // border: 1px solid rgba(255, 255, 255, 75);
    // border-radius: 10px;
`

const SuggestionsPortal: React.FC<SuggestionsPortalProps> = (props) => {
    const {
        store,
        decoratedText, // eslint-disable-line @typescript-eslint/no-unused-vars
        dir, // eslint-disable-line @typescript-eslint/no-unused-vars
        entityKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        getEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
        offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        setEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
        contentState, // eslint-disable-line @typescript-eslint/no-unused-vars
        blockKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...otherProps
    } = props

    // This is our search portal reference
    const searchPortal = useRef<HTMLSpanElement>()

    // This function will set the element into the reference
    const searchPortalRef = (element: HTMLSpanElement): void => {
        searchPortal.current = element
    }

    const updatePortalClientRect = (
        currentProps: SuggestionsPortalProps
    ): void => {
        // Set the callback for the offset key to update the rectangle
        store.updatePortalClientRect(currentProps.offsetKey, () => {
            const clientRect = searchPortal.current?.getBoundingClientRect()
            return clientRect
        })
    }

    // When inputting Japanese characters (or any complex alphabet which requires
    // hitting enter to commit the characters), that action was causing a race
    // condition when we used UNSAFE_componentWillMount. By using componentDidMount
    // instead of UNSAFE_componentWillMount, the component will unmount unregister and
    // then properly mount and register after. Prior to this change,
    // UNSAFE_componentWillMount would not fire after componentWillUnmount even though it
    // was still in the DOM, so it wasn't re-registering the offsetkey.

    // On mount, we are opening the dialog
    // On unmount, we are hiding the dialog
    useEffect(() => {
        // We've just mounted the decorator, so we want to open up the dialog
        store.registerOffsetKey(props.offsetKey)
        store.setIsOpen(true)

        // props.store.register(props.offsetKey)
        // props.store.setIsOpened(true)
        updatePortalClientRect(props)

        // trigger a re-render so the suggestions box becomes active
        store.rerender()
        //props.store.setEditorState!(props.store.getEditorState!())

        return () => {
            store.deregisterOffsetKey(props.offsetKey)
            store.setIsOpen(false)
            //props.store.unregister(props.offsetKey)
            //props.store.setIsOpened(false)
        }
    }, [])

    // Run this every time
    useEffect(() => {
        updatePortalClientRect(props)
    })

    return (
        <Span ref={searchPortalRef} {...otherProps}>
            {props.children}
        </Span>
    )
}

export { SuggestionsPortal }
