import { NoteBody } from "../../../Note"

import escapeRegExp from "lodash/escapeRegExp"
import defaultRegExpWithWhitespace from "../../../../EditorPlugins/RelatedEntity/defaultRegExpWithWhitespace"

const regex = new RegExp(
    `${escapeRegExp(`[[`)}${defaultRegExpWithWhitespace}${escapeRegExp(`]]`)}`,
    `g`
)

export default function extractLinksFromNoteBody(noteBody: NoteBody): string[] {
    const links: Set<string> = new Set()
    noteBody.body.blocks.forEach((block) => {
        let matchArr
        while ((matchArr = regex.exec(block.text)) !== null) {
            const match = matchArr[0]
            // Remove the [[ and ]]
            const title = match.substr(2, match.length - 4)
            links.add(title.toLowerCase())
        }
    })
    return Array.from(links)
}
