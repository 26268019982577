function formatAge(date: Date) {
    const now = Date.now() / 1000
    const then = date.getTime() / 1000

    let diff = Math.floor(now - then)
    if (diff < 0) {
        return ``
    }

    if (diff < 60) {
        return `${diff}s` // seconds
    }

    diff = Math.floor(diff / 60) // convert to minutes
    if (diff < 60) {
        return `${diff}m` // minutes
    }

    diff = Math.floor(diff / 60) // convert to hours
    if (diff < 24) {
        return `${diff}h` // hours
    }

    diff = Math.floor(diff / 24) // Convert to days
    if (diff < 7) {
        return `${diff}d` // days
    }

    diff = Math.floor(diff / 7) // Convert to weeks
    if (diff <= 4) {
        return `${diff}W` // weeks
    }

    // Okay now we need to find out the number of months
    const nowDate = new Date(now * 1000)
    const thenDate = new Date(then * 1000)
    const nowMonths = nowDate.getFullYear() * 12 + nowDate.getMonth()
    const thenMonths = thenDate.getFullYear() * 12 + thenDate.getMonth()
    diff = nowMonths - thenMonths // Convert to months

    if (diff < 12) {
        return `${diff === 0 ? 1 : diff}M` // months
    }

    // Otherwise we'll show it in years
    const years = Math.floor(diff / 12)
    return `${years}Y` // years
}

export default formatAge
