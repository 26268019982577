import { EditorState, Modifier } from "draft-js"

function replaceText(editorState: EditorState, bufferText: string) {
    const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        bufferText
    )
    return EditorState.push(editorState, contentState, `insert-characters`)
}

export default replaceText
