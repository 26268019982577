import React from "react"
import styled, { css } from "styled-components"

import { Button } from "ui"

const Card = styled.div`
    ${({ theme }) => css`
        ${theme.focus.sessionCard};

        padding: 20px;

        > ${Button} {
            margin-top: 10px;
        }
    `}
`

const Label = styled.h2`
    font-size: 16px;
`

const Duration = styled.span`
    ${({ theme }) => css`
        color: ${theme.palette.textSecondary};
        display: block;
        font-size: 14px;
    `})
`

const NoteCount = styled.span`
    ${({ theme }) => css`
        color: ${theme.palette.textSecondary};
        display: block;
        font-size: 12px;
    `})
`

interface SessionCardProps {
    label: string
    durationMins?: number | null
    noteCount?: number
    onStart: () => void
}

const SessionCard: React.FC<SessionCardProps> = ({
    label,
    durationMins = null,
    noteCount = 0,
    onStart,
}) => {
    return (
        <Card>
            <Label>{label}</Label>
            <Duration>
                {durationMins} {durationMins !== 1 ? `mins` : `min`}
            </Duration>
            <NoteCount>
                {noteCount} {noteCount === 1 ? `Note` : `Notes`}
            </NoteCount>
            <Button primary onClick={onStart} disabled={noteCount === 0}>
                Start
            </Button>
        </Card>
    )
}

export default SessionCard
