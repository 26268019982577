import { FlowtelicDatabase } from "../../../Data/Database"
import { ObservableItem } from "../../../Observable"
import { UUID } from "../../../Data/UUID"
import { Note, NoteBody } from "../../../Data/Note"
import { NotesManager } from "../NotesManager"
import { DataLoader } from "../../../Utils"

interface NoteItem {
    note?: Note
    noteBody?: NoteBody
}

class NoteView extends ObservableItem<NoteItem> {
    private db: FlowtelicDatabase
    private notesManager: NotesManager
    public uuid: UUID
    private cache: DataLoader<NoteItem | null>

    public constructor(
        db: FlowtelicDatabase,
        notesManager: NotesManager,
        uuid: UUID
    ) {
        super()
        this.db = db
        this.notesManager = notesManager
        this.uuid = uuid

        this.cache = new DataLoader<NoteItem | null>(async () => {
            return await db.loadNote({ uuid: this.uuid })
        })
    }

    public dispose() {
        this.notesManager.removeNoteView(this)
    }

    public get() {
        return this.cache.get()
    }

    public update(
        { note, noteBody }: { note?: Note; noteBody?: NoteBody },
        isSelfUpdate: boolean
    ) {
        // Only update if the note is for this view
        if (this.uuid === note?.uuid || this.uuid === noteBody?.uuid) {
            const cachedData = this.cache.getData()
            if (cachedData) {
                // Update the cached copy
                if (note !== undefined) {
                    cachedData.note = note
                }

                if (noteBody !== undefined) {
                    cachedData.noteBody = noteBody
                }
            }

            this.fireItemUpdatedEvent({ note, noteBody }, isSelfUpdate)
        }
    }

    public remove(uuid: UUID, isSelfUpdate: boolean) {
        if (this.uuid === uuid) {
            const noteItem = this.cache.getData()
            this.cache.setData(null)
            noteItem && this.fireItemDeletedEvent(noteItem, isSelfUpdate)
        }
    }
}

export { NoteView }
export default NoteView
