import { FlowtelicDatabase } from "../../../Data/Database"
import { ObservableItemList } from "../../../Observable"
import { Collection } from "../../../Data/Collection"
import { UUID } from "../../../Data/UUID"
import DataLoader from "../../../Utils/DataLoader"

export interface CollectionListEntry {
    uuid: UUID
    sortKey: string
}

interface CollectionCache {
    list: CollectionListEntry[]
    map: Map<UUID, Collection>
}

interface CollectionsFilter {
    deleted?: boolean
}

function sortCollections(collections: CollectionListEntry[]) {
    // Sort the collection based on the name
    collections.sort((a, b) => {
        if (a.sortKey < b.sortKey) return -1
        if (a.sortKey > b.sortKey) return 1
        return 0
    })
}

function matchesCollectionFilter(
    filter: CollectionsFilter,
    collection: Collection
) {
    let include = false
    if (filter.deleted === undefined) {
        include = true
    } else {
        include = filter.deleted === (collection.deleted ? true : false) // deleted may be undefined
    }
    return include
}

function getCollectionSortKey(collection: Collection) {
    return collection.name.toLowerCase()
}

class CollectionView extends ObservableItemList<
    Collection,
    CollectionListEntry
> {
    private db: FlowtelicDatabase
    private cache: DataLoader<CollectionCache>
    private filter: CollectionsFilter

    public constructor(
        db: FlowtelicDatabase,
        filter: CollectionsFilter = { deleted: false }
    ) {
        super()
        this.db = db
        this.filter = filter
        this.cache = new DataLoader<CollectionCache>(async () => {
            const collections = await db.getCollections()
            const list: CollectionListEntry[] = []
            const map = new Map<UUID, Collection>()

            // Populate the list and items
            for (let i = 0; i < collections.length; i += 1) {
                const collection = collections[i]
                if (matchesCollectionFilter(this.filter, collection)) {
                    list.push({
                        uuid: collection.uuid,
                        sortKey: getCollectionSortKey(collection),
                    })
                    map.set(collection.uuid, collection)
                }
            }

            sortCollections(list)

            return { list, map }
        })
    }

    public async list() {
        const cache = await this.cache.get()
        return cache.list
    }

    public async get(uuid: UUID) {
        const cache = await this.cache.get()
        return cache.map.get(uuid) || null
    }

    public async insert(collection: Collection, isSelfUpdate: boolean) {
        if (!matchesCollectionFilter(this.filter, collection)) {
            return
        }

        const cache = await this.cache.get()

        // Add it to the list
        cache.list.push({
            uuid: collection.uuid,
            sortKey: getCollectionSortKey(collection),
        })
        sortCollections(cache.list)

        // Add it to the map
        cache.map.set(collection.uuid, collection)

        this.fireListChangeEvent(cache.list, isSelfUpdate)
    }

    public async remove(uuid: UUID, isSelfUpdate: boolean) {
        const cache = await this.cache.get()

        // Remove it from the list
        const index = cache.list.findIndex((c) => c.uuid === uuid)
        const item = cache.map.get(uuid)
        if (index !== -1) {
            cache.list.splice(index, 1)
        }

        // Remove it from the map as it's removed
        cache.map.delete(uuid)

        index !== -1 && this.fireListChangeEvent(cache.list, isSelfUpdate)
        item && this.fireItemDeletedEvent(item, isSelfUpdate)
    }

    public async update(newCollection: Collection, isSelfUpdate: boolean) {
        if (!matchesCollectionFilter(this.filter, newCollection)) {
            // Updated and now doesn't match the filter, so remove it
            await this.remove(newCollection.uuid, isSelfUpdate)
            return
        }

        const cache = await this.cache.get()

        // Get the old collection
        const oldCollection = cache.map.get(newCollection.uuid)
        if (oldCollection) {
            const oldSortKey = getCollectionSortKey(oldCollection)
            const newSortKey = getCollectionSortKey(newCollection)

            // Update the collection
            cache.map.set(newCollection.uuid, newCollection)

            if (oldSortKey !== newSortKey) {
                // We need to resort the list
                const index = cache.list.findIndex(
                    (c) => c.uuid === newCollection.uuid
                )
                if (index !== -1) {
                    cache.list[index].sortKey = newSortKey
                    sortCollections(cache.list)

                    this.fireListChangeEvent(cache.list, isSelfUpdate)
                }
            }

            this.fireItemUpdatedEvent(newCollection, isSelfUpdate)
        } else {
            // Looks like it was changed to match the filter
            // Add it to the list
            cache.list.push({
                uuid: newCollection.uuid,
                sortKey: getCollectionSortKey(newCollection),
            })
            sortCollections(cache.list)

            // Add it to the map
            cache.map.set(newCollection.uuid, newCollection)

            this.fireListChangeEvent(cache.list, isSelfUpdate)
        }
    }
}

export { CollectionView }
export default CollectionView
