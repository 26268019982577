import React, { useMemo } from "react"
import { EditorState } from "draft-js"
import { EditorPlugin, EditorCommand } from "@draft-js-plugins/editor"
import {
    KeyBindingFn,
    KeyReturnFn,
    OnEditorStateChange,
    LookupSuggestionsProps,
    LookupSuggestionsModalProps,
} from "./types"

import {
    relatedItemDecoratorStrategy,
    controlCharacterStrategy,
    relatedItemRegexStrategy,
    suggestionLookupStrategy,
    // relatedItemRegexStrategy,
} from "./Strategies"

import defaultRegExp from "./defaultRegExp"
import defaultRegExpWithWhitespace from "./defaultRegExpWithWhitespace"

import * as Decorators from "./Decorators"
import { SuggestionStore } from "./Store"

import LookupSuggestions from "./LookupSuggestions"

type RelatedEntityPlugin = EditorPlugin & {
    LookupSuggestions: React.ComponentType
}

interface SuggestionsCallbacks {
    onEditorStateChange?: OnEditorStateChange
    keyBindingFn?: KeyBindingFn
    keyReturnFn?: KeyReturnFn
}

interface PluginConfig {
    suggestionTrigger?: string
    prefix?: string
    suffix?: string
    supportWhitespace?: boolean
    entityType: string
    LookupSuggestionsComponent: React.ComponentType<LookupSuggestionsModalProps>
    Decorator: React.ComponentType<any>
    decorateControlCharacters?: boolean
    ControlCharacterDecorator?: React.ComponentType<any>
}

export default function useRelatedEntity(
    config: PluginConfig
): RelatedEntityPlugin {
    const plugin = useMemo(() => {
        const trigger = config.prefix || config.suggestionTrigger
        if (!trigger) {
            throw new Error(
                `You must supply at least one suggestion or one prefix trigger`
            )
        }

        // Create a store for the plugin
        const suggestionStore = new SuggestionStore()

        // Wrap the suggestions component in a provider
        // which provides a store for the state
        const SuggestionsPortalWithStore: React.FC<Decorators.SuggestionsPortalProps> = (
            props
        ) => {
            return (
                <Decorators.SuggestionsPortal
                    {...props}
                    store={suggestionStore}
                />
            )
        }

        const callbacks: SuggestionsCallbacks = {
            onEditorStateChange: undefined,
            keyBindingFn: undefined,
            keyReturnFn: undefined,
        }

        function registerEditorStateCallback(callback: OnEditorStateChange) {
            callbacks.onEditorStateChange = callback

            // Return a cleanup function
            return () => {
                callbacks.onEditorStateChange = undefined
            }
        }

        function registerKeyBindingCallback(
            keyBindingFn: KeyBindingFn,
            keyReturnFn: KeyReturnFn
        ) {
            callbacks.keyBindingFn = keyBindingFn
            callbacks.keyReturnFn = keyReturnFn

            // Return a cleanup function
            return () => {
                callbacks.keyBindingFn = undefined
                callbacks.keyReturnFn = undefined
            }
        }

        const LookupSuggestionsWithStore: React.FC = () => {
            return (
                <LookupSuggestions
                    store={suggestionStore}
                    suggestionTrigger={trigger}
                    // entityMutability="IMMUTABLE"
                    // mentionPrefix=""
                    registerEditorStateChangeCallback={
                        registerEditorStateCallback
                    }
                    registerKeyBindingCallback={registerKeyBindingCallback}
                    renderComponent={config.LookupSuggestionsComponent}
                />
            )
        }

        // const StatefulSuggestionsPortal: React.FC<SuggestionsPortalProps> = (
        //     props
        // ) => {
        //     return <Decorators.SuggestionsPortal {...props} />
        // }

        const decorators = [
            {
                // strategy: relatedItemDecoratorStrategy({
                //     entityType: config.entityType,
                // }), // Find all entries, e.g. @mention or [[link]]
                strategy: relatedItemRegexStrategy({
                    prefix: trigger,
                    suffix: config.suffix || ``,
                    regex: config.supportWhitespace
                        ? defaultRegExpWithWhitespace
                        : defaultRegExp,
                    decorateControlCharacters:
                        config.decorateControlCharacters === false
                            ? false
                            : true,
                }),
                component: config.Decorator, // Then decorate it with this
            },
            {
                strategy: suggestionLookupStrategy({
                    trigger: trigger,
                    supportWhitespace: config.supportWhitespace === true,
                    regex: config.supportWhitespace
                        ? defaultRegExpWithWhitespace
                        : defaultRegExp,
                }),
                component: SuggestionsPortalWithStore,
            },
        ]

        if (config.ControlCharacterDecorator) {
            // Add the prefix
            decorators.push({
                strategy: controlCharacterStrategy({
                    isPrefix: true,
                    supportWhitespace: config.supportWhitespace === true,
                    prefix: trigger,
                    suffix: config.suffix || ``,
                    regex: defaultRegExp,
                }),
                component: config.ControlCharacterDecorator,
            })

            if (config.suffix) {
                // Add the suffix
                decorators.push({
                    strategy: controlCharacterStrategy({
                        isPrefix: false,
                        supportWhitespace: config.supportWhitespace === true,
                        prefix: trigger,
                        suffix: config.suffix || ``,
                        regex: defaultRegExp,
                    }),
                    component: config.ControlCharacterDecorator,
                })
            }
        }

        const plugin: RelatedEntityPlugin = {
            LookupSuggestions: LookupSuggestionsWithStore,
            decorators,
            initialize: ({ getEditorState, setEditorState }) => {
                // Register the getter and setter to our store
                suggestionStore.init({ getEditorState, setEditorState })
            },
            onChange: (editorState: EditorState) => {
                if (callbacks.onEditorStateChange) {
                    return callbacks.onEditorStateChange(editorState)
                } else {
                    return editorState
                }
            },
            keyBindingFn: (keyboardEvent: React.KeyboardEvent) => {
                return (
                    callbacks.keyBindingFn &&
                    callbacks.keyBindingFn(keyboardEvent)
                )
            },
            handleReturn: (keyboardEvent: React.KeyboardEvent) => {
                return (
                    (callbacks.keyReturnFn &&
                        callbacks.keyReturnFn(keyboardEvent)) ||
                    `not-handled`
                )
            },
        }

        return plugin
    }, [])

    return plugin
}
