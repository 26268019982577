import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import {
    SearchBox,
    Button,
    ButtonMenu,
    MenuItem,
    Icon,
    DropdownMenu,
    DropdownMenuConfig,
} from "ui"
import FocusButton from "../FocusMode/FocusButton"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import { useCollectionsView } from "../../Providers/CollectionsViewProvider"
import { useCollectionViewState } from "../../Providers/CollectionViewProvider"
import { useCollectionViewDispatch } from "../../Providers/CollectionViewProvider"
import { useFilteredNotes } from "../../Providers/FilteredNotesProvider"
import {
    useCollectionConfig,
    CollectionViewConfig,
} from "../../Data/Collection"
import { useCollectionFocusSessions } from "../../Providers/FocusModeSessionsProvider"
import { useFocusSessionDispatch } from "../../Providers/FocusModeViewProvider"
import { getDefaultWorkflowStateForNoteType } from "../../Utils"
import { FocusModeSession } from "../../Managers/NotesManager/FocusModeSessionView"

const NewNoteButton = styled(Button)`
    padding: 4px;
`

const HeaderBar = styled.div`
    ${({ theme }) => css`
        ${theme.header.panel};

        width: 100%;
        display: grid;
        grid-template-columns: 250px auto max-content;
        align-items: center;
        position: relative;
        z-index: 1;
    `}
`

const SearchWithNewNote = styled.div`
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: 5px;
    padding: 0 15px;
`

const Navigation = styled.div`
    > ${DropdownMenu} {
        margin: 0 3px;
    }
`

const RightActions = styled.div`
    display: block;
    margin-right: 10px;
`

const NavLink = styled.button<{ active?: boolean }>`
    ${({ theme, active = false }) => css`
        border: 1px solid rgba(255, 255, 255, 0);
        background: none;
        user-select: none;
        ${active && theme.header.navLinkActive};
        ${!active && theme.header.navLinkInactive};
        padding: 5px 10px;
        transition: border 0.2s linear;
        text-decoration: none;
        outline: none;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 10px;

        &:focus {
            ${theme.controlFocus};
        }

        &:hover {
            ${theme.header.navLinkHover};
        }
    `}
`
interface HeaderProps {
    className?: string
}

const Header: React.FC<HeaderProps> = ({ className }) => {
    const notesManager = useNotesManager()
    const collectionsView = useCollectionsView()
    const viewDispatch = useCollectionViewDispatch()
    const viewState = useCollectionViewState()
    const {
        state: filteredNotesState,
        dispatch: filteredNotesDispatch,
    } = useFilteredNotes()
    const focusModeDispatch = useFocusSessionDispatch()

    const collectionUUID = viewState?.collectionUUID || null

    const focusSessions = useCollectionFocusSessions(collectionUUID)

    const config = useCollectionConfig(collectionUUID)
    const views = config?.views || []

    let activeView: CollectionViewConfig | null = null
    views.forEach((view) => {
        if (view.name === viewState?.activeViewState?.viewName) {
            activeView = view
        } else {
            const foundChildView =
                view.subViews?.find(
                    (subView) =>
                        subView.name === viewState?.activeViewState?.viewName
                ) || null
            if (foundChildView !== null) {
                activeView = foundChildView
            }
        }
    })
    if (activeView === null && views.length > 0) {
        activeView = views[0]
    }

    const menuConfig = useMemo(() => {
        if (config === null) {
            return null
        }

        const menuConfig: DropdownMenuConfig[] = config.views.map((view) => {
            let parentActive = false
            if (view.name === activeView?.name) {
                parentActive = true
            }

            let children: DropdownMenuConfig[] = []
            if (view.subViews && view.subViews.length > 0) {
                children = view.subViews.map((subView) => {
                    let active = false
                    if (subView.name === activeView?.name) {
                        parentActive = true
                        active = true
                    }
                    return {
                        name: subView.name,
                        label: subView.label,
                        active,
                        children: [],
                    }
                })
            }
            return {
                name: view.name,
                label: view.label,
                active: parentActive,
                children,
            }
        })

        return menuConfig
    }, [config?.views, activeView])

    function handleNewNote() {
        ;(async () => {
            if (
                notesManager === null ||
                collectionUUID === null ||
                activeView === null
            ) {
                return
            }

            // Create the new blank note
            const { note } = await notesManager.createNote({
                collectionUUID: collectionUUID,
                noteType: activeView.defaultNoteType,
                workflowState: getDefaultWorkflowStateForNoteType(
                    config,
                    activeView.defaultNoteType
                ),
            })

            // Now open it
            viewDispatch({ type: `openNote`, uuid: note.uuid })
        })()
    }

    function handleSearchChange(value: string) {
        // Make sure the sidebar is open
        if (collectionsView.state.noteListVisible === false) {
            collectionsView.dispatch({ type: `showNoteList` })
        }
        filteredNotesDispatch({ type: `setSearchText`, searchText: value })
    }

    function handleNavigation(name: string) {
        viewDispatch({
            type: `setActiveView`,
            name,
        })
    }

    function handleFocusSessionStart(session: FocusModeSession) {
        if (collectionUUID === null) {
            return
        }

        focusModeDispatch({
            type: `setFocusSession`,
            collectionUUID: session.collectionUUID,
            sessionName: session.config.name,
        })
    }

    return (
        <HeaderBar className={className}>
            <SearchWithNewNote>
                <SearchBox
                    placeholder="Search notes"
                    value={filteredNotesState.filter?.searchText || ``}
                    onChange={handleSearchChange}
                />
                <NewNoteButton
                    icon={<Icon name="draft" size="24px" />}
                    subtle
                    onClick={handleNewNote}
                />
            </SearchWithNewNote>
            <Navigation>
                {menuConfig &&
                    menuConfig.map((menuItem) => (
                        <DropdownMenu
                            key={menuItem.label}
                            config={menuItem}
                            onClick={handleNavigation}
                        />
                    ))}
            </Navigation>
            <RightActions>
                <FocusButton
                    focusSessions={focusSessions}
                    onSessionStart={handleFocusSessionStart}
                />
            </RightActions>
        </HeaderBar>
    )
}

export default styled(Header)``
