import React from "react"
import styled, { css } from "styled-components"

import Button from "../../Button"
import Icon from "../../Icon"

interface Props {
    className?: string
    isOpen?: boolean
    onOpenChange?: (isOpen: boolean) => void
    secondary?: boolean
}

const Heading: React.FC<Props> = ({
    className,
    children,
    isOpen = true,
    onOpenChange,
}) => {
    const iconName = isOpen ? `arrow-down-s` : `arrow-right-s`

    function _handleClick() {
        onOpenChange && onOpenChange(!isOpen)
    }

    return (
        <Button className={className} onClick={_handleClick}>
            <div>
                <span>{children}</span>
                <Icon name={iconName} size="18px" />
            </div>
        </Button>
    )
}

export default styled(Heading)<Props>`
    ${({ theme, isOpen = true, secondary = false }) => css`
        width: 100%;
        padding: 10px;

        ${!secondary &&
        css`
            text-align: left;
            background-color: ${theme.section.primaryBackgroundColor};
            color: ${theme.section.primaryColor};
            font-size: ${theme.section.primaryFontSize};

            ${!isOpen &&
            css`
                border-bottom: ${theme.separator.color};
            `}

            > div {
                display: grid;
                align-items: center;
                grid-template-columns: auto max-content;
            }
        `}

        ${secondary &&
        css`
            background-color: ${theme.section.secondaryBackgroundColor};
            color: ${theme.section.secondaryColor};
            font-size: ${theme.section.primaryFontSize};

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `}
    `}
`
