import { NoteYamlConfig } from "./types"
import yaml from "js-yaml"

const defaultConfig = {
    type: `Permanent`,
    workflowState: null,
}

function extractConfigFromLines(lines: string[]): NoteYamlConfig {
    // If the lines start with `---` and have a closing `---` then the bit in between is the yaml config

    // Find the start line
    const start = lines.indexOf(`---`)
    if (start !== 0) {
        return defaultConfig
    }

    // Find the end
    const end = lines.indexOf(`---`, 1)
    if (end === -1) {
        // No end config
        return defaultConfig
    }

    // extract the content between
    const yamlLines: string[] = []
    for (let i = start + 1; i < end; i += 1) {
        yamlLines.push(lines[i])
    }

    // Now we want to strip out the config and return the rest of the markdown, but let's trim all blank lines at the start
    let contentStartIndex = end + 1
    for (let i = contentStartIndex; i < lines.length; i += 1) {
        if (lines[i] === ``) {
            contentStartIndex++
        } else {
            break
        }
    }

    // Now remove all the items before the content start index
    lines.splice(0, contentStartIndex)

    try {
        const config = yaml.load(yamlLines.join(`\n`))

        if (typeof config === `object`) {
            return {
                ...defaultConfig,
                ...config,
            }
        } else {
            return defaultConfig
        }
    } catch (ex) {
        console.warn(
            `Could not parse YAML content from markdown`,
            yamlLines.join(`\n`)
        )
        return defaultConfig
    }
}

export default extractConfigFromLines
