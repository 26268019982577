import React, { ReactNode } from "react"
import styled from "styled-components"

export interface HashtagProps {
    className?: string
    children?: ReactNode

    //removed props
    decoratedText?: unknown
    dir?: unknown
    entityKey?: unknown
    getEditorState?: unknown
    offsetKey?: unknown
    setEditorState?: unknown
    contentState?: unknown
    blockKey?: unknown
}

const Span = styled.span`
    color: #5e93c5;
    // display: inline-block;
    // padding: 5px;
    // margin: 3px 3px;
    // border: 1px solid rgba(255, 255, 255, 75);
    // border-radius: 10px;
`

const HashtagDecorator: React.FC<HashtagProps> = (props) => {
    const {
        decoratedText, // eslint-disable-line @typescript-eslint/no-unused-vars
        dir, // eslint-disable-line @typescript-eslint/no-unused-vars
        entityKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        getEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
        offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        setEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
        contentState, // eslint-disable-line @typescript-eslint/no-unused-vars
        blockKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...otherProps
    } = props

    return <Span {...otherProps} />
}

export default HashtagDecorator
