import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"

export interface LoadNoteProps {
    uuid: UUID
    db: FlowtelicDatabase
}

export async function loadNote({ uuid, db }: LoadNoteProps) {
    const start = new Date().getTime()
    const [note, noteBody] = await Promise.all([
        db.notes.get(uuid),
        db.noteBody.get(uuid),
    ])

    console.log(`Duration:`, new Date().getTime() - start)
    return note === null ? null : { note, noteBody }
}
