import React from "react"
import styled from "styled-components"
import { FormField, TextInput, Icon, Button } from "ui"
import { useAccountForm } from "../../Hooks"

const NextButton = styled(Button)`
    width: 100%;
`

const BackButton = styled(Button)`
    width: 100%;
`

const Buttons = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;
`

const Setup2: React.FC = () => {
    const [{ processing, siteAddress, siteName }, dispatch] = useAccountForm()

    let nextEnabled = true
    let siteAddressIcon = null

    if (siteAddress.status === `processing`) {
        nextEnabled = false
        siteAddressIcon = (
            <Icon
                name="spinner-quarter"
                size="18px"
                subtle
                spin
                spinSpeed={1}
            />
        )
    } else if (siteAddress.status === `success`) {
        siteAddressIcon = <Icon name="check" size="18px" success />
    } else if (siteAddress.status === `error`) {
        nextEnabled = false
        siteAddressIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    function _handleSiteAddressChange(value: string) {
        dispatch({
            type: `set`,
            field: `siteAddress`,
            value: value,
        })
    }

    function _handleSiteNameChange(value: string) {
        dispatch({
            type: `set`,
            field: `siteName`,
            value: value,
        })
    }

    return (
        <>
            <h1>Create your Flowtelic website</h1>

            <FormField
                label="Site address"
                error={siteAddress.error}
                helpText="You can upgrade to a custom domain later."
            >
                <TextInput
                    name="siteAddress"
                    value={siteAddress.value}
                    suffix=".flowtelic.io"
                    onChange={_handleSiteAddressChange}
                    icon={siteAddressIcon}
                />
            </FormField>

            <FormField
                label="What would you like to call your site?"
                error={siteName.error}
            >
                <TextInput
                    name="siteName"
                    value={siteName.value}
                    onChange={_handleSiteNameChange}
                />
            </FormField>

            <Buttons>
                <BackButton
                    secondary
                    cta
                    onClick={() =>
                        dispatch({
                            type: `setContext`,
                            formType: `signupUsernamePassword`,
                        })
                    }
                >
                    Back
                </BackButton>
                <NextButton
                    primary
                    cta
                    onClick={() => {
                        return
                    }}
                    disabled={!nextEnabled}
                >
                    Next
                </NextButton>
            </Buttons>
        </>
    )
}

export default Setup2
