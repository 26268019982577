import { ContentBlock } from "draft-js"
import linkifyIt from "linkify-it"
import tlds from "tlds"

const linkify = linkifyIt()
linkify.tlds(tlds)

export default (
    contentBlock: ContentBlock,
    callback: (begin: number, end: number) => void
): void => {
    const links = linkify.match(contentBlock.get(`text`))
    if (typeof links !== `undefined` && links !== null) {
        for (let i = 0; i < links.length; i += 1) {
            callback(links[i].index, links[i].lastIndex)
        }
    }
}
