import { gql, useLazyQuery } from "@apollo/client"
import { useDelayedEffect } from "../../../Hooks"

const IS_USERNAME_TAKEN = gql`
    query isUsernameTaken($username: String!) {
        isUsernameTaken(username: $username)
    }
`

interface DataResult {
    isUsernameTaken?: boolean
}

function useIsUsernameTaken({ username }: { username: string | null }) {
    const [query, state] = useLazyQuery<DataResult>(IS_USERNAME_TAKEN)

    useDelayedEffect(
        () => {
            if (username !== null) {
                query({
                    variables: {
                        username,
                    },
                })
            }
        },
        300,
        [username]
    )

    return {
        error: state.error || null,
        loading: state.loading,
        isUsernameTaken: state.data?.isUsernameTaken === true,
    }
}

export default useIsUsernameTaken
