import React, { useReducer, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import { Note, NoteBody } from "../../Data/Note"
import { NoteView } from "../../Managers/NotesManager"
import { useNotesManager } from "../NotesManagerProvider"

interface NoteState {
    uuid: UUID | null
    note: Note | null
    noteBody: NoteBody | null
    view: NoteView | null
    reloadTrigger: number
}

const defaultState: NoteState = {
    uuid: null,
    note: null,
    noteBody: null,
    view: null,
    reloadTrigger: 0,
}

type Action =
    | { type: "setView"; view: NoteView }
    | {
          type: "setNote"
          uuid: UUID | null
          note?: Note | null
          noteBody?: NoteBody | null
          triggerReload: boolean
      }
type Dispatch = (action: Action) => void

export const NoteStateContext = React.createContext<NoteState>(defaultState)
export const NoteDispatchContext = React.createContext<Dispatch>(() => {
    return
})

function reducer(state: NoteState, action: Action) {
    if (action.type === `setView`) {
        return {
            ...state,
            view: action.view,
        }
    } else if (action.type === `setNote`) {
        const newState: NoteState = { ...state }
        if (action.note !== undefined) {
            newState.note = action.note
        }
        if (action.noteBody !== undefined) {
            newState.noteBody = action.noteBody
        }
        if (action.triggerReload) {
            newState.reloadTrigger++
        }
        newState.uuid = action.uuid
        return newState
    } else {
        return state
    }
}

interface NoteProviderProps {
    uuid: string
}

export const NoteProvider: React.FC<NoteProviderProps> = ({
    children,
    uuid,
}) => {
    const notesManager = useNotesManager()
    const [state, dispatch] = useReducer(reducer, defaultState)

    useEffect(() => {
        if (notesManager === null) {
            return
        }

        const view = notesManager.createNoteView(uuid)
        dispatch({ type: `setView`, view })

        // Now fetch the note and body
        view.get().then((item) => {
            if (item !== null) {
                dispatch({
                    type: `setNote`,
                    uuid,
                    note: item.note,
                    noteBody: item.noteBody,
                    triggerReload: false,
                })
            } else {
                dispatch({
                    type: `setNote`,
                    uuid,
                    note: null,
                    noteBody: null,
                    triggerReload: false,
                })
            }
        })

        const unsubscribe = view.watchItem((item, eventType, isSelfUpdate) => {
            if (eventType === `deleted`) {
                dispatch({
                    type: `setNote`,
                    uuid,
                    note: null,
                    noteBody: null,
                    triggerReload: false,
                })
            } else if (eventType === `updated`) {
                dispatch({
                    type: `setNote`,
                    uuid,
                    note: item.note,
                    noteBody: item.noteBody,
                    triggerReload: isSelfUpdate === false,
                })
            }
        })

        return () => {
            unsubscribe()
            view.dispose()
        }
    }, [notesManager, uuid])

    return (
        <NoteStateContext.Provider value={state}>
            <NoteDispatchContext.Provider value={dispatch}>
                {children}
            </NoteDispatchContext.Provider>
        </NoteStateContext.Provider>
    )
}
