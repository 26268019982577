import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"

export interface LoadNoteBodyProps {
    uuid: UUID
    db: FlowtelicDatabase
}

export async function loadNoteBody({ uuid, db }: LoadNoteBodyProps) {
    return (await db.noteBody.get(uuid)) || null
}
