import React, { useState, useEffect } from "react"
import { useDatabase } from "../../Data/Database"
import { NotesManager } from "../../Managers/NotesManager"

export const ManagerContext = React.createContext<NotesManager | null>(null)

export const NotesManagerProvider: React.FC = ({ children }) => {
    const db = useDatabase()
    const [notesManager, setNotesManager] = useState<NotesManager | null>(null)

    useEffect(() => {
        if (db) {
            const newNotesManager = new NotesManager(db)
            setNotesManager(newNotesManager)

            return () => {
                newNotesManager.shutdown()
            }
        }
    }, [db])

    return (
        <ManagerContext.Provider value={notesManager}>
            {children}
        </ManagerContext.Provider>
    )
}
