import React, { useReducer, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import { NoteType } from "../../Data/Note"
import { NotesView, NotesFilter } from "../../Managers/NotesManager"
import { useNotesManager } from "../NotesManagerProvider"
import { useCollectionViewState } from "../../Providers/CollectionViewProvider"
import { CollectionViewSort } from "../../Data/Collection"

interface FilteredNotesState {
    filter: NotesFilter | null
    sort: CollectionViewSort
    limit: number
    offset: number
    view: NotesView | null
    list: UUID[]
}

const defaultState: FilteredNotesState = {
    filter: null,
    sort: `recentUpdated`,
    limit: 0,
    offset: 0,
    view: null,
    list: [],
}

type Action =
    | {
          type: "setFilter"
          collectionUUID: UUID
          noteType?: NoteType[] | null
          workflowStates?: string[] | null
          sort: CollectionViewSort
      }
    | {
          type: "setSearchText"
          searchText: string | null
      }
    | {
          type: "setPagination"
          limit: number
          offset: number
      }
    | { type: "setList"; list: UUID[] }
    | { type: "setView"; view: NotesView }
type Dispatch = (action: Action) => void

export const FilteredNotesStateContext = React.createContext<FilteredNotesState>(
    defaultState
)
export const FilteredNotesDispatchContext = React.createContext<Dispatch>(
    () => {
        return
    }
)

function reducer(state: FilteredNotesState, action: Action) {
    switch (action.type) {
        case `setFilter`:
            return {
                ...state,
                filter: {
                    collectionUUID: action.collectionUUID,
                    noteType: action.noteType || null,
                    workflowStates: action.workflowStates || null,
                },
                sort: action.sort,
            }
        case `setSearchText`:
            if (state.filter !== null) {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        searchText: action.searchText,
                    },
                }
            }
            break
        case `setPagination`:
            return {
                ...state,
                limit: action.limit,
                offset: action.offset,
            }
        case `setList`:
            return {
                ...state,
                list: action.list,
            }
        case `setView`:
            return {
                ...state,
                view: action.view,
            }
    }
    return state
}

interface FilteredNotesProviderProps {
    noteType?: NoteType[] | null
    sort: CollectionViewSort
    workflowStates?: string[] | null
    searchText?: string | null
    limit?: number
    offset?: number
}

export const FilteredNotesProvider: React.FC<FilteredNotesProviderProps> = ({
    children,
    noteType,
    sort,
    workflowStates = null,
    searchText = null,
    limit = 100,
    offset = 0,
}) => {
    const notesManager = useNotesManager()
    const collectionView = useCollectionViewState()
    const defaultState: FilteredNotesState = {
        filter: null,
        sort: `recentUpdated`,
        limit,
        offset,
        view: null,
        list: [],
    }
    const [state, dispatch] = useReducer(reducer, defaultState)
    const collectionUUID = collectionView?.collectionUUID || null

    useEffect(() => {
        if (collectionUUID === null) {
            return
        }

        dispatch({
            type: `setFilter`,
            collectionUUID,
            noteType,
            workflowStates,
            sort,
        })
    }, [collectionUUID, noteType, workflowStates, sort])

    useEffect(() => {
        dispatch({ type: `setSearchText`, searchText })
    }, [searchText])

    useEffect(() => {
        dispatch({ type: `setPagination`, limit, offset })
    }, [limit, offset])

    // Re-query if our filter changes
    useEffect(() => {
        if (notesManager === null || state.filter === null) {
            return
        }

        // Create a new view
        const notesView = notesManager.createNotesView(
            state.filter,
            state.sort,
            state.limit,
            state.offset
        )

        dispatch({ type: `setView`, view: notesView })

        // Now fetch the list
        notesView.list().then((list) => {
            dispatch({ type: `setList`, list })
        })

        // Dispose of it when the view updates
        return () => {
            notesView.dispose()
        }
    }, [notesManager, state.filter, state.offset, state.limit])

    return (
        <FilteredNotesStateContext.Provider value={state}>
            <FilteredNotesDispatchContext.Provider value={dispatch}>
                {children}
            </FilteredNotesDispatchContext.Provider>
        </FilteredNotesStateContext.Provider>
    )
}
