// @deprecated
import { ContentBlock } from "draft-js"
import escapeRegExp from "lodash/escapeRegExp"
import { findWithRegex } from "../Utils"
import { FindWithRegexCb } from "../Utils/types"

interface RelatedItemRegexStrategyProps {
    isPrefix: boolean
    prefix: string
    suffix: string
    supportWhitespace: boolean
    regex: string
}

export default ({
    isPrefix,
    prefix,
    suffix,
    regex,
}: RelatedItemRegexStrategyProps): ((
    contentBlock: ContentBlock,
    callback: FindWithRegexCb
) => void) => {
    const RELATE_REGEX = new RegExp(
        `${escapeRegExp(isPrefix ? prefix : suffix)}`,
        `g`
    )

    return (contentBlock: ContentBlock, callback: FindWithRegexCb) => {
        findWithRegex(RELATE_REGEX, contentBlock, callback)
    }
}
