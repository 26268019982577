import { FlowtelicDatabase } from "../../../Data/Database"
import Dexie, { Collection } from "dexie"
import { ObservableItemList } from "../../../Observable"
import { Note, NoteType } from "../../../Data/Note"
import { UUID } from "../../../Data/UUID"
import { DataLoader, buildSearchRegExp } from "../../../Utils"
import { NotesManager } from "../NotesManager"
import { CollectionViewSort } from "../../../Data/Collection"

interface Pagination {
    count: number
    limit: number
    offset: number
}

interface NoteCache {
    count: number
    limit: number
    offset: number
    list: UUID[]
    map: Map<UUID, Note>
}

export interface NotesFilter {
    collectionUUID: UUID
    noteType?: NoteType[] | null
    workflowStates: string[] | null
    searchText?: string | null
}

function filterNote(
    note: Note,
    searchRegExp: RegExp | null,
    workflows: string[] | null
) {
    if (searchRegExp !== null && searchRegExp.test(note.title) === false) {
        return false
    }

    if (
        workflows !== null &&
        workflows.length > 0 &&
        note.workflowState !== null &&
        workflows.indexOf(note.workflowState) === -1
    ) {
        return false
    }

    return true
}

class NotesView extends ObservableItemList<Note, UUID> {
    private db: FlowtelicDatabase
    private notesManager: NotesManager
    private cache: DataLoader<NoteCache>
    private filter: NotesFilter
    private sort: CollectionViewSort
    private searchRegExp: RegExp | null = null
    private limit: number
    private offset: number

    public constructor(
        db: FlowtelicDatabase,
        notesManager: NotesManager,
        filter: NotesFilter,
        sort: CollectionViewSort,
        limit: number,
        offset: number
    ) {
        super()
        this.db = db
        this.notesManager = notesManager
        this.filter = filter
        this.sort = sort
        this.limit = limit
        this.offset = offset

        if (this.filter.searchText) {
            this.searchRegExp = buildSearchRegExp(this.filter.searchText)
        }

        this.cache = new DataLoader<NoteCache>(async () => {
            // Do a partial compound index match
            let notesCollection: Collection<Note, string>
            let sortReverse = false

            // console.log(`filter`, this.filter)
            if (this.filter.noteType && this.filter.noteType.length === 1) {
                // Filter by single type
                const matchType = this.filter.noteType[0]

                let index = `[collectionUUID+type+updated]`
                sortReverse = true
                if (this.sort === `title`) {
                    index = `[collectionUUID+type+title]`
                    sortReverse = false
                }

                notesCollection = db.notes
                    .where(index)
                    .between(
                        [this.filter.collectionUUID, matchType, Dexie.minKey],
                        [this.filter.collectionUUID, matchType, Dexie.maxKey]
                    )
            } else {
                // Show everything
                if (this.sort === `title`) {
                    notesCollection = db.notes
                        .where(`[collectionUUID+title]`)
                        .between(
                            [this.filter.collectionUUID, Dexie.minKey],
                            [this.filter.collectionUUID, Dexie.maxKey]
                        )
                } else {
                    // Sort by recentUpdated
                    notesCollection = db.notes
                        .where(`[collectionUUID+updated]`)
                        .between(
                            [this.filter.collectionUUID, Dexie.minKey],
                            [this.filter.collectionUUID, Dexie.maxKey]
                        )
                    sortReverse = true // newest first
                }

                if (this.filter.noteType && this.filter.noteType.length > 1) {
                    const types = this.filter.noteType
                    // Filter by the array of note types
                    notesCollection = notesCollection.filter((note: Note) => {
                        return types.indexOf(note.type) !== -1
                    })
                }
            }

            // If we're filtering by text or workflow states, add this in
            if (
                this.searchRegExp !== null ||
                this.filter.workflowStates !== null
            ) {
                const notesFilter = (note: Note) => {
                    return filterNote(
                        note,
                        this.searchRegExp,
                        this.filter.workflowStates
                    )
                }

                notesCollection = notesCollection.filter(notesFilter)
                sortReverse = false

                // TODO - sort based on the rank from the search text
            }

            if (sortReverse) {
                // Sort by updated descending
                notesCollection = notesCollection.reverse()
            }

            const count = await notesCollection.count()

            // Now add the limits
            notesCollection = notesCollection
                .limit(this.limit)
                .offset(this.offset)

            const list: UUID[] = []
            const map = new Map<UUID, Note>()
            await notesCollection.each((note) => {
                list.push(note.uuid)
                map.set(note.uuid, note)
            })

            return {
                count,
                limit: this.limit,
                offset: this.offset,
                list,
                map,
            }
        })
    }

    public dispose() {
        this.notesManager.removeNotesView(this)
    }

    public async getPagination(): Promise<Pagination> {
        const cache = await this.cache.get()
        return {
            count: cache.count,
            limit: cache.limit,
            offset: cache.offset,
        }
    }

    public async list() {
        const cache = await this.cache.get()
        return cache.list
    }

    public async get(uuid: UUID) {
        const cache = await this.cache.get()
        return cache.map.get(uuid) || null
    }

    private isNoteInView(note: Note) {
        if (
            note.collectionUUID !== this.filter.collectionUUID ||
            filterNote(note, this.searchRegExp, this.filter.workflowStates) ===
                false
        ) {
            return false
        }

        // We are filtering by type and does the type match
        if (
            this.filter.noteType &&
            this.filter.noteType.length > 0 &&
            this.filter.noteType.indexOf(note.type) === -1
        ) {
            return false // Type doesn't match
        }

        return true
    }

    public async insert(note: Note, isSelfUpdate: boolean) {
        if (this.isNoteInView(note)) {
            this.cache.reset()
            const cache = await this.cache.get()
            this.fireListChangeEvent(cache.list, isSelfUpdate)
        }
    }

    // You can insert or update an item using put
    public async put(note: Note, isSelfUpdate: boolean) {
        const cache = this.cache.getData()
        if (cache === undefined) {
            return // Not loaded so nothing to update
        }

        const oldNote = cache.map.get(note.uuid)
        if (oldNote === undefined) {
            // Not in the view before, has it come in the view?
            await this.insert(note, isSelfUpdate)
        } else {
            // It is already here, does it move out of the view?
            if (!this.isNoteInView(note)) {
                this.remove(note.uuid, isSelfUpdate)
            } else {
                // It's just updated
                this.fireItemUpdatedEvent(note, isSelfUpdate)
            }
        }
    }

    public remove(uuid: UUID, isSelfUpdate: boolean) {
        const cache = this.cache.getData()
        if (cache === undefined) {
            return // Not loaded so nothing to update
        }

        // Remove it from the list
        const index = cache.list.findIndex((itemUUID) => itemUUID === uuid)
        const item = cache.map.get(uuid)
        if (index !== null) {
            cache.list.splice(index, 1)
            cache.count--
        }

        // Remove it from the map
        cache.map.delete(uuid)

        index !== -1 && this.fireListChangeEvent(cache.list, isSelfUpdate)
        item && this.fireItemDeletedEvent(item, isSelfUpdate)
    }
}

export { NotesView }
export default NotesView
