import { useContext } from "react"
import {
    NotesImportStateContext,
    NotesImportDispatchContext,
} from "./NoteImportProvider"

export function useNotesImport() {
    const state = useContext(NotesImportStateContext)
    const dispatch = useContext(NotesImportDispatchContext)

    return { state, dispatch }
}
