import React from "react"
import styled, { css } from "styled-components"

import { MediaType } from "../MediaType"

interface Props {
    className?: string
    index?: number
    photo: MediaType
    margin?: string
    direction: `column` | `row`
    top: number
    left: number
    selected?: boolean
    onClick: () => void
}

const ImageButton = styled.button`
    border: 0;
    padding: 0;
    background-color: transparent;
`

const Media: React.FC<Props> = ({ className, photo, onClick }) => {
    return (
        <div className={className}>
            <ImageButton onClick={onClick}>
                <img alt={photo.title} {...photo} />
            </ImageButton>
        </div>
    )
}

export default styled(Media)<Props>`
    ${({
        margin,
        photo: { height, width },
        direction,
        top,
        left,
        selected,
    }) => {
        // Reference: https://codesandbox.io/s/o7o241q09?from-embed=&file=/SelectedImage.js:1417-1526
        return css`
            margin: ${margin};
            height: ${height}px;
            width: ${width}px;
            background-color: #eee;
            cursor: pointer;
            overflow: hidden;

            ${
                direction === `column` &&
                css`
                    position: absolute;
                    left: ${left}px;
                    top: ${top}px;
                `
            }

            ${
                direction !== `column` &&
                css`
                    position: relative;
                `
            }

            ${
                selected &&
                css`
                    outline: 2px solid orange;
                `
            }
        `
    }}
`
