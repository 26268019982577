import { useEffect } from "react"

export default function useOnEscape(fn: () => void, enabled = true) {
    function _handleKeyPress(e: KeyboardEvent) {
        e.key === `Escape` && fn && fn()
    }

    // Listen to the key event key
    useEffect(() => {
        if (!enabled) return

        document.addEventListener(`keydown`, _handleKeyPress, false)

        return () => {
            document.removeEventListener(`keydown`, _handleKeyPress, false)
        }
    }, [enabled])
}
