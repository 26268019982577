import React from "react"
import { useRelatedEntity } from "../RelatedEntity"
import Suggestions from "./Components/Suggestions"
import NoteLinkDecorator from "./Components/NoteLinkDecorator"
import ControlCharacterDecorator from "./Components/ControlCharacterDecorator"
import trigger from "./trigger"

import { LookupSuggestionsModalProps } from "../RelatedEntity"

function useNoteLink() {
    const SuggestionsWrapper: React.FC<LookupSuggestionsModalProps> = (
        props
    ) => {
        return <Suggestions {...props} />
    }

    const plugin = useRelatedEntity({
        prefix: trigger,
        suffix: `]]`,
        entityType: `note-link`,
        supportWhitespace: true,
        LookupSuggestionsComponent: SuggestionsWrapper,
        Decorator: NoteLinkDecorator,
        decorateControlCharacters: true,
        ControlCharacterDecorator: ControlCharacterDecorator,
    })

    return plugin
}

export default useNoteLink
