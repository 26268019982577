import React from "react"
import styled, { css } from "styled-components"

import Button from "../../Button"
import { ThumbProps } from "../types"

const Thumb: React.FC<ThumbProps> = ({ forwardedRef, className, onClick }) => {
    return (
        <div ref={forwardedRef} className={className}>
            <Button onClick={onClick} />
        </div>
    )
}

const _Thumb: React.ComponentType<ThumbProps> = React.forwardRef(
    (props: ThumbProps, ref?: React.Ref<HTMLDivElement>) => (
        <Thumb {...props} forwardedRef={ref} />
    )
)

export default styled(_Thumb)<ThumbProps>`
    ${({
        theme,
        color: { r = 0, g = 0, b = 0, a = 1 } = { r: 0, g: 0, b: 0, a: 1 },
    }) => css`
        width: 51px;
        height: 31px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJ0lEQVQoU2NcvXr1fwYkEBISgsxlYKSDgv///6O4Yc2aNahuoL0CAP2RJ/lgJQCYAAAAAElFTkSuQmCC")
            repeat;
        border-radius: 6px;
        border: 3px solid ${theme.colorPicker.thumbBorderColor};

        ${Button} {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(${r}, ${g}, ${b}, ${a});
            cursor: pointer;
            border-radius: 6px;
        }
    `}
`
