import React, { useRef, useState, useLayoutEffect } from "react"
import styled from "styled-components"
import InlineDialog from "../InlineDialog"
import { useElementBounds } from "../../../Hooks"

const Control = styled.div`
    display: inline-block;
`

interface ButtonDialogProps {
    button: React.ReactElement
    maxWidth?: number
    maxHeight?: number
    isOpen?: boolean
    place?: "above" | "below"
    align?: "left" | "right"
    buffer?: number
    onClose: () => void
}

const ButtonDialog: React.FC<ButtonDialogProps> = ({
    maxWidth,
    maxHeight,
    isOpen,
    button,
    onClose,
    place = `below`,
    align = `left`,
    buffer = 2,
    children,
}) => {
    const [position, setPosition] = useState<{
        left?: number
        right?: number
        top?: number
        bottom?: number
    } | null>({
        left: align === `left` ? 0 : undefined,
        right: align === `right` ? 0 : undefined,
        top: place === `above` ? 0 : undefined,
        bottom: place === `below` ? 0 : undefined,
    })
    const controlRef = useRef(null)
    const controlBounds = useElementBounds(controlRef, isOpen)

    const controlButton = React.cloneElement(button, {
        ref: controlRef,
    })

    // useEffect(() => {}, [panelRef.current, isOpen])

    useLayoutEffect(() => {
        if (controlBounds === null) {
            return
        }

        // Need to convert the bounding bottom to the viewport distance from the bottom
        // Same with right
        const viewportHeight = window.innerHeight
        const viewportWidth = window.innerWidth

        if (align === `left` && place === `below`) {
            setPosition({
                left: controlBounds.left,
                top: controlBounds.bottom + buffer,
            })
        } else if (align === `left` && place === `above`) {
            setPosition({
                left: controlBounds.left,
                bottom: viewportHeight - controlBounds.top + buffer,
            })
        } else if (align === `right` && place === `below`) {
            setPosition({
                right: viewportWidth - controlBounds.right,
                top: controlBounds.bottom + 2,
            })
        } else if (align === `right` && place === `above`) {
            setPosition({
                right: viewportWidth - controlBounds.right,
                bottom: viewportHeight - controlBounds.top + buffer,
            })
        }
    }, [controlBounds, place])

    return (
        <Control>
            {controlButton}
            {controlBounds && (
                <InlineDialog
                    visible={isOpen}
                    onClose={onClose}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    {...position}
                >
                    {children}
                </InlineDialog>
            )}
        </Control>
    )
}

export default ButtonDialog
