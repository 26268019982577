import { CollectionConfig } from "../Data/Collection"

export function getWorkflowForNoteType(
    config: CollectionConfig | null,
    noteType: string
) {
    if (config === null) {
        return null
    }

    // Find the note type in the config
    const noteTypeConfig = config.noteTypes.find((nt) => nt.name === noteType)
    if (!noteTypeConfig) {
        return null
    }

    // Now find the matching workflow
    const workflow =
        config.workflows.find((w) => w.name === noteTypeConfig.workflow) || null

    return workflow
}

export function getDefaultWorkflowStateForNoteType(
    config: CollectionConfig | null,
    noteType: string
) {
    const workflow = getWorkflowForNoteType(config, noteType)
    if (workflow !== null && workflow.states.length > 0) {
        return workflow.states[0].name
    } else {
        return null
    }
}

export function getBestWorkflowStateForNoteType(
    config: CollectionConfig | null,
    noteType: string,
    preferredWorkflowState: string | null
) {
    const workflow = getWorkflowForNoteType(config, noteType)
    if (workflow === null) {
        return null
    }

    const matchState =
        workflow.states.find(
            (state) => state.name === preferredWorkflowState
        ) || null

    if (matchState !== null) {
        return matchState.name
    } else if (workflow.states.length > 0) {
        return workflow.states[0].name
    } else {
        return null
    }
}
