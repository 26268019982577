import React, { useState } from "react"
import styled from "styled-components"
import {
    FormField,
    TextInput,
    Icon,
    Button,
    Text,
    ErrorMessage as _ErrorMessage,
} from "ui"
import { useAccountForm } from "../../Hooks"
import Link from "../../../Link"
import routes from "../../../../routes"

const SignInButton = styled(Button)`
    width: 100%;
`

const ErrorMessage = styled(_ErrorMessage)`
    margin-top: 20px;
`

const Email: React.FC = ({}) => {
    const [{ processing, query, email, password }, dispatch] = useAccountForm()

    function _handleSignIn() {
        // TODO
        return
    }

    function _handleEmailChange(value: string) {
        dispatch({
            type: `set`,
            field: `email`,
            value: value,
        })
    }

    function _handlePasswordChange(value: string) {
        dispatch({
            type: `set`,
            field: `password`,
            value: value,
        })
    }

    let signInButton
    if (processing) {
        signInButton = (
            <SignInButton
                primary
                cta
                disabled
                icon={
                    <Icon
                        name="spinner-circle"
                        size="18px"
                        subtle
                        spin
                        spinSpeed={1}
                    />
                }
            >
                Signing in
            </SignInButton>
        )
    } else {
        signInButton = (
            <SignInButton primary cta onClick={_handleSignIn}>
                Sign in
            </SignInButton>
        )
    }

    return (
        <>
            <h1>Sign in to your account</h1>

            {query.error && <ErrorMessage>{query.error}</ErrorMessage>}

            <FormField label="Email address">
                <TextInput
                    name="email"
                    value={email.value}
                    onChange={_handleEmailChange}
                    disabled={processing}
                />
            </FormField>

            <FormField label="Password">
                <TextInput
                    name="password"
                    type="password"
                    value={password.value}
                    onChange={_handlePasswordChange}
                    disabled={processing}
                />
            </FormField>

            {signInButton}

            <Text secondary>
                Need an account? <Link to={routes.signup}>Sign up here</Link>
            </Text>
        </>
    )
}

export default Email
