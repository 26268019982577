import React, { useState, useEffect } from "react"
import styled from "styled-components"

import RadioButton from "../../RadioButton"
import Checkbox from "../../Checkbox"
import Button from "../../Button"
import Icon from "../../Icon"
import Input from "../../Input"

interface Props {
    className?: string
    type: `radio` | `checkbox`
    value: string
    editable?: boolean
    deletable?: boolean
    selected?: boolean
    focus?: boolean
    onSelect?: (value: string) => void
    onDeselect?: (value: string) => void
    onChange?: (original: string, updated: string) => void
    onDelete?: (value: string) => void
}

const PropertyItem: React.FC<Props> = ({
    className,
    type,
    value,
    editable = false,
    deletable = false,
    selected = false,
    focus = false,
    onSelect,
    onDeselect,
    onChange,
    onDelete,
}) => {
    const [itemValue, setItemValue] = useState(value)

    useEffect(() => {
        setItemValue(itemValue)
    }, [value])

    function _handleSelect(selected: string) {
        onSelect && onSelect(selected)
    }

    function _handleSelectChange(selected: boolean) {
        if (selected) {
            onSelect && onSelect(itemValue)
        } else {
            onDeselect && onDeselect(itemValue)
        }
    }

    function _handleChange(val: string) {
        setItemValue(val)
    }

    function _commitChange() {
        // Pass the original value, then the updated one
        onChange && onChange(value, itemValue)
    }

    function _handleDelete() {
        onDelete && onDelete(value)
    }

    const selectControl =
        type === `radio` ? (
            <RadioButton onChange={_handleSelect} selected={selected} />
        ) : (
            <Checkbox onChange={_handleSelectChange} checked={selected} />
        )

    const valueInput = (
        <Input
            value={itemValue}
            readOnly={editable === false}
            onChange={_handleChange}
            onBlur={_commitChange}
            focus={focus}
            onEnter={_commitChange}
        />
    )

    return (
        <div className={className}>
            {selectControl}
            {valueInput}
            {deletable && (
                <Button onClick={_handleDelete}>
                    <Icon name="delete-bin" size="16px" />
                </Button>
            )}
        </div>
    )
}

export default styled(PropertyItem)<Props>`
    display: grid;
    grid-template-columns: max-content auto max-content;
    grid-gap: 5px;
    align-items: center;
    margin-top: 4px;

    ${Input} {
        font-size: 12px;
    }
`
