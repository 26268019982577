import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"

import Control from "./Control"
import GalleryPopup from "./GalleryPopup"
import Underlay from "../Underlay"

import { useElementBounds, useOnEscape } from "../../../Hooks"

import { MediaType } from "../MediaGallery"

interface Props {
    className?: string
    photos: MediaType[]
}

const MediaSelect: React.FC<Props> = ({ className, photos }) => {
    const controlRef = useRef(null)
    // const [search, setSearch] = useState(``)
    const [isOpen, setIsOpen] = useState(false)
    const controlBounds = useElementBounds(controlRef, isOpen)

    // function _handleChange(value) {
    //     setSearch(value)
    // }

    function _handleGalleryOpen() {
        setIsOpen(true)
    }

    function _handleGalleryClose() {
        setIsOpen(false)
    }

    useOnEscape(_handleGalleryClose, isOpen)

    return (
        <div className={className} data-testid="media-select">
            <Control ref={controlRef} onClick={_handleGalleryOpen}>
                Select from gallery
            </Control>
            {controlBounds && isOpen && (
                <>
                    <Underlay onClick={_handleGalleryClose} />
                    <GalleryPopup
                        x={controlBounds.x}
                        y={controlBounds.y + controlBounds.height}
                        onClose={_handleGalleryClose}
                        photos={photos}
                    />
                </>
            )}
        </div>
    )
}

export default styled(MediaSelect)`
    ${(props) => css``}
`
