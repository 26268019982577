import React from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    visible?: boolean
}

const Sidebar: React.FC<Props> = ({ className, children }) => {
    return (
        <div className={className} data-testid="sidebar">
            {children}
        </div>
    )
}

export default styled(Sidebar)<Props>`
    ${({ theme, visible = true }) => css`
        display: block;
        width: ${visible ? theme.sidebar.width : `0px`};
        background-color: ${theme.sidebar.backgroundColor};
        height: 100%;

        color: ${theme.sidebar.color};
        font-size: ${theme.sidebar.fontSize};
    `}
`
