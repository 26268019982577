import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"
import config from "../../Config"

function createClient(httpUri: string) {
    const cache = new InMemoryCache()

    const httpLink = new HttpLink({
        uri: httpUri,
        fetch,
    })

    // const authLink = new ApolloLink((operation, forward) => {
    //     operation.setContext(({ headers }: { headers: Headers }) => ({
    //         headers: {
    //             "X-Access-Token": accessToken,
    //         },
    //     }))
    //     return forward(operation)
    // })

    // const link = from([authLink, httpLink])

    const client = new ApolloClient({
        cache: cache,
        link: httpLink,
        queryDeduplication: true,
        // defaultOptions: {
        //     watchQuery: {
        //         fetchPolicy: `network-only`,
        //         errorPolicy: `all`,
        //     },
        //     query: {
        //         fetchPolicy: `network-only`,
        //         errorPolicy: `all`,
        //     },
        // },
    })

    return client
}
const client = createClient(config.API_SERVER_HTTP_URL)

export { client, createClient }
export default client
