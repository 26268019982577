import React from "react"

import { RGBAColor } from "../types"

import Underlay from "../../Underlay"
import PickerMenu from "./PickerMenu"
import { useOnEscape } from "../../../../Hooks"

interface PickerProps {
    color: RGBAColor
    visible?: boolean
    onClose?: () => void
    onChange?: (color: RGBAColor) => void
    x: number
    y: number
}

const Picker: React.FC<PickerProps> = ({
    color,
    visible = false,
    onClose,
    onChange,
    x,
    y,
}) => {
    function _handleClose() {
        onClose && onClose()
    }

    useOnEscape(_handleClose)

    return (
        <>
            {visible && <Underlay onClick={_handleClose} />}
            {visible && (
                <PickerMenu x={x} y={y} color={color} onChange={onChange} />
            )}
        </>
    )
}

export default Picker
