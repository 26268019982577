import { useRelatedEntity } from "../RelatedEntity"
import Suggestions from "./Components/Suggestions"
import MentionDecorator from "./Components/MentionDecorator"

function useMention() {
    const plugin = useRelatedEntity({
        suggestionTrigger: `@`,
        supportWhitespace: false,
        LookupSuggestionsComponent: Suggestions,
        Decorator: MentionDecorator,
        entityType: `mention`,
    })

    return plugin
}

export default useMention
