import React, { useRef, useLayoutEffect, useEffect } from "react"
import styled, { css } from "styled-components"
import { formatAge } from "../../Utils"
import { Note } from "../../Data/Note"
import { UUID } from "../../Data/UUID"
import { useFilteredNote } from "../../Providers/FilteredNotesProvider"
import {
    useCollectionNoteType,
    useCollectionWorkflowState,
} from "../../Data/Collection"

import { AnimatePresence, motion } from "framer-motion"

const ClampTwoLines = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

const Item = styled.div<{ selected: boolean }>`
    ${({ theme, selected = false }) => css`
        cursor: default;
        padding: 10px 15px 10px ${selected ? `12px` : `15px`};
        user-select: none;

        display: grid;
        grid-template-columns: 1fr 30px;
        grid-template-rows: max-content 1fr max-content;
        gap: 6px 3px;
        grid-template-areas:
            "Title Age"
            "Preview Preview"
            "NoteInfo NoteInfo";

        &:focus {
            outline: none;
        }

        ${theme.noteList.itemBorder};

        ${selected && theme.noteList.selected};

        &:hover {
            ${theme.noteList.hover};
        }
    `}
`

const Title = styled.h2<{ showPlaceholder: boolean }>`
    ${({ theme, showPlaceholder = false }) => css`
        ${theme.noteList.title};
        ${showPlaceholder && theme.noteList.titlePlaceholder};
        grid-area: Title;
    `}
`

const Preview = styled.span`
    ${({ theme }) => css`
        ${theme.noteList.preview};
        grid-area: Preview;
    `}
`

const Age = styled.span`
    ${({ theme }) => css`
        text-align: right;
        ${theme.noteList.age};
        grid-area: Age;
    `}
`

const NoteInfo = styled.span`
    ${({ theme }) => css`
        ${theme.noteList.noteInfo};
        grid-area: NoteInfo;
    `}
`

interface NoteItemProps {
    uuid: UUID
    selected?: boolean
    animate?: boolean
    onSelect?(note: Note): void
}

const NoteItem: React.FC<NoteItemProps> = ({
    uuid,
    selected = false,
    animate = false,
    onSelect,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const note = useFilteredNote(uuid)
    const noteType = useCollectionNoteType(
        note?.collectionUUID || null,
        note?.type || null
    )
    const workflowState = useCollectionWorkflowState(
        note?.collectionUUID || null,
        note?.type || null,
        note?.workflowState || null
    )

    // If we select it, make sure it's visible in the scroll
    useLayoutEffect(() => {
        if (selected) {
            ref.current?.scrollIntoView({
                behavior: `smooth`,
                block: `nearest`,
            })
        }
    }, [selected])

    useEffect(() => {
        function handleMouseDown(e: MouseEvent) {
            e.preventDefault() // Avoid a content edit change
        }

        function handleMouseUp(e: MouseEvent) {
            onSelect && note && onSelect(note)
        }

        const el = ref.current
        if (el) {
            el.addEventListener(`mousedown`, handleMouseDown)
            el.addEventListener(`mouseup`, handleMouseUp)

            return () => {
                el.removeEventListener(`mousedown`, handleMouseDown)
                el.removeEventListener(`mouseup`, handleMouseUp)
            }
        }
    }, [ref.current, note])

    if (note === null) {
        return null // TODO placeholder
    }

    let title = note.title
    if (title === ``) {
        title = note.uuid === null ? `New Note` : `Untitled Note`
    }

    const noteInfoParts = []
    if (noteType) {
        noteInfoParts.push(noteType.label)
    }
    if (workflowState) {
        noteInfoParts.push(workflowState.label)
    }

    const item = (
        <Item
            ref={ref}
            className={
                `note-list__item ` +
                (selected ? `note-list__item--selected` : ``)
            }
            selected={selected}
        >
            <Title showPlaceholder={note.title === ``}>
                <ClampTwoLines>{title}</ClampTwoLines>
            </Title>
            <Age>{formatAge(note.updated)}</Age>
            <Preview>
                <ClampTwoLines>{note.preview}</ClampTwoLines>
            </Preview>
            <NoteInfo>{noteInfoParts.join(` • `)}</NoteInfo>
        </Item>
    )

    let AnimateWrapper = animate ? (
        <AnimatePresence>
            <motion.div
                style={{ overflow: `hidden` }}
                initial={{ height: 0 }}
                animate={{ height: `auto` }}
                exit={{ height: 0 }}
            >
                {item}
            </motion.div>
        </AnimatePresence>
    ) : (
        item
    )

    // Set a class so the focus of the list can style the background of the selected item in the theme
    return AnimateWrapper
}

export default NoteItem
