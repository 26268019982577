import React from "react"
import styled, { css } from "styled-components"
import Button from "../Button"
import Icon from "../Icon"

import _Circle from "./Circle"

const Circle = styled(_Circle)`
    ${({ fill = false }) => {
        return css`
            fill: rgba(161, 161, 163, ${fill ? 1 : 0});
            stroke: rgba(161, 161, 163, 1);
            stroke-width: 1px;
            transition: fill 0.3s linear;
        `
    }}
`

function calculateWidth(total: number, gap: number, radius: number) {
    const gapTotal = (total - 1) * gap
    const circleTotal = 2 * (total * radius)
    return gapTotal + circleTotal + 1
}

function calculateHeight(radius: number) {
    return 2 + 2 * radius // 2 is the border
}

interface Props {
    className?: string
    total: number
    step?: number
    testId?: string
    gap?: number
    radius?: number
    leftEnabled?: boolean
    rightEnabled?: boolean
    onNext?: () => void
    onPrevious?: () => void
}

const StepList: React.FC<Props> = ({
    className,
    total,
    step = 1,
    testId = `step-list`,
    gap = 10,
    radius = 5,
    leftEnabled = true,
    rightEnabled = true,
    onNext,
    onPrevious,
}) => {
    const elements = []
    const diameter = 2 * radius

    // Add each step except the final one
    let x = 0
    for (let i = 1; i <= total; i += 1) {
        elements.push(
            <Circle
                key={`c${i}`}
                cx={x + radius}
                cy={radius + 1}
                r={radius}
                fill={i <= step}
            />
        )
        x += diameter + gap
    }

    const totalWidth = calculateWidth(total, gap, radius)
    const totalHeight = calculateHeight(radius)

    return (
        <div className={className} data-testid={testId}>
            <Button
                disabled={!leftEnabled}
                iconOnly
                icon={<Icon name="arrow-left-s" size="18px" subtle />}
                onClick={onPrevious}
            />
            <svg
                viewBox={`0 0 ${totalWidth} ${totalHeight}`}
                xmlns="http://www.w3.org/2000/svg"
            >
                {elements}
            </svg>
            <Button
                disabled={!rightEnabled}
                iconOnly
                icon={<Icon name="right-arrow" size="18px" />}
                onClick={onNext}
            />
        </div>
    )
}

export default styled(StepList)<Props>`
    ${({ total, gap = 10, radius = 5 }) => {
        const totalWidth = calculateWidth(total, gap, radius)
        const totalHeight = calculateHeight(radius)

        return css`
            display: flex;
            justify-content: center;
            align-items: center;

            > svg {
                margin: 0 10px;
                width: ${totalWidth}px;
                height: ${totalHeight}px;
            }
        `
    }}
`
