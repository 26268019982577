import React from "react"

interface Props {
    className?: string
    fill: boolean
    r: number
    cx: number
    cy: number
}

const Circle: React.FC<Props> = ({ className, r, cx, cy }) => {
    return <circle className={className} cx={cx} cy={cy} r={r} />
}

export default Circle
