import React from "react"
import { HeadingDecorator } from "./Decorators"

function createHeadingDecorator(as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6") {
    const Decorator: React.FC = (props) => {
        return <HeadingDecorator as={as} {...props} />
    }

    return Decorator
}

export default createHeadingDecorator
