import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"

import Icon from "../Icon"

const InputControl = styled.input`
    ${({ theme }) => css`
        width: 100%;
        background: transparent;
        border: 0;
        font-size: inherit;
        color: inherit;
        padding: 5px;

        &:focus {
            outline: none;
        }

        &::placeholder {
            ${theme.input.placeholder};
        }

        ${theme.input.value}
    `}
`

const Suffix = styled.span`
    ${({ theme }) => css`
        ${theme.input.suffix}
    `}
`

const ReadOnly = styled.span`
    ${({ theme }) => css`
        ${theme.input.value}
    `}
`

export interface TextInputProps {
    className?: string
    id?: string
    name?: string
    value?: string
    placeholder?: string
    suffix?: string
    type?: `text` | `password`
    icon?: JSX.Element | null
    iconPosition?: `left` | `right`
    disabled?: boolean
    readOnly?: boolean
    focus?: boolean
    error?: boolean
    testId?: string
    tabIndex?: number

    onChange?: (value: string) => void
    onFocus?: () => void
    onBlur?: () => void
    onEnter?: () => void
}

const TextInput: React.FC<TextInputProps> = ({
    className,
    id,
    name,
    type = `text`,
    value,
    placeholder,
    suffix,
    icon,
    iconPosition = `right`,
    disabled = false,
    readOnly = false,
    focus = false,
    testId = `text-input`,
    tabIndex,
    onChange,
    onFocus,
    onBlur,
    onEnter,
}) => {
    const ref = useRef<HTMLInputElement | null>(null)

    // Auto focus setting
    useEffect(() => {
        if (ref.current === null) return

        if (focus) {
            const element = ref.current
            // In some cases the focus doesn't trigger (e.g. new collection dialog), but it does on a delay
            setTimeout(() => {
                element.focus()
            }, 100)
        }
    }, [focus, ref])

    function _handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange && onChange(event.target.value)
    }

    function _handleFocus() {
        // setFocused(true)
        onFocus && onFocus()
    }

    function _handleBlur() {
        // setFocused(false)
        console.log(`blur called`)
        onBlur && onBlur()
    }

    function _handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (ref.current === null) return

        if (event.key === `Enter` && onEnter) {
            ref.current.blur()
            onEnter()
        }
    }

    const inputControl = readOnly ? (
        <ReadOnly ref={ref}>{value}</ReadOnly>
    ) : (
        <InputControl
            ref={ref}
            id={id}
            name={name}
            type={type}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            tabIndex={tabIndex}
            onChange={_handleChange}
            onFocus={_handleFocus}
            onBlur={_handleBlur}
            onKeyPress={_handleKeyPress}
        />
    )

    return (
        <div className={className} data-testid={testId}>
            {iconPosition === `left` && icon}
            {inputControl}
            {suffix && <Suffix>{suffix}</Suffix>}
            {iconPosition === `right` && icon}
        </div>
    )
}

export default styled(TextInput)<TextInputProps>`
    ${({
        theme,
        icon,
        iconPosition = `right`,
        suffix = null,
        error = false,
        disabled = false,
        readOnly = false,
    }) => {
        const gridProps = []
        if (iconPosition === `left` && icon) {
            gridProps.push(`max-content`) // Icon if on left
        }
        gridProps.push(`1fr`) // input field
        if (suffix !== null) {
            gridProps.push(`max-content`) // suffix
        }
        if (iconPosition === `right` && icon) {
            gridProps.push(`max-content`) // Icon on the right
        }
        return css`
            display: grid;
            grid-template-columns: ${gridProps.join(` `)};
            grid-gap: 5px;
            align-items: center;
            padding: 10px;
        }

        /* Icon padding */
        ${
            icon &&
            iconPosition === `right` &&
            css`
                ${Icon} {
                    margin-left: 10px;
                }
            `
        }

            ${
                icon &&
                iconPosition === `left` &&
                css`
                    ${Icon} {
                        margin-right: 10px;
                    }
                `
            }

        &:focus-within {
            ${theme.controlFocus}
        }

        ${theme.input.control}

        ${error && theme.input.error}

        ${
            disabled &&
            css`
                cursor: not-allowed;
                > * {
                    cursor: not-allowed;
                }

                ${theme.input.disabled}
            `
        }

        ${
            readOnly &&
            css`
                cursor: not-allowed;
                > * {
                    cursor: not-allowed;
                }
            `
        }
    `
    }}
`
