import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"

import Icon from "../Icon"

interface Props {
    className?: string
    id?: string
    value?: string | number
    placeholder?: string
    disabled?: boolean
    type?: string
    icon?: JSX.Element
    iconPosition?: `left` | `right`
    onChange?: (value: string) => void
    onFocus?: () => void
    onBlur?: () => void
    readOnly?: boolean
    focus?: boolean
    onEnter?: () => void
    error?: boolean
    testId?: string
}

const Input: React.FC<Props> = ({
    id,
    className,
    type = `text`,
    value,
    disabled = false,
    placeholder,
    icon,
    iconPosition = `right`,
    onChange,
    onFocus,
    onBlur,
    readOnly = false,
    focus = false,
    onEnter,
    testId = `input`,
}) => {
    const ref = useRef<HTMLInputElement | null>(null)
    // const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (ref.current === null) return

        if (focus) {
            ref.current.focus()
        }
    }, [focus])

    function _handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange && onChange(event.target.value)
    }

    function _handleFocus() {
        // setFocused(true)
        onFocus && onFocus()
    }

    function _handleBlur() {
        // setFocused(false)
        onBlur && onBlur()
    }

    function _handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (ref.current === null) return

        if (event.key === `Enter`) {
            ref.current.blur()
            onEnter && onEnter()
        }
    }

    const valueControl = readOnly ? (
        <span>{value}</span>
    ) : (
        <input
            ref={ref}
            id={id}
            type={type}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={_handleChange}
            onFocus={_handleFocus}
            onBlur={_handleBlur}
            onKeyPress={_handleKeyPress}
        />
    )

    return (
        <div className={className} data-testid={testId}>
            {iconPosition === `left` && icon}
            {valueControl}
            {iconPosition === `right` && icon}
        </div>
    )
}

export default styled(Input)<Props>`
    ${({
        theme,
        icon,
        iconPosition = `right`,
        error = false,
        disabled = false,
    }) => css`
        ${Icon} {
            color: ${theme.input.iconColor};
        }

        &:focus-within {
            ${`` /* outline: auto 2px Highlight; */}
            outline: 1px solid ${theme.input.focusOutlineColor};
            outline-offset: -1px;

            ${Icon} {
                color: ${theme.input.iconColorActive};
            }
        }

        > input {
            width: 100%;
            background: transparent;
            border: 0;
            font-size: inherit;
            color: inherit;

            &:focus {
                outline: none;
            }
        }

        ${icon &&
        css`
            display: grid;
            grid-template-columns: ${iconPosition === `right`
                ? `1fr max-content`
                : `max-content 1fr`};
            grid-gap: 5px;
            align-items: center;
            padding: 10px;
        `}

        ${error &&
        css`
            border: 1px solid ${theme.input.errorBorderColor} !important;
        `}

        ${disabled &&
        css`
            background-color: ${theme.input.disabledBackgroundColor} !important;
            color: ${theme.input.disabledTextColor};
        `}
    `}
`
