import React from "react"
import styled, { css } from "styled-components"
import scrollbars from "../../Styles/scrollbars"
const List = styled.div`
    ${({ theme }) => css`
        height: 100%;
        overflow-y: auto;
        ${scrollbars};

        &:focus {
            ${theme.noteList.listFocus};
        }
    `}
`

interface NoteListInterface {
    onSelectArrows?(key: "up" | "down"): void
}

const NoteList: React.FC<NoteListInterface> = ({
    onSelectArrows,
    children,
}) => {
    function handleKeyDown(event: React.KeyboardEvent) {
        if (onSelectArrows) {
            if (event.key === `ArrowDown`) {
                event.preventDefault()
                onSelectArrows(`down`)
            } else if (event.key === `ArrowUp`) {
                onSelectArrows(`up`)
                event.preventDefault()
            }
        }
    }

    return (
        <List tabIndex={0} onKeyDown={handleKeyDown}>
            {children}
        </List>
    )
}

export default NoteList
