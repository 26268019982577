import React from "react"
import styled from "styled-components"
import BrandImage from "./Brand.svg"

interface Props {
    className?: string
}

const Brand: React.FC<Props> = ({ className }) => {
    return <img className={className} src={BrandImage} />
}

export default styled(Brand)``
