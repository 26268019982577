import { useMemo } from "react"

import { EditorPlugin } from "@draft-js-plugins/editor"

import webLinkStrategy from "./webLinkStrategy"
import WebLinkDecorator from "./WebLinkDecorator"

export default function useHashtag(): EditorPlugin {
    const plugin = useMemo<EditorPlugin>(() => {
        return {
            decorators: [
                {
                    strategy: webLinkStrategy,
                    component: WebLinkDecorator,
                },
            ],
        }
    }, [])

    return plugin
}
