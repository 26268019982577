import React, { useReducer, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import produce, { Draft } from "immer"
import { useLocalStorage } from "../../Hooks"
import {
    useCollectionConfig,
    CollectionViewConfig,
} from "../../Data/Collection"

export interface ViewState {
    viewName: string
    focusedNote: UUID | null
    openNotes: UUID[]
}
interface CollectionViewState {
    collectionUUID: UUID | null
    viewStates: ViewState[]
    activeViewState: ViewState | null
    pinnedNotes: UUID[]
}

type Action =
    | { type: "openNote"; uuid: UUID }
    | { type: "closeNote"; uuid: UUID }
    | { type: "setActiveView"; name: string | null }
    | { type: "syncViewConfig"; views: CollectionViewConfig[] | null }
    | { type: "pinNote"; uuid: UUID }
    | { type: "unpinNote"; uuid: UUID }
type Dispatch = (action: Action) => void

export const StateContext = React.createContext<CollectionViewState | null>(
    null
)
export const DispatchContext = React.createContext<Dispatch>(() => {
    return
})

function initStateWithViewConfig(
    initialState: CollectionViewState,
    views: CollectionViewConfig[] | null
) {
    // If the view config is null, just return the initial state for now so we don't lose it
    if (views === null) {
        return initialState
    }

    const viewStates: ViewState[] = []
    let activeViewState: ViewState | null = null

    function addViewConfigToViewState(view: CollectionViewConfig) {
        // Find any existing config
        const existingConfig = initialState.viewStates.find(
            (v) => v.viewName === view.name
        )
        let viewState: ViewState = {
            viewName: view.name,
            focusedNote: null,
            openNotes: [],
        }

        if (existingConfig) {
            viewState.focusedNote = existingConfig.focusedNote
            viewState.openNotes = existingConfig.openNotes
        }

        if (view.name === initialState.activeViewState?.viewName) {
            // This is the active view
            activeViewState = viewState
        }

        viewStates.push(viewState)

        // Now add the children views
        if (view.subViews) {
            view.subViews.forEach(addViewConfigToViewState)
        }
    }

    // Go through all the views in the config and create the vew state config mixin in any existing config in the initial state
    views.forEach(addViewConfigToViewState)

    if (activeViewState === null && viewStates.length > 0) {
        activeViewState = viewStates[0]
    }

    const state: CollectionViewState = {
        ...initialState,
        viewStates,
        activeViewState,
    }

    return state
}

function reducer(draft: Draft<CollectionViewState>, action: Action) {
    if (action.type === `openNote`) {
        // // Make sure it's not already open
        // if (draft.openNotes.indexOf(action.uuid) === -1) {
        //     draft.openNotes.push(action.uuid)
        // }
        // draft.focusedNote = action.uuid

        // Update the active view with the open note
        const view = draft.viewStates.find(
            (v) => v.viewName === draft.activeViewState?.viewName
        )
        if (view) {
            view.openNotes = [action.uuid]
            draft.activeViewState = view
        }
    } else if (action.type === `closeNote`) {
        const view = draft.viewStates.find(
            (v) => v.viewName === draft.activeViewState?.viewName
        )
        if (view) {
            // Find the index
            const index = view.openNotes.indexOf(action.uuid)
            // Remove the item
            view.openNotes = view.openNotes.filter(
                (uuid) => uuid !== action.uuid
            )

            // Now try to select the index (so it's the one after)
            if (index >= 0 && index + 1 < view.openNotes.length) {
                view.focusedNote = view.openNotes[index] || null
            } else {
                view.focusedNote = null
            }

            draft.activeViewState = view
        }
    } else if (action.type === `setActiveView`) {
        draft.activeViewState =
            draft.viewStates.find((v) => v.viewName === action.name) || null
    } else if (action.type === `syncViewConfig`) {
        const newState = initStateWithViewConfig(draft, action.views)
        draft.activeViewState = newState.activeViewState
        draft.viewStates = newState.viewStates
    } else if (action.type === `pinNote`) {
        draft.pinnedNotes = [action.uuid]
    } else if (action.type === `unpinNote`) {
        draft.pinnedNotes = draft.pinnedNotes.filter(
            (uuid) => uuid !== action.uuid
        )
    }
}

const immerReducer = produce(reducer)

interface Props {
    collectionUUID: UUID
}

export const CollectionViewProvider: React.FC<Props> = ({
    collectionUUID,
    children,
}) => {
    const collectionConfig = useCollectionConfig(collectionUUID)
    const viewConfig = collectionConfig?.views || null

    const [savedLayout, setSavedLayout] = useLocalStorage<CollectionViewState>(
        `layout:${collectionUUID}`,
        {
            collectionUUID,
            viewStates: [],
            activeViewState: null,
            pinnedNotes: [],
        },
        (storedValue: any, initialValue) => {
            const result: CollectionViewState = {
                collectionUUID: initialValue.collectionUUID,
                activeViewState:
                    storedValue.activeViewState || initialValue.activeViewState,
                viewStates: storedValue.viewStates || initialValue.viewStates,
                pinnedNotes:
                    storedValue.pinnedNotes || initialValue.pinnedNotes,
            }
            return result
        }
    )

    const [state, dispatch] = useReducer(
        immerReducer,
        savedLayout,
        (savedLayout) => {
            // Take the collection config and merge it with our default state
            return initStateWithViewConfig(
                {
                    ...savedLayout,
                    collectionUUID,
                },
                viewConfig
            )
        }
    )

    // Watch for the collection view config updating and make sure our states
    // of each view is updated
    useEffect(() => {
        // We need to sync our active view state with this
        dispatch({
            type: `syncViewConfig`,
            views: viewConfig,
        })
    }, [viewConfig])

    useEffect(() => {
        setSavedLayout(state as CollectionViewState)
    }, [state])

    return (
        <StateContext.Provider value={state as CollectionViewState}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}
