import React from "react"
import styled, { css } from "styled-components"

import AddButton from "../AddButton"

interface Props {
    className?: string
}

const NoMedia: React.FC<Props> = ({ className }) => {
    return (
        <div className={className} data-testid="no-media">
            <div>
                <AddButton />
                <span>Drag and drop file</span>
            </div>
        </div>
    )
}

export default styled(NoMedia)<Props>`
    ${({ theme }) => css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
            text-align: center;

            > span {
                margin-top: 10px;
                color: ${theme.mediaUploader.labelColor};
            }
        }
    `}
`
