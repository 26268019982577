import React, { useRef } from "react"
import styled, { css } from "styled-components"
import NoteList, { NoteItem } from "../NoteList"
import { UUID } from "../../Data/UUID"
import { useFilteredNotes } from "../../Providers/FilteredNotesProvider"
import DropContainer from "../DropContainer"
import DropNotesOverlay from "../DropNotesOverlay"
import { useNotesImport } from "../../Providers/NoteImportProvider"
import {
    useCollectionViewState,
    useCollectionViewDispatch,
} from "../../Providers/CollectionViewProvider"

const Sidebar = styled.div`
    ${({ theme }) => css`
        display: block;
        height: 100%;
        ${theme.noteList.listBorder};
    `}
`

interface NoteListSidebarProps {
    className?: string
}

const NoteListSidebar: React.FC<NoteListSidebarProps> = ({ className }) => {
    const viewState = useCollectionViewState()
    const viewDispatch = useCollectionViewDispatch()

    const sidebarRef = useRef<HTMLDivElement | null>(null)
    const notesImport = useNotesImport()

    const selectedUUID = viewState?.activeViewState?.openNotes[0] || null

    const { list: notes } = useFilteredNotes()

    function handleSelect(uuid: UUID) {
        viewDispatch({ type: `openNote`, uuid })
    }

    function handleSelectArrows(direction: "up" | "down") {
        //     const selectedIndex = notes.findIndex((uuid) => uuid === selectedUUID)
        //     // The number of notes might include the new preview of an unsaved note
        //     if (direction === `down` && selectedIndex < notes.length - 1) {
        //         const index = selectedIndex + 1
        //         const uuid = notes[index]
        //         setSelectedUUID(uuid)
        //         onSelectNote && onSelectNote(uuid)
        //     } else if (direction === `up` && selectedIndex > 0) {
        //         const index = selectedIndex - 1
        //         const uuid = notes[index]
        //         setSelectedUUID(uuid)
        //         onSelectNote && onSelectNote(uuid)
        //     }
    }

    function handleFileImport(files: FileList) {
        notesImport.dispatch({ type: `importFiles`, fileList: files })
    }

    // When rendering the note, use the active not for the preview if it's the selected one
    // otherwise use the list note. This will reduce the latency of the live preview in the sidebar
    return (
        <DropContainer
            className={className}
            hintComponent={<DropNotesOverlay />}
            overlayComponentRef={sidebarRef}
            onDrop={handleFileImport}
        >
            <Sidebar ref={sidebarRef}>
                <NoteList onSelectArrows={handleSelectArrows}>
                    {notes.map((noteUUID) => (
                        <NoteItem
                            key={noteUUID}
                            uuid={noteUUID}
                            selected={noteUUID === selectedUUID}
                            animate={false}
                            onSelect={() => handleSelect(noteUUID)}
                        />
                    ))}
                </NoteList>
            </Sidebar>
        </DropContainer>
    )
}

export default styled(NoteListSidebar)``
