import React from "react"
import styled, { css } from "styled-components"

import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

import UploaderPreview from "./UploaderPreview"

interface Props {
    className?: string
}

const MediaUploader: React.FC<Props> = ({ className }) => {
    return (
        <div className={className} data-testid="media-uploader">
            <DndProvider backend={HTML5Backend}>
                <UploaderPreview />
            </DndProvider>
        </div>
    )
}

export default styled(MediaUploader)<Props>``
