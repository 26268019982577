import { useEffect, useState } from "react"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import { Collection } from "./types"
import { UUID } from "../UUID"

export function useCollection(uuid: UUID | null) {
    // TODO - see if we can pull the collection from the cache, otherwise load it with useEffect
    const notesManager = useNotesManager()
    const [collection, setCollection] = useState<Collection | null>(null)

    useEffect(() => {
        if (notesManager === null || uuid === null) {
            setCollection(null)
            return
        }

        // Get the collections, then watch for changes to the list
        notesManager.collections.get(uuid).then(setCollection)
        return notesManager.collections.watchItem(uuid, setCollection)
    }, [uuid, notesManager])

    return collection
}
