import React, { useState } from "react"
import styled from "styled-components"

import { SidebarButton, Icon, Menu, MenuItem } from "ui"
import AddCollectionButton from "../AddCollectionButton"

// import { ButtonProps } from "../../UI/Button/Button"
import CollectionItem from "../CollectionItem"
import scrollbars from "../../../Styles/scrollbars"

import { useCollections } from "../../../Data/Collections"
import { useMarkdownExport } from "../../../Data/Export"

import { InlineDialog } from "ui"
import EditCollectionDialog from "../EditCollectionDialog"
import DeleteCollectionConfirmation from "./DeleteCollectionConfirmation"

import { useNotesManager } from "../../../Providers/NotesManagerProvider"
import { useCollectionsView } from "../../../Providers/CollectionsViewProvider"

import { CollectionListEntry } from "../../../Managers/NotesManager"

const StyledSidebar = styled.div`
    background-image: linear-gradient(180deg, #111114 0%, #0f1113 100%);
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
`

const ScrollContainer = styled.div`
    overflow-y: auto;
    ${scrollbars};
`

const SidebarItem = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
`

const Sidebar: React.FC = () => {
    const notesManager = useNotesManager()
    const collections = useCollections()
    const collectionsView = useCollectionsView()

    const [contextMenu, setContextMenu] = useState<{
        collection: CollectionListEntry
        x: number
        y: number
        bounds: DOMRect
    } | null>(null)
    const [editDialog, setEditDialog] = useState<{
        collection: CollectionListEntry
        x: number
        y: number
    } | null>(null)
    const [
        confirmDeleteCollection,
        setConfirmDeleteCollection,
    ] = useState<CollectionListEntry | null>(null)
    const exportCollectionToMarkdown = useMarkdownExport()

    function toggleNoteList() {
        collectionsView.dispatch({
            type: collectionsView.state.noteListVisible
                ? `hideNoteList`
                : `showNoteList`,
        })
    }

    function selectCollection(collection: CollectionListEntry) {
        collectionsView.dispatch({
            type: `setSelectedCollection`,
            uuid: collection.uuid,
        })
    }

    function handleItemContextMenu(
        collection: CollectionListEntry,
        x: number,
        y: number,
        bounds: DOMRect
    ) {
        setContextMenu({
            collection,
            x,
            y,
            bounds,
        })
    }

    function handleContextMenuClose() {
        setContextMenu(null)
    }

    function handleEditMenuClick() {
        if (contextMenu === null) {
            return
        }

        setEditDialog({
            collection: contextMenu.collection,
            x: contextMenu.bounds.right,
            y: contextMenu.bounds.top,
        })
        // Close the context menu
        setContextMenu(null)
    }

    function handleEditClose() {
        setEditDialog(null)
    }

    function handleDeleteMenuClick() {
        if (contextMenu === null) {
            return
        }

        setConfirmDeleteCollection(contextMenu.collection)
        // Close the context menu
        setContextMenu(null)
    }

    function handleCancelDelete() {
        setConfirmDeleteCollection(null)
    }

    function handleDelete() {
        if (confirmDeleteCollection === null || notesManager === null) {
            return
        }

        notesManager.deleteCollection({ uuid: confirmDeleteCollection.uuid })
        setConfirmDeleteCollection(null)
    }

    function handleMarkdownExport() {
        if (contextMenu === null) {
            return
        }

        // Export to markdown
        exportCollectionToMarkdown(contextMenu.collection.uuid)

        // Close the context menu
        setContextMenu(null)
    }

    return (
        <>
            <StyledSidebar>
                <SidebarItem>
                    <SidebarButton
                        onClick={toggleNoteList}
                        flip={collectionsView.state.noteListVisible === false}
                        icon={<Icon name="arrow-left-s" />}
                        subtle
                    ></SidebarButton>
                </SidebarItem>
                <ScrollContainer>
                    {collections.map((collection) => (
                        <SidebarItem key={collection.uuid}>
                            <CollectionItem
                                uuid={collection.uuid}
                                selected={
                                    collectionsView.state
                                        .selectedCollectionUUID ===
                                    collection.uuid
                                }
                                onClick={() => selectCollection(collection)}
                                onContextMenu={(
                                    x: number,
                                    y: number,
                                    bounds: DOMRect
                                ) =>
                                    handleItemContextMenu(
                                        collection,
                                        x,
                                        y,
                                        bounds
                                    )
                                }
                            />
                        </SidebarItem>
                    ))}
                    <SidebarItem>
                        <AddCollectionButton />
                    </SidebarItem>
                </ScrollContainer>
            </StyledSidebar>
            {contextMenu && (
                <Menu
                    visible={true}
                    onClose={handleContextMenuClose}
                    x={contextMenu.x}
                    y={contextMenu.y}
                    maxWidth={400}
                >
                    <MenuItem
                        icon={<Icon name="edit" size="20px" />}
                        onClick={handleEditMenuClick}
                    >
                        Edit...
                    </MenuItem>
                    <MenuItem
                        icon={<Icon name="markdown" size="20px" />}
                        onClick={handleMarkdownExport}
                    >
                        Export to Markdown...
                    </MenuItem>
                    <MenuItem
                        icon={<Icon name="bin-delete" size="20px" />}
                        onClick={handleDeleteMenuClick}
                    >
                        Delete...
                    </MenuItem>
                </Menu>
            )}
            {editDialog && (
                <InlineDialog
                    visible={true}
                    left={editDialog.x}
                    top={editDialog.y}
                    onClose={handleEditClose}
                >
                    <EditCollectionDialog
                        uuid={editDialog.collection.uuid}
                        onClose={handleEditClose}
                    />
                </InlineDialog>
            )}
            {confirmDeleteCollection && (
                <DeleteCollectionConfirmation
                    uuid={confirmDeleteCollection.uuid}
                    onCancel={handleCancelDelete}
                    onDelete={handleDelete}
                />
            )}
        </>
    )
}

export default Sidebar
