import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"

export interface DeleteNoteProps {
    uuid: UUID
    view?: UUID | null
    db: FlowtelicDatabase
}

export async function deleteNote({ uuid, view = null, db }: DeleteNoteProps) {
    await db.transaction(`rw`, db.notes, db.noteBody, db.noteLinks, (tx) => {
        // @ts-ignore
        tx.source = view !== null ? `${db.source}:${view}` : db.source
        db.notes.delete(uuid)
        db.noteBody.delete(uuid)
        db.noteLinks.delete(uuid)
    })
}
