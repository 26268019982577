import React, { useRef } from "react"
import styled, { css } from "styled-components"
import Underlay from "../Underlay"
import Button from "../Button"
import { useOnEscape } from "../../../Hooks"

const CenteredDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5001;
`

interface PanelProps {
    maxWidth?: number
    maxHeight?: number
}

const Panel = styled.div<PanelProps>`
    ${({ theme, maxWidth, maxHeight }) => css`
        ${theme.dialog.panel};

        ${maxWidth &&
        css`
            max-width: ${maxWidth}px;
        `}

        ${maxHeight &&
        css`
            max-height: ${maxHeight};
        `}
    `})
`

const Header = styled.div`
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: 20px;
    align-items: center;
    margin: 20px;

    ${Button} {
        padding: 0;
    }
`
const Title = styled.h1`
    margin: 0;
`

const Body = styled.div`
    margin: 20px;
`

const Buttons = styled.div`
    text-align: right;
    padding: 20px;

    > * {
        margin-left: 20px;
    }
`

interface DialogProps {
    visible?: boolean
    buttons: React.ReactElement[]
    closeButton: React.ReactElement
    title?: string
    maxWidth?: number
    maxHeight?: number
    onClose?: () => void
    backgroundClose?: boolean
}

const Dialog: React.FC<DialogProps> = ({
    visible,
    title,
    closeButton,
    buttons = [],
    children,
    maxWidth,
    maxHeight,
    onClose,
    backgroundClose = false,
}) => {
    const centeredDivRef = useRef<HTMLDivElement | null>(null)
    useOnEscape(
        () => {
            onClose && onClose()
        },
        visible && onClose ? true : false
    )

    function handleUnderlayClick(e: React.SyntheticEvent) {
        if (e.target === centeredDivRef.current) {
            if (backgroundClose) {
                onClose && onClose()
            }
        }
    }

    return (
        <>
            <CenteredDiv ref={centeredDivRef} onClick={handleUnderlayClick}>
                <Panel maxWidth={maxWidth} maxHeight={maxHeight}>
                    <Header>
                        {title && <Title>{title}</Title>}
                        {closeButton}
                    </Header>
                    <Body>{children}</Body>
                    {buttons.length > 0 && <Buttons>{buttons}</Buttons>}
                </Panel>
            </CenteredDiv>
            <Underlay />
        </>
    )
}

export default Dialog
