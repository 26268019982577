import React, { useReducer, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import { FocusModeSession } from "../../Managers/NotesManager/FocusModeSessionView"
import { useFocusModeSession } from "../FocusModeSessionsProvider"
import produce, { Draft } from "immer"

interface FocusModeViewState {
    collectionUUID: UUID | null
    sessionName: string | null
    session: FocusModeSession | null
    activeNoteUUID: UUID | null
}

const initialState: FocusModeViewState = {
    collectionUUID: null,
    sessionName: null,
    session: null,
    activeNoteUUID: null,
}

type Action =
    | {
          type: "setFocusSession"
          collectionUUID: UUID
          sessionName: string
      }
    | { type: "exitFocusSession" }
    | { type: "setSession"; session: FocusModeSession }
    | { type: "setActiveNoteUUID"; uuid: UUID }
type Dispatch = (action: Action) => void

export const StateContext = React.createContext<FocusModeViewState | null>(null)
export const DispatchContext = React.createContext<Dispatch>(() => {
    return
})

function reducer(draft: Draft<FocusModeViewState>, action: Action) {
    if (action.type === `setFocusSession`) {
        draft.collectionUUID = action.collectionUUID
        draft.sessionName = action.sessionName
    } else if (action.type === `exitFocusSession`) {
        draft.collectionUUID = null
        draft.sessionName = null
        draft.session = null
        draft.activeNoteUUID = null
    } else if (action.type === `setSession`) {
        draft.session = action.session
        const first =
            action.session.list.length > 0 ? action.session.list[0] : null
        draft.activeNoteUUID = (first && first.uuid) || null
    } else if (action.type === `setActiveNoteUUID`) {
        draft.activeNoteUUID = action.uuid
    }
}

const immerReducer = produce(reducer)

export const FocusModeViewProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(immerReducer, initialState)
    const session = useFocusModeSession(state.collectionUUID, state.sessionName)

    useEffect(() => {
        if (session === null) {
            return
        }

        if (
            state.session !== null &&
            state.session.config.name === session.config.name &&
            state.session.collectionUUID === session.collectionUUID
        ) {
            // Still the same session, we want to ignore third party changes while we're in the session
            return
        }

        dispatch({ type: `setSession`, session })
    }, [state.session, session])

    return (
        <StateContext.Provider value={state as FocusModeViewState}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}
