import { ContentBlock } from "draft-js"
import { FindWithRegexCb } from "./types"

const findWithRegex = (
    regex: RegExp,
    contentBlock: ContentBlock,
    callback: FindWithRegexCb,
    trimStart = 0,
    trimEnd = 0
): void => {
    const contentBlockText = contentBlock.getText()

    // exclude entities, when matching
    contentBlock.findEntityRanges(
        (character) => !character.getEntity(),
        (nonEntityStart, nonEntityEnd) => {
            const text = contentBlockText.slice(nonEntityStart, nonEntityEnd)
            let matchArr
            let start
            let end
            let prevLastIndex = regex.lastIndex

            // Go through all matches in the text and return the indices to the callback
            // Break the loop if lastIndex is not changed
            while ((matchArr = regex.exec(text)) !== null) {
        // eslint-disable-line
                if (regex.lastIndex === prevLastIndex) {
                    break
                }
                prevLastIndex = regex.lastIndex
                start = nonEntityStart + matchArr.index
                end = start + matchArr[0].length
                callback(start + trimStart, end - trimEnd)
            }
        }
    )
}

export default findWithRegex
