import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"

export interface DeleteCollectionProps {
    uuid: UUID
    view?: UUID | null
    db: FlowtelicDatabase
}

export async function deleteCollection({
    uuid,
    view = null,
    db,
}: DeleteCollectionProps) {
    await db.transaction(`rw`, db.collections, (tx) => {
        // @ts-ignore
        tx.source = view !== null ? `${db.source}:${view}` : db.source
        db.collections.update(uuid, { deleted: true })
    })
}
