import FlowtelicDatabase from "../FlowtelicDatabase"
import { Note } from "../../Note"
import { UUID } from "../../UUID"
import { titleToKey } from "./Utils"

export interface FindBacklinksProps {
    db: FlowtelicDatabase
    collectionUUID: UUID
    title: string
}

export async function findBacklinks({
    db,
    collectionUUID,
    title,
}: FindBacklinksProps) {
    // Multi-entry compound index doesn't work as compound keys so match the title, then match collection
    const links = await db.noteLinks
        .where({ linkTitles: titleToKey(title) })
        .filter((noteLink) => noteLink.collectionUUID === collectionUUID)
        .toArray()
    // Now load the notes for each of these
    const result = await db.notes.bulkGet(links.map((link) => link.uuid))

    return result.filter((note) => note !== undefined) as Note[]
}

export default findBacklinks
