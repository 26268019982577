import React, { useRef } from "react"
import styled, { css } from "styled-components"

import { SketchPicker, RGBColor, ColorResult } from "react-color"
import { useEnsureOnScreen } from "../../../../../Hooks"

import { RGBAColor } from "../../types"

interface PickerProps {
    className?: string
    color: RGBAColor
    visible?: boolean
    onChange?: (color: RGBAColor) => void
    x: number
    y: number
}

const Picker: React.FC<PickerProps> = ({ className, color, onChange }) => {
    const ref = useRef(null)

    useEnsureOnScreen(ref, 8)

    function _handleChange(c: ColorResult) {
        onChange && onChange(c.rgb as RGBAColor)
    }

    return (
        <div ref={ref} className={className}>
            <SketchPicker
                width="200"
                color={color as RGBColor}
                onChange={_handleChange}
            />
        </div>
    )
}

export default styled(Picker)<PickerProps>`
    ${({ x, y }) => css`
        display: block;
        opacity: 0;
        width: 220px;
        position: fixed;
        left: ${x}px;
        top: ${y}px;
        z-index: 5001;
        margin-top: 10px;
    `}
`
