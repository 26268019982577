import React from "react"
import styled, { css } from "styled-components"
import { useNotesImport } from "../../../Providers/NoteImportProvider"
import { Button } from "ui"
import scrollbars from "../../../Styles/scrollbars"
import FileResult from "./FileResult"

const CenteredDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5001;
`

const Title = styled.h1`
    margin: 0;
`

const Panel = styled.div`
    ${({ theme }) => css`
        ${theme.dialog.panel};

        width: 600px;
        max-width: 80%;
        padding: 10px 20px;

        > * {
            margin: 10px 0;
        }
    `})
`

const Results = styled.div`
    overflow-y: auto;
    height: 300px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 20px;

    ${scrollbars};
`

const ButtonBar = styled.div`
    display: flex;
    justify-content: center;
`

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    background-color: rgba(0, 0, 0, 0.4);
`

const ProgressBarContainer = styled.div`
    ${({ theme }) => css`
        ${theme.progressBar.container};
        width: 100%;
    `}
`

const ProgressBar = styled.div<{ width: number }>`
    ${({ theme, width = 0 }) => css`
        ${theme.progressBar.progressBar};
        width: ${width}%;
    `}
`

const ImportModal: React.FC = () => {
    const { state, dispatch } = useNotesImport()

    if (state.queue.length === 0 && state.results.length === 0) {
        return null
    }

    const totalFiles =
        state.results.length +
        state.queue.length +
        (state.processingFile === null ? 0 : 1)
    const processedFiles = state.results.length

    return (
        <>
            <CenteredDiv>
                <Panel>
                    <Title>Importing notes</Title>
                    <ProgressBarContainer>
                        <ProgressBar
                            width={(processedFiles / totalFiles) * 100}
                        />
                    </ProgressBarContainer>
                    <Results>
                        {state.results.map((result, index) => (
                            <FileResult
                                key={index}
                                filename={result.filename}
                                error={result.error}
                            />
                        ))}
                    </Results>
                    <ButtonBar>
                        <Button
                            secondary
                            disabled={state.processingFile !== null}
                            onClick={() => dispatch({ type: `clear` })}
                        >
                            Close
                        </Button>
                    </ButtonBar>
                </Panel>
            </CenteredDiv>
            <Underlay></Underlay>
        </>
    )
}

export default ImportModal
