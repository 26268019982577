import escapeRegExp from "./escapeRegExp"

// See https://stackoverflow.com/questions/13911053/regular-expression-to-match-all-words-in-a-query-in-any-order
export default function buildSearchRegExp(text: string) {
    const variables = text.split(/[ ]+/)
    const parts = variables.map((word) => {
        return `(?=.*${escapeRegExp(word)})`
    })

    const regExp = new RegExp(`^${parts.join(``)}.+`, `i`)
    return regExp
}
