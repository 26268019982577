import { Note, NoteBody, NoteLinks } from "../Note"
import { Collection } from "../Collection"
import { UUID } from "../UUID"
import { WebPreviewContent } from "../WebPreview"
import { v4 as uuidv4 } from "uuid"
import Dexie from "dexie"
import "dexie-observable"
import {
    IDatabaseChange,
    ICreateChange,
    IUpdateChange,
} from "dexie-observable/api"
import collectionPresets from "../../CollectionPresets"

import * as Lib from "./Lib"

export type SubscriptionEventType = "CREATED" | "UPDATED" | "DELETED"

export interface SubscriptionEvent {
    table: string
    type: SubscriptionEventType
    key: any
    obj?: any | null
    oldObj?: any | null
    source: string | null
    view: string | null
    isSelf: boolean
}

interface SubscriptionObserver {
    table: string
    callback: (event: SubscriptionEvent) => void
}

const DATABASE_CHANGE_CREATE = 1 // DatabaseChangeType.Create
const DATABASE_CHANGE_UPDATE = 2 // DatabaseChangeType.Update
const DATABASE_CHANGE_DELETE = 3 // DatabaseChangeType.Delete

class FlowtelicDatabase extends Dexie {
    notes: Dexie.Table<Note, UUID>
    noteBody: Dexie.Table<NoteBody, UUID>
    noteLinks: Dexie.Table<NoteLinks, UUID>
    webPreviews: Dexie.Table<WebPreviewContent, string>
    collections: Dexie.Table<Collection, UUID>
    observers: SubscriptionObserver[] = []
    source: string = uuidv4() // Used to ignore observer events from this instance
    private unsubscribe: () => void

    constructor(name: string) {
        super(name)

        this.version(9).stores({
            notes: `uuid, type, title, updated, titleKey`,
            noteBody: `uuid`,
            noteLinks: `uuid, *linkTitles`,
            collections: `uuid, name`,
        })
        this.version(10)
            .stores({
                collections: `uuid`,
                notes: `uuid, type, title, updated, titleKey, collectionUUID`,
            })
            .upgrade(async (tx) => {
                const defaultCollection: Collection = {
                    uuid: uuidv4(),
                    name: `My Notes`,
                    deleted: false,
                    configPreset: collectionPresets[0].name,
                    userConfig: null,
                }
                await tx.table(`collections`).add(defaultCollection)
                const result = await tx
                    .table(`notes`)
                    .toCollection()
                    .modify((note) => {
                        note.collectionUUID = defaultCollection.uuid
                        console.log(`Upgraded`, note)
                    })

                return result
            })
        this.version(11)
            .stores({
                noteLinks: `uuid, *linkTitles, collectionUUID`,
            })
            .upgrade(async (tx) => {
                // Load all the backlinks
                const backlinks = (await tx
                    .table(`noteLinks`)
                    .toArray()) as NoteLinks[]
                console.log(`Upgrade: backlinks`, backlinks)
                // For each backlink, get the collection ID
                for (let i = 0; i < backlinks.length; i += 1) {
                    let backlink = backlinks[i]
                    const note = (await tx
                        .table(`notes`)
                        .get(backlinks[i].uuid)) as Note | undefined
                    console.log(`Update: note`, note)
                    if (note) {
                        console.log(`Getting collection ID`)
                        backlink.collectionUUID = note.collectionUUID

                        // Now put it back
                        await tx.table(`noteLinks`).put(backlink, backlink.uuid)
                    }
                }
            })
        this.version(12).stores({
            notes: `uuid, type, title, updated, titleKey, collectionUUID, [collectionUUID+type+updated]`,
        })
        this.version(13)
            .stores({
                notes: `uuid, type, title, isIndex, updated, titleKey, collectionUUID, [collectionUUID+type+updated], [collectionUUID+isIndex+updated]`,
            })
            .upgrade(async (tx) => {
                const result = await tx
                    .table(`notes`)
                    .toCollection()
                    .modify((note) => {
                        note.isIndex = 0
                    })
                return result
            })
        this.version(14).stores({
            notes: `uuid, type, title, isIndex, updated, titleKey, collectionUUID, [collectionUUID+type+updated], [collectionUUID+isIndex+title]`,
        })
        this.version(15).stores({
            webPreviews: `[url+format]`,
        })
        this.version(16).stores({
            noteLinks: `uuid, *linkTitles, collectionUUID, [collectionUUID+linkTitles]`,
        })
        this.version(17).stores({
            noteLinks: `uuid, *linkTitles, collectionUUID`,
        })
        this.version(18).stores({
            noteLinks: `uuid, *linkTitles, collectionUUID`,
        })
        this.version(19).stores({
            noteLinks: `uuid, *linkTitles, collectionUUID, [collectionUUID+linkTitles]`,
        })
        this.version(20).stores({
            notes: `uuid, type, title, isIndex, updated, titleKey, collectionUUID, [collectionUUID+type+updated], [collectionUUID+isIndex+title], [collectionUUID+type+titleKey]`,
        })
        this.version(23)
            .stores({
                notes: `uuid, type, title, isIndex, isStudyNote, updated, titleKey, collectionUUID, [collectionUUID+type+updated], [collectionUUID+isIndex+title], [collectionUUID+isStudyNote+title], [collectionUUID+type+titleKey]`,
            })
            .upgrade(async (tx) => {
                const result = await tx
                    .table(`notes`)
                    .toCollection()
                    .modify((note) => {
                        note.isStudyNote = 0
                    })
                return result
            })
        this.version(24).stores({
            noteLinks: `uuid, *linkTitles, collectionUUID`,
        })
        this.version(25)
            .stores({
                notes: `uuid, type, title, updated, titleKey, collectionUUID, [collectionUUID+type+updated], [collectionUUID+type+title], [collectionUUID+type+titleKey]`,
            })
            .upgrade(async (tx) => {
                const result = await tx
                    .table(`notes`)
                    .toCollection()
                    .modify((note) => {
                        if (note.isStudyNote === 1) {
                            note.type = `Study`
                        } else if (note.isIndex === 1) {
                            note.type = `Index`
                        } else {
                            note.type = `Permanent`
                        }
                        delete note.isStudyNote
                        delete note.isIndex
                    })
                return result
            })
        this.version(26).stores({
            notes: `uuid, type, title, updated, titleKey, collectionUUID, [collectionUUID+updated], [collectionUUID+type+updated], [collectionUUID+type+title], [collectionUUID+type+titleKey]`,
        })
        this.version(27).upgrade(async (tx) => {
            const result = await tx
                .table(`collections`)
                .toCollection()
                .modify((collection) => {
                    collection.configPreset = collectionPresets[0].name
                    collection.userConfig = null
                })
            return result
        })
        this.version(28).stores({
            notes: `uuid, type, title, updated, titleKey, collectionUUID, [collectionUUID+updated], [collectionUUID+type+updated], [collectionUUID+type+title], [collectionUUID+titleKey]`,
        })
        this.version(30).upgrade(async (tx) => {
            const result = await tx
                .table(`notes`)
                .toCollection()
                .modify((note) => {
                    note.workflowState = null
                })
            return result
        })
        this.version(31).stores({
            notes: `uuid, type, title, updated, titleKey, collectionUUID, [collectionUUID+updated], [collectionUUID+title], [collectionUUID+type+updated], [collectionUUID+type+title], [collectionUUID+titleKey]`,
        })

        const collectionConfig = collectionPresets[0].config
        // Make sure each note has a workflow status
        this.version(32).upgrade(async (tx) => {
            const result = await tx
                .table(`notes`)
                .toCollection()
                .modify((note) => {
                    const noteType =
                        collectionConfig.noteTypes.find(
                            (nt) => nt.name === note.type
                        ) || null
                    const workflowName = noteType?.workflow || null
                    const workflow =
                        collectionConfig.workflows.find(
                            (w) => w.name === workflowName
                        ) || null
                    if (workflow && workflow.states.length > 0) {
                        const defaultState = workflow.states[0].name
                        note.workflowState = defaultState
                    }
                })
            return result
        })

        this.on(`populate`, () => {
            const defaultCollection: Collection = {
                uuid: uuidv4(),
                name: `My Notes`,
                deleted: false,
                configPreset: collectionPresets[0].name,
                userConfig: null,
            }
            this.collections.add(defaultCollection)
        })

        this.notes = this.table(`notes`)
        this.noteBody = this.table(`noteBody`)
        this.noteLinks = this.table(`noteLinks`)
        this.collections = this.table(`collections`)
        this.webPreviews = this.table(`webPreviews`)
        this.open()

        const onChange = (changes: IDatabaseChange[]) => {
            changes.forEach((change) => {
                let [dbSource = null, viewSource = null] = change.source?.split(
                    `:`
                ) || [null, null]
                if (viewSource === ``) {
                    viewSource = null
                }

                let changeType: SubscriptionEventType | null = null
                switch (change.type) {
                    case DATABASE_CHANGE_CREATE:
                        changeType = `CREATED`
                        break
                    case DATABASE_CHANGE_UPDATE:
                        changeType = `UPDATED`
                        break
                    case DATABASE_CHANGE_DELETE:
                        changeType = `DELETED`
                        break
                }

                if (changeType !== null) {
                    console.log(
                        `>>> db change event`,
                        change.source,
                        dbSource,
                        this.source
                    )
                    const obj = (change as ICreateChange).obj || null
                    const oldObj = (change as IUpdateChange).oldObj || null
                    const event: SubscriptionEvent = {
                        type: changeType,
                        table: change.table,
                        key: change.key,
                        obj: obj,
                        oldObj: oldObj,
                        source: dbSource,
                        view: viewSource,
                        isSelf: dbSource === this.source,
                    }

                    this.handleEvent(event)
                }
            })
        }

        this.on(`changes`, onChange)

        // Register the unsubscribe
        this.unsubscribe = () => {
            this.on(`changes`).unsubscribe(onChange)
        }
    }

    private async handleEvent(event: SubscriptionEvent) {
        // Now notify any observers
        this.notify(event)
    }

    public shutdown() {
        this.unsubscribe()
    }

    private async notify(event: SubscriptionEvent) {
        if (event.isSelf) {
            await this.processEventSideEffect(event)
        }

        this.observers
            .filter((o) => o.table === event.table)
            .forEach((o) => o.callback(event))
    }

    public subscribe(
        table: string,
        callback: (event: SubscriptionEvent) => void
    ) {
        const observer: SubscriptionObserver = {
            table,
            callback,
        }

        this.observers.push(observer)

        // Return an unsubscribe method
        return () => {
            this.observers = this.observers.filter((o) => o !== observer)
        }
    }

    private async processEventSideEffect(event: SubscriptionEvent) {
        if (event.table === `notes` && event.obj && event.oldObj) {
            const newNote = event.obj as Note
            const oldNote = event.oldObj as Note

            if (newNote.title !== oldNote.title) {
                // console.log(`Title changed`, newNote.title, oldNote.title)
                Lib.processNoteTitleChange({
                    collectionUUID: newNote.collectionUUID,
                    oldTitle: oldNote.title,
                    newTitle: newNote.title,
                    db: this,
                })
            }
        }
    }

    public putNote(args: Omit<Lib.PutNoteProps, "db">) {
        return Lib.putNote({ ...args, db: this })
    }

    public loadNote(args: Omit<Lib.LoadNoteProps, "db">) {
        return Lib.loadNote({ ...args, db: this })
    }

    public loadNoteBody(args: Omit<Lib.LoadNoteBodyProps, "db">) {
        return Lib.loadNoteBody({ ...args, db: this })
    }

    public findNotes(args: Omit<Lib.FindNotesProps, "db">) {
        return Lib.findNotes({ ...args, db: this })
    }

    public deleteNote(args: Omit<Lib.DeleteNoteProps, "db">) {
        return Lib.deleteNote({ ...args, db: this })
    }

    public findBacklinks(args: Omit<Lib.FindBacklinksProps, "db">) {
        return Lib.findBacklinks({ ...args, db: this })
    }

    public findNotesByTitle(args: Omit<Lib.FindNotesByTitleProps, "db">) {
        return Lib.findNotesByTitle({ ...args, db: this })
    }

    public getCollection(args: Omit<Lib.GetCollectionProps, "db">) {
        return Lib.getCollection({ ...args, db: this })
    }

    public getCollections() {
        return Lib.getCollections({ db: this })
    }

    public putCollection(args: Omit<Lib.PutCollectionProps, "db">) {
        return Lib.putCollection({ ...args, db: this })
    }

    public deleteCollection(args: Omit<Lib.DeleteCollectionProps, "db">) {
        return Lib.deleteCollection({ ...args, db: this })
    }
}

export default FlowtelicDatabase
