import React, { useState } from "react"
import { ButtonDialog, Button } from "ui"
import RecommendedSessions from "../RecommendedSessions"
import SessionCard from "../SessionCard"
import { FocusModeSession } from "../../../Managers/NotesManager/FocusModeSessionView"

interface FocusButtonProps {
    focusSessions: FocusModeSession[]
    onSessionStart: (session: FocusModeSession) => void
}

const FocusButton: React.FC<FocusButtonProps> = ({
    focusSessions,
    onSessionStart,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    function handleButtonClick() {
        setIsOpen(true)
    }

    function handleClose() {
        setIsOpen(false)
    }

    function handleSessionStart(session: FocusModeSession) {
        setIsOpen(false)
        onSessionStart(session)
    }

    if (focusSessions.length === 0) {
        return null
    }

    return (
        <ButtonDialog
            isOpen={isOpen}
            button={
                <Button secondary onClick={handleButtonClick}>
                    Focus
                </Button>
            }
            place="below"
            align="right"
            onClose={handleClose}
        >
            <RecommendedSessions>
                {focusSessions.map((session) => (
                    <SessionCard
                        key={session.config.name}
                        label={session.config.label}
                        noteCount={session.count}
                        durationMins={
                            session.config.defaultGoal.durationPerSession
                        }
                        onStart={() => handleSessionStart(session)}
                    />
                ))}
            </RecommendedSessions>
        </ButtonDialog>
    )
}

export default FocusButton
