import React from "react"
import styled, { css } from "styled-components"
import { DropdownMenuConfig } from "../types"
import Button from "../../Button"
import DropdownMenu from "../DropdownMenu"

const MenuPanel = styled.div`
    ${({ theme }) => css`
        ${theme.nav.subMenuPanel};

        position: absolute;
        z-index: 200;

        ${DropdownMenu} {
            display: block;
        }
    `})
`

interface SubMenuProps {
    className?: string
    items: DropdownMenuConfig[]
    onClick: (name: string) => void
}

const SubMenu: React.FC<SubMenuProps> = ({ className, items, onClick }) => {
    return (
        <MenuPanel className={className}>
            {items.map((item) => (
                <DropdownMenu
                    key={item.label}
                    config={item}
                    onClick={onClick}
                />
            ))}
        </MenuPanel>
    )
}

export default styled(SubMenu)``
