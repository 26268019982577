import React, { useState, useEffect, useMemo } from "react"
import styled, { css } from "styled-components"

import { Button, Toggle, Select, SelectOption } from "ui"
import { useOnEscape } from "../../../Hooks"

import { Note } from "../../../Data/Note"
import { useNote } from "../../../Providers/NoteProvider"
import { useNotesManager } from "../../../Providers/NotesManagerProvider"
import { useCollection, useCollectionConfig } from "../../../Data/Collection"
import { getBestWorkflowStateForNoteType } from "../../../Utils"
import {
    useCollectionViewState,
    useCollectionViewDispatch,
} from "../../../Providers/CollectionViewProvider"

const Layout = styled.div`
    width: 220px;
`

const Item = styled.div<{ center?: boolean }>`
    ${({ center = false }) => css`
        margin: 20px;
        ${center &&
        css`
            text-align: center;
        `}
    `}
`

const Separator = styled.hr`
    border: 1px solid rgba(255, 255, 255, 0.05);
`

interface NoteOverflowMenuProps {
    onClose?: () => void
    onDelete?: () => void
}

export const NoteOverflowMenu: React.FC<NoteOverflowMenuProps> = ({
    onClose,
    onDelete,
}) => {
    const notesManager = useNotesManager()
    const { state } = useNote()
    const config = useCollectionConfig(state.note?.collectionUUID || null)
    const viewState = useCollectionViewState()
    const viewDispatch = useCollectionViewDispatch()

    const noteUUID = state.note?.uuid || null
    const isNotePinned =
        (noteUUID && viewState?.pinnedNotes.indexOf(noteUUID) !== -1) || false

    useOnEscape(() => {
        onClose && onClose()
    })

    const noteTypeOptions = useMemo(() => {
        if (config === null) {
            return null
        }

        return config.noteTypes.map((type) => {
            return {
                value: type.name,
                label: type.label,
            }
        })
    }, [config?.noteTypes])

    const workflowStateOptions = useMemo(() => {
        if (config === null || state.note === null) {
            return null
        }

        const noteType = state.note.type
        // Find the note type from the collection config
        const noteTypeConfig = config.noteTypes.find(
            (nt) => nt.name === noteType
        )
        if (!noteTypeConfig) {
            return null
        }

        // Find the matching workflow
        const workflow = config.workflows.find(
            (w) => w.name === noteTypeConfig.workflow
        )
        if (!workflow) {
            return null
        }

        // Return the workflow state options
        return workflow.states.map((state) => ({
            value: state.name,
            label: state.label,
        }))
    }, [config, state.note?.type])

    function handleDelete() {
        onClose && onClose()
        onDelete && onDelete()
    }

    function handleNoteTypeChange(option: SelectOption) {
        if (notesManager !== null && state.note !== null) {
            const workflowState = getBestWorkflowStateForNoteType(
                config ?? null,
                option.value,
                state.note.workflowState
            )
            const newNote: Note = {
                ...state.note,
                type: option.value,
                workflowState: workflowState,
            }
            // Save the new prop
            notesManager.putNote({ note: newNote })
        }
    }

    function handleWorkflowStateChange(option: SelectOption) {
        if (notesManager !== null && state.note !== null) {
            const newNote: Note = {
                ...state.note,
                workflowState: option.value,
            }
            // Save the new prop
            notesManager.putNote({ note: newNote })
        }
    }

    function handlePinChange(selected: boolean) {
        if (noteUUID !== null) {
            viewDispatch({
                type: selected ? `pinNote` : `unpinNote`,
                uuid: noteUUID,
            })
        }
    }

    return (
        <Layout>
            {noteTypeOptions && (
                <Item>
                    <Select
                        label="Note type"
                        options={noteTypeOptions}
                        selectedOption={
                            noteTypeOptions.find(
                                (opt) => opt.value === state.note?.type
                            ) || noteTypeOptions[0]
                        }
                        onChange={handleNoteTypeChange}
                    />
                </Item>
            )}
            {workflowStateOptions && (
                <Item>
                    <Select
                        label="Workflow state"
                        options={workflowStateOptions}
                        selectedOption={
                            workflowStateOptions.find(
                                (opt) => opt.value === state.note?.workflowState
                            ) || workflowStateOptions[0]
                        }
                        onChange={handleWorkflowStateChange}
                    />
                </Item>
            )}

            <Separator />

            <Item>
                <Toggle
                    label="Pin to right"
                    checked={isNotePinned}
                    onChange={handlePinChange}
                />
            </Item>

            <Separator />

            <Item center>
                <Button danger onClick={handleDelete}>
                    Delete
                </Button>
            </Item>
        </Layout>
    )
}

export default NoteOverflowMenu
