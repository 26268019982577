import styled, { css } from "styled-components"
import Button from "../Button"

interface FlipProps {
    flip?: boolean
}

export default styled(Button)<FlipProps>`
    ${({ flip = false }) => css`
        padding: 5px;
        user-select: none;

        > span {
            transition: transform 0.1s linear;

            ${flip &&
            css`
                transform: rotate(180deg);
            `}
        }
    `}
`
