import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import runes from "runes"
import { Button } from "ui"
import { useEventListener, useElementBounds } from "../../../Hooks"
import { UUID } from "../../../Data/UUID"
import { useCollection } from "../../../Data/Collection"

function nameToLetters(name: string) {
    const parts = name
        .trim()
        .split(` `)
        .filter((p) => p !== ``)
    if (parts.length === 0) {
        return `🗂`
    } else if (parts.length === 1) {
        return runes.substr(parts[0].toUpperCase(), 0, 1)
    } else {
        return (
            runes.substr(parts[0].toUpperCase(), 0, 1) +
            runes.substr(parts[1].toUpperCase(), 0, 1)
        )
    }
}

const CollectionItemButton = styled(Button)`
    border-radius: 4px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    padding: 1px;
    user-select: none;
`

interface CollectionItemProps {
    uuid: UUID
    selected?: boolean
    onClick: () => void
    onContextMenu?: (x: number, y: number, bounds: DOMRect) => void
}

const CollectionItem: React.FC<CollectionItemProps> = ({
    uuid,
    selected = false,
    onClick,
    onContextMenu,
}) => {
    const ref = useRef<HTMLButtonElement>(null)
    const touchTimeoutRef = useRef<number | null>(null)
    const bounds = useElementBounds(ref, true)
    const collection = useCollection(uuid)

    useEventListener(`contextmenu`, handleContextClick as EventListener, ref)
    useEventListener(`touchstart`, handleTouchStart as EventListener, ref)
    useEventListener(`touchend`, handleTouchEnd as EventListener, ref)

    function handleContextClick(e: MouseEvent) {
        e.stopPropagation()
        e.preventDefault()

        const x = e.clientX
        const y = e.clientY

        // setContextMenu({ open: true, x, y })
        onContextMenu && bounds && onContextMenu(x, y, bounds)
    }

    function handleTouchStart(e: TouchEvent) {
        e.stopPropagation()
        e.preventDefault()

        const x = e.touches[0].clientX
        const y = e.touches[0].clientY

        // Remove any old timeout
        touchTimeoutRef.current !== null &&
            clearTimeout(touchTimeoutRef.current)

        // Set a new timeout (it'll be cancelled if the user touchends before the timeout)
        touchTimeoutRef.current = (setTimeout(() => {
            // Clear the timeout reference as it's now fired
            touchTimeoutRef.current = null
            onContextMenu && bounds && onContextMenu(x, y, bounds)
        }, 1000) as unknown) as number
    }

    function handleTouchEnd(e: TouchEvent) {
        e.stopPropagation()
        e.preventDefault()

        // Cancel the timeout if it's still active
        if (touchTimeoutRef.current !== null) {
            clearTimeout(touchTimeoutRef.current)

            // Treat this as a handle click
            onClick && onClick()
        }
    }

    return (
        <CollectionItemButton
            ref={ref}
            onClick={onClick}
            subtle={!selected}
            outline={!selected}
            secondary={selected}
            tooltip={collection?.name || ``}
        >
            {nameToLetters(collection?.name || ``)}
        </CollectionItemButton>
    )
}

export default CollectionItem
