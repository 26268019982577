import React from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    direction?: `horizontal` | `vertical`
    vertical?: boolean
    horizontal?: boolean
}

const Separator: React.FC<Props> = ({ className }) => {
    return <div className={className} data-testid="separator" />
}

export default styled(Separator)<Props>`
    ${({
        theme,
        direction = `vertical`,
        vertical = false,
        horizontal = false,
    }) => css`
        ${(vertical || direction === `vertical`) &&
        css`
            width: ${theme.separator.width};
            height: 100%;
            background-color: ${theme.separator.color};
        `}
        ${(horizontal || direction === `horizontal`) &&
        css`
            height: ${theme.separator.width};
            width: 100%;
            background-color: ${theme.separator.color};
        `}
    `}
`
