import { CollectionConfig } from "../Data/Collection/types"

export const zettelkastenConfig: CollectionConfig = {
    noteTypes: [
        {
            name: `Study`,
            label: `Study note`,
            sortType: `recent`,
            workflow: `study`,
            createLinkedNoteType: `Study`,
        },
        {
            name: `Idea`,
            label: `Idea note`,
            sortType: `recent`,
            workflow: `idea`,
            createLinkedNoteType: `Idea`,
        },
        {
            name: `Permanent`,
            label: `Permanent note`,
            sortType: `recent`,
            workflow: `slipBox`,
            createLinkedNoteType: `Permanent`,
        },
        {
            name: `Reference`,
            label: `Reference note`,
            sortType: `title`,
            workflow: null,
            createLinkedNoteType: `Permanent`,
        },
        {
            name: `Index`,
            label: `Index note`,
            sortType: `title`,
            workflow: null,
            createLinkedNoteType: `Permanent`,
        },
        {
            name: `Project`,
            label: `Project`,
            sortType: `recent`,
            workflow: `project`,
            createLinkedNoteType: `ProjectNote`,
        },
        {
            name: `ProjectNote`,
            label: `Project note`,
            sortType: `recent`,
            workflow: null,
            createLinkedNoteType: `ProjectNote`,
        },
        {
            name: `Book`,
            label: `Book`,
            sortType: `title`,
            workflow: null,
            createLinkedNoteType: `Study`,
        },
        {
            name: `Person`,
            label: `Person`,
            sortType: `title`,
            workflow: null,
            createLinkedNoteType: `Idea`,
        },
    ],
    workflows: [
        {
            name: `study`,
            label: `Study workflow`,
            states: [
                {
                    name: `readyToStudy`,
                    label: `Ready to study`,
                    icon: null,
                },
                {
                    name: `readyToProcess`,
                    label: `Ready to process`,
                    icon: null,
                },
                {
                    name: `complete`,
                    label: `Complete`,
                    icon: null,
                },
            ],
        },
        {
            name: `idea`,
            label: `Idea workflow`,
            states: [
                {
                    name: `draft`,
                    label: `Draft`,
                    icon: null,
                },
                {
                    name: `readyToProcess`,
                    label: `Ready to process`,
                    icon: null,
                },
                {
                    name: `complete`,
                    label: `Complete`,
                    icon: null,
                },
            ],
        },
        {
            name: `slipBox`,
            label: `Slip box workflow`,
            states: [
                {
                    name: `draft`,
                    label: `Draft`,
                    icon: null,
                },
                {
                    name: `complete`,
                    label: `Complete`,
                    icon: null,
                },
            ],
        },
        {
            name: `project`,
            label: `Project workflow`,
            states: [
                {
                    name: `backlog`,
                    label: `Backlog`,
                    icon: null,
                },
                {
                    name: `inProgress`,
                    label: `In Progress`,
                    icon: null,
                },
                {
                    name: `complete`,
                    label: `Complete`,
                    icon: null,
                },
            ],
        },
    ],
    views: [
        {
            name: `capture`,
            label: `Capture`,
            icon: null,
            filter: {
                noteType: [`Idea`, `Study`],
                workflowStates: null,
            },
            sort: `recentUpdated`,
            defaultNoteType: `Idea`,
            subViews: [
                {
                    name: `idea`,
                    label: `Ideas`,
                    icon: null,
                    filter: {
                        noteType: [`Idea`],
                        workflowStates: null,
                    },
                    sort: `recentUpdated`,
                    defaultNoteType: `Idea`,
                    // subViews: [
                    //     {
                    //         name: `idea/draft`,
                    //         label: `Draft`,
                    //         icon: null,
                    //         filter: {
                    //             noteType: [`Idea`],
                    //             workflowStates: [`draft`],
                    //         },
                    //         sort: `recentUpdated`,
                    //         defaultNoteType: `Idea`,
                    //     },
                    //     {
                    //         name: `idea/readyToProcess`,
                    //         label: `Ready to process`,
                    //         icon: null,
                    //         filter: {
                    //             noteType: [`Idea`],
                    //             workflowStates: [`readyToProcess`],
                    //         },
                    //         sort: `recentUpdated`,
                    //         defaultNoteType: `Idea`,
                    //     },
                    //     {
                    //         name: `idea/complete`,
                    //         label: `Complete`,
                    //         icon: null,
                    //         filter: {
                    //             noteType: [`Idea`],
                    //             workflowStates: [`complete`],
                    //         },
                    //         sort: `recentUpdated`,
                    //         defaultNoteType: `Idea`,
                    //     },
                    // ],
                },
                {
                    name: `study`,
                    label: `Study notes`,
                    icon: null,
                    filter: {
                        noteType: [`Study`],
                        workflowStates: null,
                    },
                    sort: `recentUpdated`,
                    defaultNoteType: `Study`,
                    // subViews: [
                    //     {
                    //         name: `study/readyToStudy`,
                    //         label: `Ready to study`,
                    //         icon: null,
                    //         filter: {
                    //             noteType: [`Study`],
                    //             workflowStates: [`readyToStudy`],
                    //         },
                    //         sort: `recentUpdated`,
                    //         defaultNoteType: `Study`,
                    //     },
                    //     {
                    //         name: `study/readyToProcess`,
                    //         label: `Ready to process`,
                    //         icon: null,
                    //         filter: {
                    //             noteType: [`Study`],
                    //             workflowStates: [`readyToProcess`],
                    //         },
                    //         sort: `recentUpdated`,
                    //         defaultNoteType: `Study`,
                    //     },
                    //     {
                    //         name: `study/complete`,
                    //         label: `Complete`,
                    //         icon: null,
                    //         filter: {
                    //             noteType: [`Study`],
                    //             workflowStates: [`complete`],
                    //         },
                    //         sort: `recentUpdated`,
                    //         defaultNoteType: `Study`,
                    //     },
                    // ],
                },
            ],
        },
        {
            name: `theArchive`,
            label: `The Archive`,
            icon: null,
            filter: {
                noteType: [`Permanent`, `Index`],
                workflowStates: null,
            },
            sort: `recentUpdated`,
            defaultNoteType: `Permanent`,
            subViews: [
                {
                    name: `index`,
                    label: `Index notes`,
                    icon: null,
                    filter: {
                        noteType: [`Index`],
                        workflowStates: null,
                    },
                    sort: `title`,
                    defaultNoteType: `Index`,
                },
                {
                    name: `permanentNotes`,
                    label: `Permanent notes`,
                    icon: null,
                    filter: {
                        noteType: [`Permanent`],
                        workflowStates: null,
                    },
                    sort: `recentUpdated`,
                    defaultNoteType: `Permanent`,
                },
                {
                    name: `references`,
                    label: `References`,
                    icon: null,
                    filter: {
                        noteType: [`Reference`],
                        workflowStates: null,
                    },
                    sort: `title`,
                    defaultNoteType: `References`,
                },
            ],
        },
        {
            name: `Projects`,
            label: `Projects`,
            icon: null,
            filter: {
                noteType: [`Project`],
                workflowStates: null,
            },
            sort: `recentUpdated`,
            defaultNoteType: `Project`,
            subViews: [
                {
                    name: `projectsInProgress`,
                    label: `In progress`,
                    icon: null,
                    filter: {
                        noteType: [`Project`],
                        workflowStates: [`inProgress`],
                    },
                    sort: `recentUpdated`,
                    defaultNoteType: `Project`,
                },
                {
                    name: `projectsBacklog`,
                    label: `Backlog`,
                    icon: null,
                    filter: {
                        noteType: [`Project`],
                        workflowStates: [`backlog`],
                    },
                    sort: `recentUpdated`,
                    defaultNoteType: `Project`,
                },
                {
                    name: `projectNotes`,
                    label: `Project notes`,
                    icon: null,
                    filter: {
                        noteType: [`ProjectNote`],
                        workflowStates: null,
                    },
                    sort: `recentUpdated`,
                    defaultNoteType: `ProjectNote`,
                },
            ],
        },
        {
            name: `artifacts`,
            label: `Artifacts`,
            icon: null,
            filter: {
                noteType: [`Book`, `Person`],
                workflowStates: null,
            },
            sort: `title`,
            defaultNoteType: `Book`,
            subViews: [
                {
                    name: `books`,
                    label: `Books`,
                    icon: null,
                    filter: {
                        noteType: [`Book`],
                        workflowStates: null,
                    },
                    sort: `title`,
                    defaultNoteType: `Book`,
                },
                {
                    name: `people`,
                    label: `People`,
                    icon: null,
                    filter: {
                        noteType: [`Person`],
                        workflowStates: null,
                    },
                    sort: `title`,
                    defaultNoteType: `Person`,
                },
            ],
        },
        {
            name: `all`,
            label: `Everything`,
            icon: null,
            filter: {
                noteType: null,
                workflowStates: null,
            },
            sort: `recentUpdated`,
            defaultNoteType: `Permanent`,
        },
    ],
    focusSessions: [
        {
            name: `study`,
            label: `Study Session`,
            filter: {
                noteType: [`Study`],
                workflowStates: [`readyToStudy`],
            },
            defaultGoal: {
                durationPerSession: 15,
                sessionsPerFrequency: 5,
                frequencyInDays: 7,
                resetHour: 4, // 4am
            },
            viewMode: `single`,
        },
        {
            name: `organizeIdeas`,
            label: `Organize Ideas`,
            filter: {
                noteType: [`Idea`],
                workflowStates: [`readyToProcess`],
            },
            defaultGoal: {
                durationPerSession: 10,
                sessionsPerFrequency: 5,
                frequencyInDays: 7,
                resetHour: 4, // 4am
            },
            viewMode: `organize`,
        },
        {
            name: `organizeStudyNotes`,
            label: `Organize Study Notes`,
            filter: {
                noteType: [`Study`],
                workflowStates: [`readyToProcess`],
            },
            defaultGoal: {
                durationPerSession: 10,
                sessionsPerFrequency: 5,
                frequencyInDays: 7,
                resetHour: 4, // 4am
            },
            viewMode: `organize`,
        },
        {
            name: `workOnProjects`,
            label: `Work on projects`,
            filter: {
                noteType: [`Project`],
                workflowStates: [`inProgress`],
            },
            defaultGoal: {
                durationPerSession: 20,
                sessionsPerFrequency: 3,
                frequencyInDays: 7,
                resetHour: 4, // 4am
            },
            viewMode: `reference`,
        },
    ],
}
