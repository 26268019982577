import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import Brand from "../../Brand"
import Quotes from "./Quotes"

import Background1x from "./NebulaBackground.jpg"
import Background2x from "./NebulaBackground@2x.jpg"
interface Props {
    className?: string
}

const CenteredDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Panel = styled.div`
    ${({ theme }) => css`
        ${theme.modal.panel};
        width: 900px;
        height: 650px;
        display: grid;
        grid-template-columns: 50% 50%;
    `}
`

const FormPanel = styled.div`
    ${({ theme }) => css`
        ${theme.panel.primary}

        padding: 60px;

        > ${Brand} {
            display: block;
            margin-bottom: 30px;
        }
    `}
`

const QuotePanel = styled.div`
    display: grid;
`

const QuotePanelBackground = styled.div`
    background-image: url(${Background1x});

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(${Background2x});
    }

    background-size: cover;
    grid-area: 1 / 1;
`

const QuotePanelContent = styled.div`
    box-shadow: inset 18px 0px 30px -6px rgba(0, 0, 0, 0.75);
    grid-area: 1 / 1;
    display: grid;
`

const Modal: React.FC<Props> = ({ children }) => {
    return (
        <CenteredDiv>
            <Panel>
                <FormPanel>
                    <Brand />
                    {children}
                </FormPanel>
                <QuotePanel>
                    <QuotePanelBackground />
                    <QuotePanelContent>
                        <Quotes />
                    </QuotePanelContent>
                </QuotePanel>
            </Panel>
        </CenteredDiv>
    )
}

export default Modal
