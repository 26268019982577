import React from "react"
import styled, { css } from "styled-components"

import Button from "../../Button"

import { TabProps } from "./TabProps"

const Tab: React.FC<TabProps> = ({
    className,
    children,
    onClick,
    disabled = false,
    selected = false,
}) => {
    return (
        <Button
            className={className}
            onClick={onClick}
            disabled={disabled || selected}
        >
            {children}
        </Button>
    )
}

export default styled(Tab)<TabProps>`
    ${({ theme, disabled = false, selected = false }) => css`
        cursor: pointer;
        height: ${theme.tabBar.height};
        color: ${theme.tabBar.color};
        padding: 0 13px;

        ${selected &&
        css`
            cursor: default;
            background-color: ${theme.tabBar.activeBackgroundColor};
            color: ${theme.tabBar.activeColor};
        `}

        ${disabled &&
        css`
            cursor: not-allowed;
        `}
    `}
`
