import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { Icon } from "ui"

const Details = styled.div``

const Result = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 10px;
    margin: 10px 0;
    align-items: center;
`

interface FileResultProps {
    filename: string
    error: string | null
}

const FileResult: React.FC<FileResultProps> = ({ filename, error }) => {
    const resultRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        resultRef.current?.scrollIntoView()
    }, [])

    return (
        <Result ref={resultRef}>
            <Icon
                name={error === null ? `check` : `warning`}
                success={error === null}
                danger={error !== null}
            />
            <Details>
                {filename}
                {error && `: ${error}`}
            </Details>
        </Result>
    )
}

export default FileResult
