import React, { useState } from "react"
import styled, { css } from "styled-components"
import { DropdownMenuConfig } from "./types"
import SubMenu from "./SubMenu"

const MenuGroup = styled.div<{ hover?: boolean }>`
    ${({ hover = false }) => {
        const subMenuDownshiftInPixels = 5

        return css`
            display: inline-block;
            position: relative;
            padding-bottom: ${subMenuDownshiftInPixels}px; // This value should match the

            > ${SubMenu} {
                display: block;
                opacity: 0;
                transform: translateY(-10px);
                pointer-events: none;
                user-select: none;

                transition: opacity 0.2s linear, transform 0.2s ease;
            }

            ${hover &&
            css`
                > ${SubMenu} {
                    transform: translateY(${subMenuDownshiftInPixels}px);
                    display: block;
                    opacity: 1;
                    pointer-events: auto;
                }
            `}
        `
    }}
`

const MenuButton = styled.button<{ active?: boolean; hover?: boolean }>`
    ${({ theme, active = false, hover = false }) => css`
        border: 1px solid rgba(255, 255, 255, 0);
        background: none;
        user-select: none;
        ${active && theme.header.navLinkActive};
        ${!active && theme.header.navLinkInactive};
        padding: 5px 10px;
        transition: border 0.2s linear;
        text-decoration: none;
        outline: none;
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;

        &:focus {
            ${theme.controlFocus};
        }

        ${hover &&
        css`
            ${theme.header.navLinkHover};
        `}
    `}
`

interface DropdownMenuProps {
    className?: string
    config: DropdownMenuConfig
    onClick?: (name: string) => void
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    className,
    config,
    onClick,
}) => {
    const [menuOpen, setMenuOpen] = useState(false)

    function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        setMenuOpen(false)
        onClick && onClick(config.name)
    }

    function handleSubMenuClick(name: string) {
        setMenuOpen(false)
        onClick && onClick(name)
    }

    return (
        <MenuGroup
            className={className}
            hover={menuOpen}
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}
        >
            <MenuButton
                onClick={handleClick}
                active={config.active || false}
                hover={menuOpen}
            >
                {config.label}
            </MenuButton>
            {config.children.length > 0 && (
                <SubMenu
                    items={config.children}
                    onClick={handleSubMenuClick}
                ></SubMenu>
            )}
        </MenuGroup>
    )
}

export default styled(DropdownMenu)``
