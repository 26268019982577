import React from "react"
import styled from "styled-components"

const Decorator = styled.span`
    border: 1px solid rgba(255, 255, 255, 75);
    border-radius: 14px;
    display: inline-block;
    padding: 5px;
`

const MentionDecorator: React.FC = ({ children }) => {
    return <Decorator>Mention: {children}</Decorator>
}

export default MentionDecorator
