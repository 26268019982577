import React from "react"
import styled, { css } from "styled-components"

const Panel = styled.div`
    ${({ theme }) => css`
        display: inline-block;
        ${theme.focus.recommendationPanel};
        padding: 20px;
        max-width: 800px;
    `}
`

const Title = styled.h1`
    margin: 0;
    font-size: 16px;
    padding: 10px;
`

const Cards = styled.div`
    display: inline-flex;
    flex-wrap: wrap;

    > * {
        margin: 10px;
    }
`

const RecommendedSessions: React.FC = ({ children }) => {
    return (
        <Panel>
            <Title>Recommendations for today</Title>
            <Cards>{children}</Cards>
        </Panel>
    )
}

export default RecommendedSessions
