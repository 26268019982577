import { State, Interest } from "./types"

const interests: readonly Interest[] = [
    {
        value: `writing`,
        label: `Writing`,
        selected: false,
    },
    {
        value: `programming`,
        label: `Programming`,
        selected: false,
    },
    {
        value: `marketing`,
        label: `Marketing`,
        selected: false,
    },
    {
        value: `audience-building`,
        label: `Audience Building`,
        selected: false,
    },
    {
        value: `influrencer-marketing`,
        label: `Influrencer Marketing`,
        selected: false,
    },
    {
        value: `video-production`,
        label: `Video Production`,
        selected: false,
    },
    {
        value: `productivity`,
        label: `Productivity`,
        selected: false,
    },
    {
        value: `design`,
        label: `Design`,
        selected: false,
    },
    {
        value: `entrepreneurship`,
        label: `Entrepreneurship`,
        selected: false,
    },
    {
        value: `ecommerce`,
        label: `Ecommerce`,
        selected: false,
    },
    {
        value: `education`,
        label: `Education`,
        selected: false,
    },
    {
        value: `personal-development`,
        label: `Personal development`,
        selected: false,
    },
]

const initialState: State = {
    activeForm: `signup`,
    formStage: null,
    processing: false,
    email: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    password: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    confirmPassword: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    username: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    siteAddress: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    siteName: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    usage: {
        personal: false,
        professional: false,
    },
    interests,
    resetCode: {
        value: ``,
        error: null,
        status: `pending`,
        focus: false,
    },
    query: {
        action: null,
        status: `pending`,
        error: null,
    },
}

export default initialState
