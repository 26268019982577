import { FlowtelicDatabase } from "../../../Data/Database"
import Dexie, { Collection } from "dexie"
import { ObservableItemList } from "../../../Observable"
import { Note, NoteType } from "../../../Data/Note"
import { UUID } from "../../../Data/UUID"
import { DataLoader } from "../../../Utils"
import { NotesManager } from "../NotesManager"
import { FocusSessionConfig } from "../../../Data/Collection"
import { v4 as uuid } from "uuid"

interface FocusNote {
    uuid: UUID
    status: "active" | "notApplicable" | "skipped"
}

export interface FocusModeSession {
    collectionUUID: UUID
    config: FocusSessionConfig
    count: number
    list: FocusNote[]
}

function filterNote(note: Note, workflowStates: string[] | null) {
    if (note.workflowState === null && workflowStates === null) {
        return true
    } else if (note.workflowState === null) {
        return false
    } else if (workflowStates === null) {
        return true
    } else if (workflowStates.indexOf(note.workflowState) === -1) {
        return false
    } else {
        return true
    }
}

class FocusModeSessionView extends ObservableItemList<FocusNote, FocusNote> {
    public uuid: UUID
    private db: FlowtelicDatabase
    private notesManager: NotesManager
    private cache: DataLoader<FocusModeSession>
    public collectionUUID: UUID
    public focusSessionConfig: FocusSessionConfig

    public constructor(
        db: FlowtelicDatabase,
        notesManager: NotesManager,
        collectionUUID: UUID,
        focusSessionConfig: FocusSessionConfig
    ) {
        super()
        this.uuid = uuid()
        this.db = db
        this.notesManager = notesManager
        this.collectionUUID = collectionUUID
        this.focusSessionConfig = focusSessionConfig

        this.cache = new DataLoader<FocusModeSession>(async () => {
            let notesCollection: Collection<Note, string>

            const { noteType, workflowStates } = this.focusSessionConfig.filter

            let sortReverse = false

            if (noteType !== null && noteType.length === 1) {
                // Filter by a single type
                const matchType = noteType[0]

                let index = `[collectionUUID+type+updated]`
                sortReverse = true // Newest to oldest

                notesCollection = db.notes
                    .where(index)
                    .between(
                        [this.collectionUUID, matchType, Dexie.minKey],
                        [this.collectionUUID, matchType, Dexie.maxKey]
                    )
            } else {
                // Sort by recentUpdated
                notesCollection = db.notes
                    .where(`[collectionUUID+updated]`)
                    .between(
                        [this.collectionUUID, Dexie.minKey],
                        [this.collectionUUID, Dexie.maxKey]
                    )
                sortReverse = true // newest first

                if (noteType && noteType.length > 1) {
                    // Filter by the array of note types
                    notesCollection = notesCollection.filter((note: Note) => {
                        return noteType.indexOf(note.type) !== -1
                    })
                }
            }

            if (workflowStates !== null) {
                const notesFilter = (note: Note) => {
                    return filterNote(note, workflowStates)
                }

                notesCollection = notesCollection.filter(notesFilter)
            }

            if (sortReverse) {
                // Sort by updated descending
                notesCollection = notesCollection.reverse()
            }

            const count = await notesCollection.count()

            // Now add the limits
            notesCollection = notesCollection.limit(100).offset(0)

            const list: FocusNote[] = []
            await notesCollection.each((note) => {
                list.push({
                    uuid: note.uuid,
                    status: `active`,
                })
            })

            return {
                collectionUUID,
                config: focusSessionConfig,
                count,
                list,
            }
        })
    }

    public dispose() {
        this.notesManager.removeFocusModeSessionView(this)
    }

    public async session() {
        const cache = await this.cache.get()
        return cache
    }

    private isNoteInView(note: Note) {
        const { workflowStates, noteType } = this.focusSessionConfig.filter

        if (
            note.collectionUUID !== this.collectionUUID ||
            filterNote(note, workflowStates) === false
        ) {
            return false
        }

        // We are filtering by type and does the type match
        if (
            noteType &&
            noteType.length > 0 &&
            noteType.indexOf(note.type) === -1
        ) {
            return false // Type doesn't match
        }

        return true
    }

    public async insert(note: Note, isSelfUpdate: boolean) {
        if (this.isNoteInView(note)) {
            const data = await this.cache.get()
            this.cache.setData({
                ...data,
                count: data.count + 1,
                list: [
                    ...data.list,
                    {
                        uuid: note.uuid,
                        status: `active`,
                    },
                ],
            })
            this.fireListChangeEvent(data.list, isSelfUpdate)
        }
    }

    // You can insert or update an item using put
    public async put(note: Note, isSelfUpdate: boolean) {
        if (this.collectionUUID !== note.collectionUUID) {
            return
        }

        const cache = this.cache.getData()
        if (cache === undefined) {
            return // Not loaded so nothing to update
        }

        // Is it already in the view?
        const existingNote =
            cache.list.find((item) => item.uuid === note.uuid) || null
        if (existingNote === null) {
            // Try adding it
            await this.insert(note, isSelfUpdate)
        } else {
            // It's already here, is it still in the view?
            if (!this.isNoteInView(note)) {
                this.remove(note.uuid, isSelfUpdate)
            }
        }
    }

    public remove(uuid: UUID, isSelfUpdate: boolean) {
        let cache = this.cache.getData()
        if (cache === undefined) {
            return // Not loaded so nothing to update
        }

        // Remove it from the list
        const index = cache.list.findIndex((item) => item.uuid === uuid)
        if (index !== -1) {
            cache.list.splice(index, 1)
            cache.count--
        }

        index !== -1 && this.fireListChangeEvent(cache.list, isSelfUpdate)
    }
}

export { FocusModeSessionView }
export default FocusModeSessionView
