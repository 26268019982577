import React from "react"
import styled, { css } from "styled-components"

import Icon from "../../Icon"

const StyledButton = styled.button`
    ${({ theme }) => css`
        ${theme.select.default};
        width: 100%;
        text-align: left;

        > div {
            display: grid;
            grid-template-columns: auto max-content;
            align-items: center;

            > div {
                color: ${theme.select.controlColor};
            }
        }
    `}
`

const LabelWithValue = styled.div``

const Label = styled.label`
    ${({ theme }) => css`
        ${theme.select.inlineLabel};
        margin-bottom: 3px;
        display: block;
    `}
`

const Value = styled.span`
    font-size: 14px;
`

interface Props {
    label?: string | null
    ref?: React.Ref<HTMLButtonElement> | null
    forwardedRef?: React.Ref<HTMLButtonElement> | null
    onClick?: () => void
}

const Control: React.FC<Props> = ({
    label = null,
    forwardedRef,
    children,
    onClick,
}) => {
    return (
        <StyledButton ref={forwardedRef} onClick={onClick}>
            <div>
                {label !== null && (
                    <LabelWithValue>
                        <Label>{label}</Label>
                        <Value>{children}</Value>
                    </LabelWithValue>
                )}
                {label === null && <Value>{children}</Value>}
                <div>
                    <Icon name="arrow-up-down" solid size="24px" />
                </div>
            </div>
        </StyledButton>
    )
}

const _Control: React.ComponentType<Props> = React.forwardRef(
    (props: Props, ref?: React.Ref<HTMLButtonElement>) => (
        <Control {...props} forwardedRef={ref} />
    )
)

export default _Control

// export default styled(_Control)<Props>`
//     ${({ theme }) => css`
//         width: 100%;
//         background-color: ${theme.select.backgroundColor};
//         border: ${theme.select.border};
//         font-size: ${theme.select.fontSize};
//         color: ${theme.select.color};
//         padding: ${theme.select.controlPadding};
//         text-align: left;

//         > div {
//             display: grid;
//             grid-template-columns: auto max-content;
//             align-items: center;

//             > div {
//                 color: ${theme.select.controlColor};
//             }
//         }
//     `}
// `
