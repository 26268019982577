import React, { useEffect } from "react"

export default function useElementStyles(
    ref: React.RefObject<HTMLElement>,
    fn: (el: HTMLElement) => { [key: string]: string | null }
) {
    useEffect(() => {
        if (ref.current === null) return

        const styles = typeof fn === `function` ? fn(ref.current) : fn

        const keys = Object.keys(styles) as string[]
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i]
            const value = styles[keys[i]]
            ref.current.style.setProperty(key, value)
        }
    }, [ref.current, fn])
}
