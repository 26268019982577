import React from "react"
import styled, { css } from "styled-components"
import scrollbars from "../../../../Styles/scrollbars"

const View = styled.div`
    ${({ theme }) => css`
        background-color: #ffffff;
        width: 375px;
        padding: 20px;

        * {
            color: #2d2d2d;
        }

        a {
            color: #5656cc;
        }

        // Stop images going off the view
        img {
            max-width: 100%;
            height: auto;
        }

        p {
            margin: 30px 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 30px 0;
        }

        li {
            margin-left: 20px;
        }

        figcaption {
            font-size: 0.8em;
            font-weight: bold;
            text-align: center;
            text-decoration: uppercase;
            color: ${theme.palette.textSecondary};
        }

        pre {
            width: 100%;
            padding-bottom: 10px; // Give room for the scrollbar
            overflow-x: auto;
            ${scrollbars};
        }
    `}
`

interface ReadabilityViewProps {
    html: string
}

const ReadabilityView: React.FC<ReadabilityViewProps> = ({ html }) => {
    return <View dangerouslySetInnerHTML={{ __html: html }} />
}

export default ReadabilityView
