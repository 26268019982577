import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { v4 as uuidv4 } from "uuid"

import Text from "../Text"

interface Props {
    className?: string
    label?: string
    error?: string | null
    helpText?: string
}

const Field = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content max-content max-content;
    gap: 5px 5px;
    grid-template-areas: "label error" "control control" "help help";
`

const Label = styled.label`
    ${({ theme }) => css`
        grid-area: label;

        ${theme.form.label};
    `}
`

const Error = styled.span`
    ${({ theme }) => css`
        grid-area: error;

        ${theme.form.error};
        text-align: right;
    `}
`

const Control = styled.div`
    grid-area: control;
`

const HelpText = styled(Text)`
    grid-area: help;
    margin-top: 0;
`

const FormField: React.FC<Props> = ({
    children,
    label = null,
    error = null,
    helpText = null,
}) => {
    const uuid = useMemo(() => {
        return uuidv4()
    }, [])

    // Add the ID attribute to the first child
    const control = React.Children.map(
        children,
        (child: React.ReactNode, index) => {
            if (typeof child === `string`) {
                return child
            }
            if (index === 0) {
                return React.cloneElement(child as any, {
                    id: uuid,
                })
            } else {
                return child
            }
        }
    )

    return (
        <Field>
            <Label htmlFor={uuid}>{label}</Label>
            <Error>{error}</Error>
            <Control>{control}</Control>
            {helpText && <HelpText>{helpText}</HelpText>}
        </Field>
    )
}

export default FormField
