import React from "react"
import { Provider as DatabaseProvider } from "../../../Data/Database"
import { NotesManagerProvider } from "../../../Providers/NotesManagerProvider"
import { FocusModeViewProvider } from "../../../Providers/FocusModeViewProvider"
import { FocusModeSessionsProvider } from "../../../Providers/FocusModeSessionsProvider"
import ViewMode from "../ViewMode"

import AppLayout from "../../AppLayout"

const AppView: React.FC = () => {
    return (
        <DatabaseProvider name="flowtelic">
            <NotesManagerProvider>
                <FocusModeSessionsProvider>
                    <FocusModeViewProvider>
                        <AppLayout>
                            <ViewMode />
                        </AppLayout>
                    </FocusModeViewProvider>
                </FocusModeSessionsProvider>
            </NotesManagerProvider>
        </DatabaseProvider>
    )
}

AppView.displayName = `AppView`

export default AppView
