import { useContext } from "react"
import { UUID } from "../../Data/UUID"
import { StateContext } from "./FocusModeSessionsProvider"

function useFocusModeSession(
    collectionUUID: UUID | null,
    sessionName: string | null
) {
    const sessions = useContext(StateContext)
    return (
        sessions.find(
            (session) =>
                session.collectionUUID === collectionUUID &&
                session.config.name === sessionName
        ) || null
    )
}

export { useFocusModeSession }
