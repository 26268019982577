import React from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    visible?: boolean
    secondary?: boolean
}

const Content: React.FC<Props> = ({ className, children }) => {
    return <div className={className}>{children}</div>
}

export default styled(Content)<Props>`
    ${({ theme, visible = false, secondary = false }) => css`

        ${
            !secondary &&
            css`
                padding: ${theme.properties.padding};
            `
        }
        
        display: ${visible ? `block` : `none`};
    `}
`
