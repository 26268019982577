function isValidUsername(username: string): boolean {
    const nameRegex = /^[a-zA-Z0-9\-_]+$/

    if (!username.match(nameRegex)) {
        return false
    } else {
        return username.length >= 3
    }
}

export default isValidUsername
