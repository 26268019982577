import { ContentBlock, ContentState } from "draft-js"
import { getTypeByTrigger } from "../Utils"

const findRelatedItemEntities = ({ entityType }: { entityType: string }) => {
    return (
        contentBlock: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState
    ): void => {
        // Find all content block changes
        contentBlock.findEntityRanges((character) => {
            // Get the entity key for this character
            const entityKey = character.getEntity()
            // Return if this entity type is the same as our type based on the trigger
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === entityType
            )
        }, callback)
    }
}

export default findRelatedItemEntities
