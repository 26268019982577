import { Modifier, EditorState } from "draft-js"
import { Note } from "../../../Data/Note"
import {
    getSearchText,
    getTypeByTrigger,
} from "../../RelatedEntity/Utils/index"
import trigger from "../trigger"

type EntityImmutability = "SEGMENTED" | "IMMUTABLE" | "MUTABLE"

interface AddNoteLinkProps {
    editorState: EditorState
    note: Note
}

function addNoteLink({ editorState, note }: AddNoteLinkProps): EditorState {
    // const entityMutaibility: EntityImmutability = `MUTABLE`

    // // Create the new entity that will be the link
    // const contentStateWithEntity = editorState
    //     .getCurrentContent()
    //     .createEntity(`note-link`, entityMutaibility, {
    //         uuid: note.uuid,
    //     })

    // Get the entity key from the newly created entity
    // const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    // Get the selection
    const currentSelectionState = editorState.getSelection()

    // Now get the beginning and end positions
    const { begin, end } = getSearchText(
        editorState,
        currentSelectionState,
        trigger
    )

    // Get the selection of the link search text
    const textSelection = currentSelectionState.merge({
        anchorOffset: begin,
        focusOffset: end,
    })

    // Replace the text with the final entry
    let replacedContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        textSelection,
        `[[` + note.title + `]]`,
        undefined // no inline style needed
        // entityKey
    )

    // If the text is inserted at the end, a space is appended right after for a smooth writing experience
    const blockKey = textSelection.getAnchorKey()
    const blockSize = editorState
        .getCurrentContent()
        .getBlockForKey(blockKey)
        .getLength()
    if (blockSize === end) {
        replacedContent = Modifier.insertText(
            replacedContent,
            replacedContent.getSelectionAfter(),
            ` `
        )
    }

    // Now insert it
    const newEditorState = EditorState.push(
        editorState,
        replacedContent,
        `insert-fragment`
    )

    return EditorState.forceSelection(
        newEditorState,
        replacedContent.getSelectionAfter()
    )
}

export default addNoteLink
