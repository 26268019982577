import React from "react"

interface Props {
    className?: string
    x1: number
    y1: number
    x2: number
    y2: number
}

const Line: React.FC<Props> = ({ className, x1, y1, x2, y2 }) => {
    return <line className={className} x1={x1} y1={y1} x2={x2} y2={y2} />
}

export default Line
