import React from "react"
import styled, { css } from "styled-components"

import { UUID } from "../../../Data/UUID"
import FocusModeHeader from "../../FocusMode/FocusModeHeader"
import { FocusModeSession } from "../../../Managers/NotesManager/FocusModeSessionView"
import { FilteredNotesProvider } from "../../../Providers/FilteredNotesProvider"
import { NoteProvider } from "../../../Providers/NoteProvider"
import Note from "../../Note"

const ViewLayout = styled.div`
    display: grid;
    grid-template-rows: 50px auto;
    height: 100%;
`

const NotePane = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px;
    height: 100%;
    overflow: hidden;
`

interface SingleNoteFocusViewProps {
    focusSession: FocusModeSession
    activeNoteUUID: UUID | null
    onStop: () => void
    onNoteChange: (uuid: UUID) => void
}

const SingleNoteFocusView: React.FC<SingleNoteFocusViewProps> = ({
    focusSession,
    activeNoteUUID,
    onStop,
    onNoteChange,
}) => {
    if (activeNoteUUID === null) {
        return null
    }

    function handleItemChange(item: number) {
        if (item > 0 && item <= focusSession.list.length) {
            onNoteChange(focusSession.list[item - 1].uuid)
        }
    }

    const noteNumber =
        (focusSession.list.findIndex((item) => item.uuid === activeNoteUUID) ||
            0) + 1

    return (
        <ViewLayout>
            <FocusModeHeader
                item={noteNumber}
                items={focusSession.count}
                onStop={onStop}
                onItemChange={handleItemChange}
            />
            <NotePane>
                <FilteredNotesProvider sort="recentUpdated">
                    <NoteProvider uuid={activeNoteUUID}>
                        <Note />
                    </NoteProvider>
                </FilteredNotesProvider>
            </NotePane>
        </ViewLayout>
    )
}

export default SingleNoteFocusView
