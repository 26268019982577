import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"

export interface FindNotesProps {
    collectionUUID: UUID
    type: string
    db: FlowtelicDatabase
}

export function findNotes({ db, collectionUUID, type }: FindNotesProps) {
    return db.notes.where({ type, collectionUUID }).toArray()
}
