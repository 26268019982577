import React from "react"
import styled from "styled-components"
import { DecoratorProps } from "../../../RelatedEntity"

const Decorator = styled.span`
    opacity: 0.2;
`

const ControlCharacterDecorator: React.FC<DecoratorProps> = ({ children }) => {
    return <Decorator>{children}</Decorator>
}

export default ControlCharacterDecorator
