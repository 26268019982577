import { ContentBlock } from "draft-js"
import escapeRegExp from "lodash/escapeRegExp"
import { findWithRegex } from "../Utils"
import { FindWithRegexCb } from "../Utils/types"

interface SuggestionLookupStrategyProps {
    trigger: string
    supportWhitespace: boolean
    regex: string
}

export default ({
    trigger,
    supportWhitespace,
    regex,
}: SuggestionLookupStrategyProps): ((
    contentBlock: ContentBlock,
    callback: FindWithRegexCb
) => void) => {
    const RELATE_REGEX = new RegExp(`${escapeRegExp(trigger)}${regex}`, `g`)

    return (contentBlock: ContentBlock, callback: FindWithRegexCb) => {
        findWithRegex(RELATE_REGEX, contentBlock, callback)
    }
}
