import React from "react"
import styled, { css } from "styled-components"
import { Icon, Button, ButtonMenu, MenuItem } from "ui"
import { useNote } from "../../../Providers/NoteProvider"
import { useNotesManager } from "../../../Providers/NotesManagerProvider"

const MoreButton = styled(Button)`
    position: relative;
    z-index: 100;
    opacity: 0.3;
    transition: opacity 0.2s linear;

    &:hover {
        opacity: 1;
    }
`

const ButtonToolbar = styled.div`
    position: absolute;
    height: 0;
    overflow: visible;
    z-index: 100;
    text-align: right;
    padding: 5px;
`

const Video = styled.div`
    width: 100%;
`

interface VimeoEmbedProps {
    videoID: string
}

const VimeoEmbed: React.FC<VimeoEmbedProps> = ({ videoID }) => {
    const notesManager = useNotesManager()
    const { state: noteState } = useNote()

    function handleClearPreview() {
        if (notesManager !== null && noteState.noteBody) {
            notesManager.setNotePreview({
                uuid: noteState.noteBody.uuid,
                url: null,
            })
        }
    }

    return (
        <>
            <ButtonToolbar>
                <ButtonMenu
                    button={
                        <MoreButton
                            secondary
                            iconOnly
                            icon={<Icon name="more-horizontal" size="24px" />}
                        ></MoreButton>
                    }
                >
                    <MenuItem onClick={handleClearPreview}>
                        Hide preview
                    </MenuItem>
                </ButtonMenu>
            </ButtonToolbar>
            <Video>
                <iframe
                    src={`https://player.vimeo.com/video/${videoID}?color=ff0179`}
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </Video>
        </>
    )
}

export default VimeoEmbed
