import { useContext, useState, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import {
    FilteredNotesStateContext,
    FilteredNotesDispatchContext,
} from "./FilteredNotesProvider"

export function useFilteredNotes() {
    // TODO: populate list from cache if available
    const state = useContext(FilteredNotesStateContext)
    const dispatch = useContext(FilteredNotesDispatchContext)
    const [list, setList] = useState<UUID[]>([])

    useEffect(() => {
        if (state.view === null) {
            return
        }

        state.view.list().then(setList)
        return state.view.watchList(setList)
    }, [state.view])

    return { list, state, dispatch }
}
