import React from "react"
import styled from "styled-components"
import { FormField, TextInput, Icon, Button, Text } from "ui"
import { useAccountForm } from "../../Hooks"
import Link from "../../../Link"
import routes from "../../../../routes"

const NextButton = styled(Button)`
    width: 100%;
`

const BackButton = styled(Button)`
    width: 100%;
`

const Buttons = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;
`

const ResetCode: React.FC = () => {
    const [{ email, resetCode }, dispatch] = useAccountForm()

    let nextEnabled = true
    let resetCodeIcon = null

    if (resetCode.status === `processing`) {
        nextEnabled = false
        resetCodeIcon = (
            <Icon
                name="spinner-quarter"
                size="18px"
                subtle
                spin
                spinSpeed={1}
            />
        )
    } else if (resetCode.status === `success`) {
        resetCodeIcon = <Icon name="check" size="18px" success />
    } else if (resetCode.status === `error`) {
        nextEnabled = false
        resetCodeIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    function _handleResetCodeChange(value: string) {
        dispatch({
            type: `set`,
            field: `resetCode`,
            value: value,
        })
    }

    function _handleBack() {
        dispatch({
            type: `setContext`,
            formType: `sendPasswordReset`,
        })
    }

    function _handleNext() {
        // TODO
        return
    }

    return (
        <>
            <h1>We sent you a password reset code</h1>

            <Text>
                If you have an account, we have sent you an email to
                <strong>{email.value}</strong> with a unique code for you to
                enter below.
            </Text>

            <FormField label="Password reset code" error={resetCode.error}>
                <TextInput
                    name="resetCode"
                    value={resetCode.value}
                    onChange={_handleResetCodeChange}
                    icon={resetCodeIcon}
                />
            </FormField>

            <Buttons>
                <BackButton secondary cta onClick={_handleBack}>
                    Back
                </BackButton>
                <NextButton
                    primary
                    cta
                    onClick={_handleNext}
                    disabled={!nextEnabled}
                >
                    Next
                </NextButton>
            </Buttons>

            <Text secondary centered>
                <Link to={routes.signin}>Return to sign in</Link>
            </Text>
        </>
    )
}

export default ResetCode
