import { useState } from "react"

type Dispatch<A> = (value: A) => void
type SetStateAction<S> = S | ((prevState: S) => S)

function useLocalStorage<T>(
    key: string,
    initialValue: T,
    upgradeFn?: (val: any, initialValue: T) => T
): [T, Dispatch<SetStateAction<T>>] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            let value = initialValue
            if (item !== null) {
                // Parse it and upgrade the contents if necessary
                let parsedItem = JSON.parse(item)
                if (upgradeFn) {
                    parsedItem = upgradeFn(parsedItem, initialValue)
                }

                value = { ...value, ...parsedItem }
            }

            return value
        } catch (error) {
            // If error also return initialValue
            console.log(error)
            return initialValue
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue: Dispatch<SetStateAction<T>> = (
        value: SetStateAction<T>
    ) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            // Save state
            setStoredValue(valueToStore)
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error)
        }
    }

    return [storedValue, setValue]
}

export default useLocalStorage
