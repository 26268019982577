import { useEffect } from "react"

function useOnEnter(fn: () => void) {
    function _handleKeyPress(e: KeyboardEvent) {
        e.key === `Enter` && fn()
    }

    // Listen to the enter key
    useEffect(() => {
        document.addEventListener(`keydown`, _handleKeyPress, false)

        return () => {
            document.removeEventListener(`keydown`, _handleKeyPress, false)
        }
    })
}

export default useOnEnter
