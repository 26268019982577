import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"

export interface GetCollectionProps {
    uuid: UUID
    db: FlowtelicDatabase
}

export async function getCollection({ uuid, db }: GetCollectionProps) {
    return (await db.collections.get(uuid)) || null
}
