function getNearestElement(
    element: HTMLElement | null,
    parentElementName: string
) {
    while (element !== null) {
        if (element.localName === parentElementName) {
            return element
        }
        element = element.parentElement
    }
    return null
}

export default getNearestElement
