import { NoteBody, Note, NoteType } from "../../Data/Note"
import { UUID } from "../../Data/UUID"
import { v4 as uuidv4 } from "uuid"
import { extractMetadataFromNoteBody } from "../../Data/Database/Lib/Utils/"
import { importMarkdown } from "../../Utils"
import collectionPresets from "../../CollectionPresets"
import { CollectionConfig } from "../../Data/Collection"

function getDefaultWorkflowState(
    noteType: string,
    collectionConfig: CollectionConfig
) {
    const noteTypeConfig =
        collectionConfig.noteTypes.find((nt) => nt.name === noteType) || null
    if (noteTypeConfig === null) {
        return null
    }

    // Find the workflow state
    const workflow =
        collectionConfig.workflows.find(
            (w) => w.name === noteTypeConfig.workflow
        ) || null

    if (workflow === null) {
        return null
    }

    // Now find the first state for the workflow
    if (workflow.states.length === 0) {
        return null
    }

    return workflow.states[0].name
}

function getCollectionConfig(collectionUUID: UUID) {
    // TODO Support different collection presets and custom collection types
    return collectionPresets[0].config
}

function convertMarkdownToNote(
    markdown: string,
    noteType: NoteType,
    collectionUUID: UUID,
    updated: Date = new Date()
) {
    const importedData = importMarkdown(markdown)
    const metadata = extractMetadataFromNoteBody(importedData.content)

    let workflowState = importedData.config.workflowState
    if (workflowState === null) {
        const collectionConfig = getCollectionConfig(collectionUUID)
        workflowState = getDefaultWorkflowState(
            importedData.config.type,
            collectionConfig
        )
    }

    const note: Note = {
        uuid: uuidv4(),
        type: importedData.config.type,
        title: metadata.title,
        preview: metadata.preview || ``,
        updated: updated,
        collectionUUID: collectionUUID,
        workflowState: workflowState,
    }

    const noteBody: NoteBody = {
        uuid: note.uuid,
        body: importedData.content,
    }

    return { note, noteBody }
}

export default convertMarkdownToNote
