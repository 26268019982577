import React from "react"
import styled from "styled-components"
import { FormField, TextInput, Icon, Button } from "ui"
import { useAccountForm } from "../../Hooks"

const NextButton = styled(Button)`
    width: 100%;
`

const BackButton = styled(Button)`
    width: 100%;
`

const Buttons = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;
`

const Setup1: React.FC = () => {
    const [
        { processing, username, password, confirmPassword },
        dispatch,
    ] = useAccountForm()

    let nextEnabled = true
    let usernameIcon = null

    if (username.status === `processing`) {
        nextEnabled = false
        usernameIcon = (
            <Icon
                name="spinner-quarter"
                size="18px"
                subtle
                spin
                spinSpeed={1}
            />
        )
    } else if (username.status === `success`) {
        usernameIcon = <Icon name="check" size="18px" success />
    } else if (username.status === `error`) {
        nextEnabled = false
        usernameIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    let passwordIcon = null

    if (password.status === `success`) {
        passwordIcon = <Icon name="check" size="18px" success />
    } else if (password.status === `error`) {
        nextEnabled = false
        passwordIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    let confirmPasswordIcon = null

    if (confirmPassword.status === `success`) {
        confirmPasswordIcon = <Icon name="check" size="18px" success />
    } else if (confirmPassword.status === `error`) {
        nextEnabled = false
        confirmPasswordIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    function _handleUsernameChange(value: string) {
        dispatch({
            type: `set`,
            field: `username`,
            value: value,
        })
    }

    function _handlePasswordChange(value: string) {
        dispatch({
            type: `set`,
            field: `password`,
            value: value,
        })
    }

    function _handleConfirmPasswordChange(value: string) {
        dispatch({
            type: `set`,
            field: `confirmPassword`,
            value: value,
        })
    }

    function _handlePasswordBlur() {
        dispatch({
            type: `setFieldBlur`,
            field: `password`,
        })
    }

    function _handleConfirmPasswordBlur() {
        dispatch({
            type: `setFieldBlur`,
            field: `confirmPassword`,
        })
    }

    function _handleUsernameBlur() {
        dispatch({ type: `setFieldBlur`, field: `username` })
    }

    return (
        <>
            <h1>Set up your account</h1>

            <FormField
                label="Username"
                error={username.error}
                helpText="This will be used as your unique handle so other people can @
                you."
            >
                <TextInput
                    name="username"
                    value={username.value}
                    onChange={_handleUsernameChange}
                    icon={usernameIcon}
                    focus={username.focus}
                    onBlur={_handleUsernameBlur}
                    tabIndex={1}
                />
            </FormField>

            <FormField label="Password" error={password.error}>
                <TextInput
                    name="password"
                    type="password"
                    value={password.value}
                    onChange={_handlePasswordChange}
                    onBlur={_handlePasswordBlur}
                    icon={passwordIcon}
                    focus={password.focus}
                    tabIndex={2}
                />
            </FormField>

            <FormField label="Confirm password" error={confirmPassword.error}>
                <TextInput
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword.value}
                    onChange={_handleConfirmPasswordChange}
                    onBlur={_handleConfirmPasswordBlur}
                    icon={confirmPasswordIcon}
                    focus={confirmPassword.focus}
                    tabIndex={3}
                />
            </FormField>

            <Buttons>
                <BackButton
                    secondary
                    cta
                    onClick={() =>
                        dispatch({
                            type: `setContext`,
                            formType: `signup`,
                        })
                    }
                    tabIndex={5}
                >
                    Back
                </BackButton>
                <NextButton
                    primary
                    cta
                    onClick={() => {
                        return
                    }}
                    disabled={!nextEnabled}
                    tabIndex={4}
                >
                    Next
                </NextButton>
            </Buttons>
        </>
    )
}

export default Setup1
