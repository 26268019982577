import { useEffect, useState } from "react"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import { CollectionListEntry } from "../../Managers/NotesManager"

export function useCollections() {
    const notesManager = useNotesManager()
    const [collections, setCollections] = useState<CollectionListEntry[]>([])

    useEffect(() => {
        if (notesManager === null) {
            return
        }

        notesManager.collections.list().then(setCollections)
        return notesManager.collections.watchList(setCollections)
    }, [notesManager])

    return collections
}
