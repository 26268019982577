import { Note } from "../Data/Note"
import yaml from "js-yaml"
import { NoteYamlConfig } from "./types"

function noteToYaml(note: Note) {
    const config: NoteYamlConfig = {
        type: note.type,
        workflowState: note.workflowState,
    }
    const result: string = yaml.dump(config)

    return result
}

export default noteToYaml
