import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import Control from "./Control"
import Menu, { Item } from "../Menu"
import Icon from "../Icon"
import { useElementBounds } from "../../../Hooks"

import { OptionType } from "./OptionType"

interface Props {
    className?: string
    options: OptionType[]
    renderItem?: (option: OptionType) => React.ReactNode
    selectedIndex?: number
    minListWidth?: number
    value?: string
    onChange?: (value: string) => void
    error?: boolean
    placeholder?: string
    icon?: JSX.Element
}

const Combo: React.FC<Props> = ({
    className,
    value,
    icon,
    onChange,
    error = false,
    options = [],
    renderItem,
    minListWidth = null,
}) => {
    const controlRef = useRef(null)
    const [_value, _setValue] = useState(value)
    const [_isOpen, _setIsOpen] = useState(false)
    const controlBounds = useElementBounds(controlRef, _isOpen)

    useEffect(() => {
        _setValue(value)
    }, [value])

    function _handleChange(value: string) {
        _setValue(value)
        onChange && onChange(value)
    }

    function _handleMenuOpen() {
        _setIsOpen(true)
    }

    function _handleMenuClose() {
        _setIsOpen(false)
    }

    function _handleSelect(index: number) {
        const value = options[index].value
        _setValue(value)
        _setIsOpen(false)
        onChange && onChange(value)
    }

    const items = options.map((option, index) => (
        <Item
            key={option.value}
            selected={option.value === _value}
            onClick={() => _handleSelect(index)}
            icon={
                (option.icon && <Icon name={option.icon} size="18px" />) || null
            }
        >
            {(renderItem && renderItem(option)) || option.label}
        </Item>
    ))

    return (
        <div className={className} data-testid="combo">
            <Control
                ref={controlRef}
                icon={icon}
                value={_value}
                onChange={_handleChange}
                error={error}
                onMenuOpen={_handleMenuOpen}
            ></Control>
            {controlBounds && (
                <Menu
                    x={controlBounds.x}
                    y={controlBounds.y + controlBounds.height}
                    minWidth={
                        minListWidth === null
                            ? controlBounds.width
                            : minListWidth
                    }
                    visible={true}
                    onClose={_handleMenuClose}
                >
                    {items}
                </Menu>
            )}
        </div>
    )
}

export default styled(Combo)<Props>``
