import { RawDraftContentState } from "draft-js"

function rawContentStateToMarkdown(body: RawDraftContentState | null = null) {
    if (body === null) {
        return ``
    }

    const blocks = body.blocks.map((block) => {
        switch (block.type) {
            case `header-one`:
                return `# ` + block.text + `\n`
            case `header-two`:
                return `## ` + block.text + `\n`
            case `header-three`:
                return `### ` + block.text + `\n`
            case `header-four`:
                return `#### ` + block.text + `\n`
            case `header-five`:
                return `##### ` + block.text + `\n`
            case `header-six`:
                return `###### ` + block.text + `\n`
            default:
                return block.text
        }
    })

    return blocks.join(`\n`)
}

export default rawContentStateToMarkdown
