import React, { useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import ResizeObserver from "resize-observer-polyfill"

import ReactSlider from "react-slider"

import Track from "./Track"
import Thumb from "./Thumb"

interface Props {
    className?: string
    min?: number
    max?: number
    value: number
    onChange: (value: number) => void
}

const Slider: React.FC<Props> = ({
    className,
    min = 0,
    max = 100,
    value = 100,
    onChange,
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const sliderRef = useRef<any | null>(null)
    // const sliderRef =
    //     useRef<React.RefObject<
    //         React.Component<ReactSlider.ReactSliderProps, any, any>
    //     >(null)

    // The slider needs to be resized when a parent container becomes visible (e.g. via a display: block)
    // We'll use a ResizeObserver to detect this and check the offsetParent property on the wrapping div
    // because this will only be null if the slider is hidden
    useEffect(() => {
        if (sliderRef.current === null || containerRef.current === null) {
            return
        }

        function _handleResize() {
            if (containerRef.current === null || sliderRef.current === null)
                return

            // If offsetParent is null, the element is not visible
            if (containerRef.current.offsetParent !== null) {
                sliderRef.current.resize()
            }
        }

        // Observe the slider dom element which will trigger when it's shown via a parent container visibility
        const ro = new ResizeObserver(_handleResize)
        ro.observe(containerRef.current)

        // Cleanup
        return () => {
            ro.disconnect()
        }
    }, [containerRef.current, sliderRef.current])

    function _handleChange(value: number | number[] | null | undefined) {
        if (typeof value === `number`) {
            onChange && onChange(value as number)
        }
    }

    return (
        <div ref={containerRef} className={className} data-testid="slider">
            <ReactSlider
                ref={sliderRef}
                min={min}
                max={max}
                value={value}
                renderTrack={Track}
                renderThumb={Thumb}
                onChange={_handleChange}
            />
        </div>
    )
}

export default styled(Slider)<Props>`
    ${(props) => css`
        > div {
            width: 100%;
            height: 5px;
        }
    `}
`
