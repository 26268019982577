import React, { useReducer, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import convertMarkdownToNote from "./convertMarkdownToNote"
import { useCollectionsView } from "../../Providers/CollectionsViewProvider"

interface NoteImportState {
    queue: File[]
    processingFile: File | null
    results: {
        filename: string
        error: string | null
    }[]
}

const defaultState: NoteImportState = {
    queue: [],
    processingFile: null,
    results: [],
}

type Action =
    | { type: "importFiles"; fileList: FileList }
    | { type: "fileCompleted"; error?: string }
    | { type: "clear" }
type Dispatch = (action: Action) => void

function reducer(state: NoteImportState, action: Action) {
    if (action.type === `importFiles`) {
        let files = [...state.queue]
        for (let i = 0; i < action.fileList.length; i++) {
            files.push(action.fileList[i])
        }

        let processingFile = state.processingFile
        if (processingFile === null && files.length > 0) {
            processingFile = files.shift() || null
        }

        return {
            ...state,
            queue: files,
            processingFile,
            results: [],
        }
    } else if (action.type === `fileCompleted`) {
        let results = [...state.results]
        if (state.processingFile !== null) {
            results.push({
                filename: state.processingFile.name,
                error: action.error || null,
            })
        }

        const newQueue = [...state.queue]
        const nextItem = newQueue.shift() || null
        return {
            ...state,
            queue: newQueue,
            processingFile: nextItem,
            results,
        }
    } else if (action.type === `clear`) {
        return {
            ...defaultState,
        }
    }
    return state
}

export const NotesImportStateContext = React.createContext<NoteImportState>(
    defaultState
)

export const NotesImportDispatchContext = React.createContext<Dispatch>(() => {
    return
})

export const NotesImportProvider: React.FC = ({ children }) => {
    const notesManager = useNotesManager()
    const collectionsView = useCollectionsView()
    const [state, dispatch] = useReducer(reducer, defaultState)

    const collectionUUID = collectionsView.state.selectedCollectionUUID

    useEffect(() => {
        async function process() {
            if (
                state.processingFile !== null &&
                notesManager !== null &&
                collectionUUID !== null
            ) {
                if (state.processingFile.size > 1 * 1024 * 1024) {
                    dispatch({
                        type: `fileCompleted`,
                        error: `File is greater than 1MB`,
                    })
                } else {
                    const noteDate = new Date(state.processingFile.lastModified)
                    const markdown = await state.processingFile.text()
                    // Convert it to notes
                    const { note, noteBody } = convertMarkdownToNote(
                        markdown,
                        `PermanentNote`,
                        collectionUUID,
                        noteDate
                    )

                    notesManager.putNote({ note, noteBody })

                    dispatch({
                        type: `fileCompleted`,
                    })
                }
            }
        }
        process()
    }, [state.processingFile, collectionUUID, notesManager])

    return (
        <NotesImportStateContext.Provider value={state}>
            <NotesImportDispatchContext.Provider value={dispatch}>
                {children}
            </NotesImportDispatchContext.Provider>
        </NotesImportStateContext.Provider>
    )
}
