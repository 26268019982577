import React from "react"
import styled, { css } from "styled-components"

import Button from "../../Button"
import Icon from "../../Icon"

interface Props {
    ref?: React.Ref<HTMLButtonElement> | null
    forwardedRef?: React.Ref<HTMLButtonElement> | null
    className?: string
    onClick?: () => void
}

const Control: React.FC<Props> = ({
    forwardedRef,
    className,
    children,
    onClick,
}) => {
    return (
        <Button ref={forwardedRef} className={className} onClick={onClick}>
            <div>
                <Icon name="gallery" size="18px" />
                <span>{children}</span>
                <div>
                    <Icon name="arrow-up-s" solid size="16px" />
                    <Icon name="arrow-down-s" solid size="16px" />
                </div>
            </div>
        </Button>
    )
}

const _Control: React.ComponentType<Props> = React.forwardRef(
    (props: Props, ref?: React.Ref<HTMLButtonElement>) => (
        <Control {...props} forwardedRef={ref} />
    )
)

export default styled(_Control)<Props>`
    ${({ theme }) => css`
        width: 100%;
        background-color: ${theme.select.backgroundColor};
        font-size: ${theme.select.fontSize};
        color: ${theme.select.color};
        padding: ${theme.select.controlPadding};
        text-align: left;

        > div {
            display: grid;
            grid-template-columns: max-content auto max-content;
            grid-gap: 10px;
            align-items: center;

            > ${Icon} {
                color: ${theme.select.controlColor};
            }

            > span {
                color: ${theme.select.controlColor};
            }

            > div {
                color: ${theme.select.controlColor};

                ${Icon} {
                    display: block;
                    line-height: 6px;
                }
            }
        }
    `}
`
