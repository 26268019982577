import React, { useReducer, useEffect } from "react"
import produce from "immer"
import { State, FormType } from "./types"
import _initialState from "./initialState"
import reducer from "./reducer"

import { StateContext, DispatchContext } from "./Context"

const immerReducer = produce(reducer)

interface Props {
    initialState?: State
    activeForm: FormType
}

const Provider: React.FC<Props> = ({
    children,
    initialState = _initialState,
    activeForm,
}) => {
    const [state, dispatch] = useReducer(immerReducer, {
        ...initialState,
        activeForm,
    })

    // Watch for a change in active form
    useEffect(() => {
        dispatch({ type: `setContext`, formType: activeForm })
    }, [activeForm])

    // // Watch for actions
    // useEffect(() => {
    //     ;(async () => {
    //         if (state.query.action === `createAccount`) {
    //             // Simulate network
    //             await new Promise((f) => setTimeout(f, 2000))

    //             dispatch({ type: `createAccountResponse`, error: null })
    //         }
    //     })()
    // }, [state.query.action])

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children instanceof Function
                    ? children(state, dispatch)
                    : children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

export default Provider
