import { useContext } from "react"
import { UUID } from "../../Data/UUID"
import { StateContext } from "./FocusModeSessionsProvider"

function useCollectionFocusSessions(collectionUUID: UUID | null) {
    const sessions = useContext(StateContext)
    return sessions.filter(
        (session) => session.collectionUUID === collectionUUID
    )
}

export { useCollectionFocusSessions }
