import React from "react"
import styled from "styled-components"
import { FormField, Button, Checkbox } from "ui"
import { useAccountForm } from "../../Hooks"
import { Interest } from "../../Data/types"

const NextButton = styled(Button)`
    width: 100%;
`

const BackButton = styled(Button)`
    width: 100%;
`

const Buttons = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;
`

const Columns = styled.div`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    ${Checkbox} {
        flex: 50%;
        margin-bottom: 10px;
    }
`

const Setup2: React.FC = () => {
    const [{ processing, usage, interests }, dispatch] = useAccountForm()

    function _handlePersonalUsage(selected: boolean) {
        dispatch({ type: `setUsage`, field: `personal`, selected })
    }

    function _handleProfessionalUsage(selected: boolean) {
        dispatch({ type: `setUsage`, field: `professional`, selected })
    }

    function _handleInterest(value: string, selected: boolean) {
        dispatch({ type: `setInterest`, value, selected })
    }

    function _handleSkip() {
        return
    }

    function _handleFinish() {
        return
    }

    return (
        <>
            <FormField label="How do you plan to use Flowtelic?">
                <Columns>
                    <Checkbox
                        name="personally"
                        label="Personally"
                        checked={usage.personal}
                        onChange={_handlePersonalUsage}
                    />
                    <Checkbox
                        name="professionally"
                        label="Professionally"
                        checked={usage.professional}
                        onChange={_handleProfessionalUsage}
                    />
                </Columns>
            </FormField>

            <FormField label="Which of the following are you interested in/">
                <Columns>
                    {interests.map((interest: Interest) => (
                        <Checkbox
                            key={interest.value}
                            name={interest.value}
                            label={interest.label}
                            checked={interest.selected}
                            onChange={(selected: boolean) =>
                                _handleInterest(interest.value, selected)
                            }
                        />
                    ))}
                </Columns>
            </FormField>

            <Buttons>
                <BackButton secondary cta onClick={_handleSkip}>
                    Skip
                </BackButton>
                <NextButton primary cta onClick={_handleFinish}>
                    Finish
                </NextButton>
            </Buttons>
        </>
    )
}

export default Setup2
