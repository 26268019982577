import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { Icon, Button, ButtonMenu, MenuItem } from "ui"
import { useWebPreview, WebPreviewFormat } from "../../../Data/WebPreview"
import { useNote } from "../../../Providers/NoteProvider"
import { useRecentWebPreviewFormat } from "../../../Data/WebPreview"
import { useNotesManager } from "../../../Providers/NotesManagerProvider"
import ReadabilityView from "./ReadabilityView"
import { getNearestElement } from "../../../Utils"
const LoaderContainer = styled.div<{ width: number }>`
    ${({ width = 375 }) => css`
        width: ${width}px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`

const MoreButton = styled(Button)`
    position: relative;
    z-index: 100;
    opacity: 0.3;
    transition: opacity 0.2s linear;

    &:hover {
        opacity: 1;
    }
`

const ButtonToolbar = styled.div`
    position: absolute;
    height: 0;
    overflow: visible;
    z-index: 100;
    text-align: right;
    padding: 5px;
`

interface WebPreviewProps {
    url: string
}

const WebPreview: React.FC<WebPreviewProps> = ({ url }) => {
    const notesManager = useNotesManager()
    const contentRef = useRef<HTMLDivElement | null>(null)
    const { state: noteState } = useNote()
    const [recentFormat, setRecentFormat] = useRecentWebPreviewFormat()
    const [format, setFormat] = useState<WebPreviewFormat>(
        noteState.noteBody?.previewFormat || recentFormat.format
    )
    const previewContent = useWebPreview(url, format)

    useEffect(() => {
        setRecentFormat({ format })

        if (
            notesManager !== null &&
            noteState.noteBody &&
            // Only update it if it's changed
            noteState.noteBody.previewFormat !== format
        ) {
            // Save the format to the note
            notesManager.setNotePreview({
                uuid: noteState.noteBody.uuid,
                format,
            })
        }
    }, [notesManager, noteState.note, format])

    useEffect(() => {
        if (noteState.noteBody) {
            // Update the local format
            setFormat(noteState.noteBody.previewFormat || `Readability`)
        }
    }, [noteState.noteBody?.previewFormat])

    useEffect(() => {
        const element = contentRef.current

        if (element === null) {
            return
        }

        element.addEventListener(`click`, handleContentClick)
        return () => {
            if (element !== null) {
                element.removeEventListener(`click`, handleContentClick)
            }
        }
    }, [contentRef.current])

    function handleContentClick(e: MouseEvent) {
        const link = getNearestElement(e.target as HTMLElement, `a`)
        if (link !== null) {
            e.preventDefault()
            const target = link.getAttribute(`href`)
            // Open the target link in a new tab
            Object.assign(document.createElement(`a`), {
                target: `_blank`,
                rel: `noreferrer noopener`,
                href: target,
            }).click()
        }
    }

    function handleClearPreview() {
        if (notesManager !== null && noteState.noteBody) {
            notesManager.setNotePreview({
                uuid: noteState.noteBody.uuid,
                url: null,
            })
        }
    }

    let content = null
    if (previewContent === null) {
        content = (
            <LoaderContainer width={375}>
                {` `}
                <Icon
                    name="spinner-circle"
                    size="24px"
                    subtle
                    spin
                    spinSpeed={1}
                />
            </LoaderContainer>
        )
    } else if (format === `Readability`) {
        content = <ReadabilityView html={previewContent} />
    } else if (format === `SmallSvg` || format === `LargeSvg`) {
        content = (
            <div
                dangerouslySetInnerHTML={{
                    __html: previewContent,
                }}
            />
        )
    } else {
        content = <img src={previewContent} />
    }

    return (
        <>
            <ButtonToolbar>
                <ButtonMenu
                    button={
                        <MoreButton
                            secondary
                            iconOnly
                            icon={<Icon name="more-horizontal" size="24px" />}
                        ></MoreButton>
                    }
                >
                    <MenuItem onClick={() => setFormat(`Readability`)}>
                        Readability mode
                    </MenuItem>
                    <MenuItem onClick={() => setFormat(`SmallSvg`)}>
                        Mobile vector mode
                    </MenuItem>
                    <MenuItem onClick={() => setFormat(`LargeSvg`)}>
                        Desktop vector mode
                    </MenuItem>
                    <MenuItem onClick={handleClearPreview}>
                        Hide preview
                    </MenuItem>
                </ButtonMenu>
            </ButtonToolbar>
            <div ref={contentRef}>{content}</div>
        </>
    )
}

export default WebPreview
