import { Draft } from "immer"
import { isValidEmail, isValidPassword } from "../../../Validation"
import { State, Action, Field } from "./types"

export default (draft: Draft<State>, action: Action) => {
    switch (action.type) {
        case `set`:
            if (draft[action.field].value !== action.value) {
                draft[action.field].error = null
                draft[action.field].status = `pending`
            }
            draft[action.field].value = action.value

            // If we're setting the confirm password, see if it matches
            if (action.field === `confirmPassword`) {
                draft.confirmPassword.error = null
                if (
                    draft.password.status === `success` &&
                    draft.confirmPassword.value === draft.password.value
                ) {
                    draft.confirmPassword.status = `success`
                } else {
                    draft.confirmPassword.status = `pending`
                }
            }
            break
        case `setFieldBlur`:
            draft[action.field].focus = false

            // Validate the password fields
            if (action.field === `password`) {
                if (!isValidPassword(draft.password.value)) {
                    draft.password.status = `error`
                    draft.password.error = `Invalid`
                } else {
                    draft.password.status = `success`
                }
            } else if (action.field === `confirmPassword`) {
                if (draft.password.value !== draft.confirmPassword.value) {
                    draft.confirmPassword.error = `Does not match`
                    draft.confirmPassword.status = `error`
                }
            }
            break
        case `setProcessing`:
            draft[action.field].status = `processing`
            draft[action.field].error = null
            break
        case `setPending`:
            draft[action.field].status = `pending`
            // draft[action.field].error = null
            break
        case `resolveProcessing`:
            draft[action.field].status = action.error ? `error` : `success`
            draft[action.field].error = action.error || null
            break
        case `setUsage`:
            if (action.field === `personal`) {
                draft.usage.personal = action.selected
            } else if (action.field === `professional`) {
                draft.usage.professional = action.selected
            }
            break

        case `setInterest`:
            const interest = draft.interests.find(
                (interest) => interest.value === action.value
            )
            if (interest) {
                interest.selected = action.selected
            }
            break

        // Form actions
        case `setContext`:
            draft.activeForm = action.formType
            break

        case `createAccount`:
            // Validate the email
            if (!isValidEmail(draft.email.value)) {
                draft.email.error =
                    draft.email.value === `` ? `Required` : `Not a valid email`
                draft.email.status = `error`
                draft.email.focus = true
                return
            }

            // Make sure the email validation was successful (i.e. not in use0
            if (draft.email.status !== `success`) {
                draft.email.focus = true
                return
            }

            // Move to the username setup
            draft.activeForm = `signupUsernamePassword`
            draft.username.focus = true
            draft.formStage = 1

            break
        case `createAccountResponse`:
            draft.processing = false // no longer processing
            draft.query = {
                action: null,
                status: `pending`,
                error: action.error,
            }

            if (action.error === null) {
                // Success, move to the setup1 form
                draft.activeForm = `signupUsernamePassword`
            }
            break
    }
}
