import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
    }

    #root {
        height: 100%;
    }
`

const AppLayout: React.FC = ({ children }) => {
    return (
        <>
            <GlobalStyle />
            {children}
        </>
    )
}

export default AppLayout
