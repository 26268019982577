import { useMemo } from "react"
import {
    EditorState,
    DraftHandleValue,
    RichUtils,
    ContentState,
    ContentBlock,
} from "draft-js"
import { EditorPlugin } from "@draft-js-plugins/editor"
import { insertEmptyBlock } from "../Utils"

import createHeadingStrategy from "./createHeadingStrategy"
import createHeadingDecorator from "./createHeadingDecorator"

type SetEditorState = (editorState: EditorState) => void
type GetEditorState = () => EditorState

export default function useHashtag(): EditorPlugin {
    const plugin = useMemo<EditorPlugin>(() => {
        const store: {
            getEditorState?: GetEditorState
            setEditorState?: SetEditorState
        } = {
            getEditorState: undefined,
            setEditorState: undefined,
        }

        return {
            decorators: [
                {
                    strategy: createHeadingStrategy(`#`),
                    component: createHeadingDecorator(`h1`),
                },
                {
                    strategy: createHeadingStrategy(`##`),
                    component: createHeadingDecorator(`h2`),
                },
                {
                    strategy: createHeadingStrategy(`###`),
                    component: createHeadingDecorator(`h3`),
                },
                {
                    strategy: createHeadingStrategy(`####`),
                    component: createHeadingDecorator(`h4`),
                },
                {
                    strategy: createHeadingStrategy(`#####`),
                    component: createHeadingDecorator(`h5`),
                },
                {
                    strategy: createHeadingStrategy(`######`),
                    component: createHeadingDecorator(`h6`),
                },
            ],
            initialize: ({ getEditorState, setEditorState }) => {
                store.getEditorState = getEditorState
                store.setEditorState = setEditorState
            },
            handleBeforeInput(
                character: string,
                editorState: EditorState,
                eventTimestamp: number
            ): DraftHandleValue {
                // Ignore normal characters
                if (character.match(/[A-z0-9_*~`]/)) {
                    return `not-handled`
                }

                // Check the character for state

                const currentSelection = editorState.getSelection()
                const key = currentSelection.getStartKey()
                const text = editorState
                    .getCurrentContent()
                    .getBlockForKey(key)
                    .getText()
                const position = currentSelection.getAnchorOffset()
                // Insert the character in the line to test with
                const line = [
                    text.slice(0, position),
                    character,
                    text.slice(position),
                ].join(``)
                const blockType = RichUtils.getCurrentBlockType(editorState)

                const headings = [`#`, `##`, `###`, `####`, `#####`, `######`]
                const headingTypes = [
                    `header-one`,
                    `header-two`,
                    `header-three`,
                    `header-four`,
                    `header-five`,
                    `header-six`,
                ]
                for (let i = 0; i < headings.length; i += 1) {
                    if (
                        line.indexOf(`${headings[i]} `) === 0 &&
                        blockType !== `code-block`
                    ) {
                        // Convert the # heading to a heading type

                        // change current block type
                        const headerType = headingTypes[i]
                        // Remove the line header
                        const newLine = line.replace(/^#+\s/, ``)

                        const newEditorState = changeCurrentBlockType(
                            editorState,
                            headerType,
                            newLine
                        )

                        store.setEditorState &&
                            store.setEditorState(newEditorState)
                        return `handled`
                    }
                }

                return `not-handled`
            },
            handleReturn(event: React.KeyboardEvent, editorState: EditorState) {
                const newEditorState = checkReturnForState(editorState, event, {
                    insertEmptyBlockOnReturnWithModifierKey: true,
                })
                if (editorState !== newEditorState) {
                    store.setEditorState && store.setEditorState(newEditorState)
                    return `handled`
                }
                return `not-handled`
            },
        }
    }, [])

    function checkReturnForState(
        editorState: EditorState,
        ev: React.KeyboardEvent,
        {
            insertEmptyBlockOnReturnWithModifierKey,
        }: { insertEmptyBlockOnReturnWithModifierKey: boolean }
    ) {
        let newEditorState = editorState
        const contentState = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const key = selection.getStartKey()
        const currentBlock = contentState.getBlockForKey(key)
        const type = currentBlock.getType()
        const text = currentBlock.getText()
        // if (/-list-item$/.test(type) && text === ``) {
        //     newEditorState = leaveList(editorState)
        // }
        if (
            newEditorState === editorState &&
            insertEmptyBlockOnReturnWithModifierKey &&
            (ev.ctrlKey ||
                ev.shiftKey ||
                ev.metaKey ||
                ev.altKey ||
                (/^header-/.test(type) &&
                    selection.isCollapsed() &&
                    selection.getEndOffset() === text.length))
        ) {
            newEditorState = insertEmptyBlock(editorState)
        }
        // if (
        //     newEditorState === editorState &&
        //     type !== `code-block` &&
        //     /^```([\w-]+)?$/.test(text)
        // ) {
        //     newEditorState = handleNewCodeBlock(editorState)
        // }
        // if (newEditorState === editorState && type === `code-block`) {
        //     if (/```\s*$/.test(text)) {
        //         newEditorState = changeCurrentBlockType(
        //             newEditorState,
        //             type,
        //             text.replace(/\n```\s*$/, ``)
        //         )
        //         newEditorState = insertEmptyBlock(newEditorState)
        //     } else {
        //         newEditorState = insertText(editorState, `\n`)
        //     }
        // }
        // if (editorState === newEditorState) {
        //     newEditorState = handleInlineStyle(editorState, `\n`)
        // }
        return newEditorState
    }

    const changeCurrentBlockType = (
        editorState: EditorState,
        type: string,
        text: string,
        blockMetadata: Record<string, unknown> = {}
    ) => {
        const currentContent = editorState.getCurrentContent()
        const selection = editorState.getSelection()
        const key = selection.getStartKey()
        const blockMap = currentContent.getBlockMap()
        const block = blockMap.get(key)
        const data = block.getData().merge(blockMetadata)
        const newBlock = block.merge({
            type,
            data,
            text: text || ``,
        }) as ContentBlock
        const newSelection = selection.merge({
            anchorOffset: 0,
            focusOffset: 0,
        })
        const newContentState = currentContent.merge({
            blockMap: blockMap.set(key, newBlock),
            selectionAfter: newSelection,
        }) as ContentState
        return EditorState.push(
            editorState,
            newContentState,
            `change-block-type`
        )
    }

    return plugin
}
