import React, { useState, useEffect } from "react"

export default function useElementBounds(
    ref: React.RefObject<HTMLElement>,
    enabled = true
) {
    const [position, setPosition] = useState<DOMRect | null>(null)

    function _calculate() {
        if (ref.current === null || !enabled) {
            setPosition(null)
            return
        }

        setPosition(ref.current.getBoundingClientRect())
    }

    useEffect(_calculate, [ref.current, enabled])

    return position
}
