import React from "react"
import styled, { css } from "styled-components"

import Button from "../Button"
import Icon from "../Icon"
import Heading from "./Heading"

interface Props {
    className?: string
    title: string
    addable?: boolean
    onAdd?: () => void
    addTooltip?: string
    testId?: string
}

const PropertyList: React.FC<Props> = ({
    className,
    children,
    title,
    addable = false,
    onAdd = undefined,
    addTooltip,
    testId = `property-list`,
}) => {
    let addButton = null
    if (addable) {
        addButton = (
            <Button tooltip={addTooltip} onClick={onAdd}>
                <Icon name="add-circle" size="18px" />
            </Button>
        )
    }

    return (
        <div className={className} data-testid={testId}>
            <Heading button={addButton}>{title}</Heading>
            {children}
        </div>
    )
}

export default styled(PropertyList)<Props>`
    ${({ theme }) => css`
        padding: ${theme.properties.padding};
    `}
`
