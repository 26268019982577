import React from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
}

const Heading: React.FC<Props> = ({ className, children }) => {
    return <div className={className}>{children}</div>
}

export default styled(Heading)<Props>`
    ${({ theme }) => css`
        width: 100%;
        background-color: ${theme.sidebar.headingBackgroundColor};
        color: ${theme.sidebar.headingColor};
        font-size: ${theme.sidebar.fontSize};
        padding: 10px;
    `}
`
