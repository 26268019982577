import React from "react"
import styled from "styled-components"

interface Props {
    className?: string
}

const Item: React.FC<Props> = ({ className }) => {
    return <div className={className} />
}

export default styled(Item)<Props>``
