import React from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    button?: JSX.Element | null
}

const Heading: React.FC<Props> = ({ className, children, button = null }) => {
    return (
        <div className={className}>
            <div>{children}</div>
            {button}
        </div>
    )
}

export default styled(Heading)<Props>`
    ${({ theme }) => css`
        font-size: ${theme.properties.labelFontSize};
        color: ${theme.properties.labelColor};

        display: grid;
        grid-template-columns: auto max-content;
        align-items: center;
    `}
`
