import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"
import { Collection } from "../../Collection"

export interface PutCollectionProps {
    db: FlowtelicDatabase
    view?: UUID | null
    collection: Collection
}

export async function putCollection({
    db,
    view = null,
    collection,
}: PutCollectionProps) {
    await db.transaction(`rw`, db.collections, (tx) => {
        // @ts-ignore
        tx.source = view !== null ? `${db.source}:${view}` : db.source
        db.collections.put(collection)
    })
}
