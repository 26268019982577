import React from "react"
import styled, { css } from "styled-components"
import { useCollectionsView } from "../../../Providers/CollectionsViewProvider"
import { useCollectionViewState } from "../../../Providers/CollectionViewProvider"
import { FilteredNotesProvider } from "../../../Providers/FilteredNotesProvider"
import { NoteProvider } from "../../../Providers/NoteProvider"
import { useCollectionView } from "../../../Data/Collection"

import NoteListSidebar from "../../NoteListSidebar"
import Header from "../../Header"

import Note from "../../Note"

const NoteListContainer = styled.div<{ show: boolean }>`
    ${({ show }) => css`
        width: ${show ? `250px` : `0px`};
        overflow: hidden;

        transition: width 0.2s ease;
        height: 100%;
    `}
`

const NotePane = styled.div`
    padding: 50px;
    height: 100%;
    // display: grid;
    // grid-template-columns: auto;
    overflow: hidden;
`

const ViewLayout = styled.div<{ visible: boolean }>`
    ${({ visible }) => css`
        display: ${visible ? `grid` : `none`};
        grid-template-rows: 50px auto;
        height: 100%;
    `}
`

const Body = styled.div`
    display: grid;
    grid-template-columns: max-content auto max-content;
    grid-template-rows: auto;
    height: 100%;
    overflow: hidden;
`

const PinnedNote = styled.div<{ visible: boolean }>`
    height: 100%;
    overflow: hidden;

    ${({ visible = false }) => css`
        width: ${visible ? `300px` : `0px`};
    `}
`

const CollectionView: React.FC = () => {
    const { state: collectionsViewState } = useCollectionsView()
    const collectionViewState = useCollectionViewState()
    const view = useCollectionView()

    const activeViewState = collectionViewState?.activeViewState ?? null
    const pinnedNotes = collectionViewState?.pinnedNotes || []
    const pinnedNoteUUID = pinnedNotes.length > 0 ? pinnedNotes[0] : null

    if (collectionViewState === null || activeViewState === null) {
        return null
    }

    const isVisible =
        collectionsViewState.selectedCollectionUUID ===
        collectionViewState.collectionUUID

    return (
        <FilteredNotesProvider
            noteType={view?.filter.noteType}
            sort={view?.sort || `recentUpdated`}
            workflowStates={view?.filter.workflowStates || null}
            limit={250}
            offset={0}
        >
            <ViewLayout visible={isVisible}>
                <Header />

                <Body>
                    <NoteListContainer
                        show={collectionsViewState.noteListVisible}
                    >
                        <NoteListSidebar />
                    </NoteListContainer>

                    <NotePane>
                        <FilteredNotesProvider sort="recentUpdated">
                            {activeViewState.openNotes.map((noteUUID) => (
                                <NoteProvider key={noteUUID} uuid={noteUUID}>
                                    <Note />
                                </NoteProvider>
                            ))}
                        </FilteredNotesProvider>
                    </NotePane>

                    <PinnedNote visible={pinnedNoteUUID !== null}>
                        {pinnedNoteUUID !== null && (
                            <FilteredNotesProvider sort="recentUpdated">
                                <NoteProvider
                                    key={pinnedNoteUUID}
                                    uuid={pinnedNoteUUID}
                                >
                                    <Note enablePreview={false} />
                                </NoteProvider>
                            </FilteredNotesProvider>
                        )}
                    </PinnedNote>
                </Body>
            </ViewLayout>
        </FilteredNotesProvider>
    )
}

export default CollectionView
