import { UUID } from "../UUID"
import { useCollection } from "./useCollection"
import presets from "../../CollectionPresets"

export function useCollectionConfig(uuid: UUID | null) {
    const collection = useCollection(uuid)

    if (collection === null) {
        return null
    }

    if (collection.configPreset !== null) {
        const preset =
            presets.find((preset) => preset.name === collection.configPreset) ||
            null
        if (preset !== null) {
            return preset.config
        } else {
            return null
        }
    } else {
        return collection.userConfig
    }
}
