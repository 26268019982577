import React from "react"
import styled, { css } from "styled-components"
import { useCollectionViewState } from "../../../Providers/CollectionViewProvider"
import { FilteredNotesProvider } from "../../../Providers/FilteredNotesProvider"
import { NoteProvider } from "../../../Providers/NoteProvider"
import { useCollectionView } from "../../../Data/Collection"
import { UUID } from "../../../Data/UUID"

import { FocusModeSession } from "../../../Managers/NotesManager/FocusModeSessionView"

import NoteListSidebar from "../../NoteListSidebar"
import OrganizeModeHeader from "../../FocusMode/OrganizeModeHeader"

import Note from "../../Note"

const NoteListContainer = styled.div<{ show: boolean }>`
    ${({ show }) => css`
        width: ${show ? `250px` : `0px`};
        overflow: hidden;

        transition: width 0.2s ease;
        height: 100%;
    `}
`

const NotePane = styled.div`
    padding: 50px;
    height: 100%;
    // display: grid;
    // grid-template-columns: auto;
    overflow: hidden;
`

const ViewLayout = styled.div<{ visible: boolean }>`
    ${({ visible }) => css`
        display: ${visible ? `grid` : `none`};
        grid-template-rows: 50px auto;
        height: 100%;
    `}
`

const Body = styled.div`
    display: grid;
    grid-template-columns: max-content auto max-content;
    grid-template-rows: auto;
    height: 100%;
    overflow: hidden;
`

const PinnedNote = styled.div<{ visible: boolean }>`
    height: 100%;
    overflow: hidden;

    ${({ visible = false }) => css`
        width: ${visible ? `300px` : `0px`};
    `}
`

interface OrganizeNoteFocusViewProps {
    focusSession: FocusModeSession
    activeNoteUUID: UUID | null
    onStop: () => void
    onNoteChange: (uuid: UUID) => void
}

const OrganizeNoteFocusView: React.FC<OrganizeNoteFocusViewProps> = ({
    focusSession,
    activeNoteUUID,
    onStop,
    onNoteChange,
}) => {
    const collectionViewState = useCollectionViewState()
    const view = useCollectionView()

    const activeViewState = collectionViewState?.activeViewState ?? null
    const pinnedNotes = collectionViewState?.pinnedNotes || []
    const pinnedNoteUUID = pinnedNotes.length > 0 ? pinnedNotes[0] : null

    if (
        collectionViewState === null ||
        activeViewState === null ||
        activeNoteUUID === null
    ) {
        return null
    }

    function handleItemChange(item: number) {
        if (item > 0 && item <= focusSession.list.length) {
            onNoteChange(focusSession.list[item - 1].uuid)
        }
    }

    const noteNumber =
        (focusSession.list.findIndex((item) => item.uuid === activeNoteUUID) ||
            0) + 1

    return (
        <FilteredNotesProvider
            noteType={view?.filter.noteType}
            sort={view?.sort || `recentUpdated`}
            workflowStates={view?.filter.workflowStates || null}
            limit={250}
            offset={0}
        >
            <ViewLayout visible={true}>
                <OrganizeModeHeader
                    item={noteNumber}
                    items={focusSession.count}
                    onStop={onStop}
                    onItemChange={handleItemChange}
                />

                <Body>
                    <NoteListContainer show={true}>
                        <NoteListSidebar />
                    </NoteListContainer>

                    <NotePane>
                        <FilteredNotesProvider sort="recentUpdated">
                            {activeViewState.openNotes.map((noteUUID) => (
                                <NoteProvider key={noteUUID} uuid={noteUUID}>
                                    <Note />
                                </NoteProvider>
                            ))}
                        </FilteredNotesProvider>
                    </NotePane>

                    <PinnedNote visible={true}>
                        <FilteredNotesProvider sort="recentUpdated">
                            <NoteProvider
                                key={activeNoteUUID}
                                uuid={activeNoteUUID}
                            >
                                <Note enablePreview={false} />
                            </NoteProvider>
                        </FilteredNotesProvider>
                    </PinnedNote>
                </Body>
            </ViewLayout>
        </FilteredNotesProvider>
    )
}

export default OrganizeNoteFocusView
