import React from "react"
import styled from "styled-components"

const Heading = styled.h1`
    margin-top: 0;
`

interface HeadingDecoratorProps {
    as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

const HeadingDecorator: React.FC<HeadingDecoratorProps> = ({
    as,
    children,
}) => {
    return <Heading as={as}>{children}</Heading>
}

export default HeadingDecorator
