// @deprecated
import { ContentBlock } from "draft-js"
import escapeRegExp from "lodash/escapeRegExp"
import { findWithRegex } from "../Utils"
import { FindWithRegexCb } from "../Utils/types"

interface RelatedItemRegexStrategyProps {
    prefix: string
    suffix: string
    regex: string
    decorateControlCharacters: boolean
}

export default ({
    prefix,
    suffix,
    regex,
    decorateControlCharacters,
}: RelatedItemRegexStrategyProps): ((
    contentBlock: ContentBlock,
    callback: FindWithRegexCb
) => void) => {
    const RELATE_REGEX = new RegExp(
        `${escapeRegExp(prefix)}${regex}${escapeRegExp(suffix)}`,
        `g`
    )

    return (contentBlock: ContentBlock, callback: FindWithRegexCb) => {
        findWithRegex(
            RELATE_REGEX,
            contentBlock,
            callback,
            decorateControlCharacters === true ? prefix.length : 0,
            decorateControlCharacters === true ? suffix.length : 0
        )
    }
}
