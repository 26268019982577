import React, { useState, useMemo, useEffect, useRef } from "react"
import styled, { css } from "styled-components"

import Control from "./Control"
import Menu, { Item } from "../Menu"

import { useElementBounds } from "../../../Hooks"

import { SelectOption } from "./types"

interface Props {
    className?: string
    label?: string | null
    options: SelectOption[]
    renderItem?: (option: SelectOption) => JSX.Element
    selectedOption: SelectOption
    minListWidth?: number
    testId?: string
    onChange: (option: SelectOption) => void
}

const Select: React.FC<Props> = ({
    className,
    label = null,
    options = [],
    selectedOption: initialSelectedOption,
    onChange,
    renderItem,
    minListWidth = null,
    testId = `select`,
}) => {
    const controlRef = useRef(null)
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption)
    const [isOpen, setIsOpen] = useState(false)
    const controlBounds = useElementBounds(controlRef, isOpen)

    useEffect(() => {
        setSelectedOption(initialSelectedOption)
    }, [initialSelectedOption])

    function handleOpen() {
        setIsOpen(true)
    }

    function handleClose() {
        setIsOpen(false)
    }

    function handleSelect(selectedOption: SelectOption) {
        setSelectedOption(selectedOption)
        setIsOpen(false)
        onChange(selectedOption)
    }

    const items = options.map((option) => (
        <Item
            key={option.value}
            selected={selectedOption === option}
            onClick={() => handleSelect(option)}
        >
            {(renderItem && renderItem(option)) || option.label}
        </Item>
    ))

    return (
        <div className={className} data-testid={testId}>
            <Control label={label} ref={controlRef} onClick={handleOpen}>
                {selectedOption &&
                    ((renderItem && renderItem(selectedOption)) ||
                        selectedOption.label)}
            </Control>
            {controlBounds && (
                <Menu
                    x={controlBounds.x}
                    y={controlBounds.y + controlBounds.height + 3}
                    minWidth={
                        minListWidth === null
                            ? controlBounds.width
                            : minListWidth
                    }
                    visible={true}
                    onClose={handleClose}
                    zIndex={6000}
                >
                    {items}
                </Menu>
            )}
        </div>
    )
}

export default styled(Select)<Props>`
    ${(props) => css``}
`
