import React, { useRef, useEffect } from "react"

type KeyboardEventListener = (e: KeyboardEvent) => void
type MouseEventListener = (e: MouseEvent) => void
type DragEventListener = (e: DragEvent) => void

type CombinedEventListener =
    | KeyboardEventListener
    | MouseEventListener
    | DragEventListener
    | EventListener

// type EventType = MouseEvent | KeyboardEvent
// type EventCallback = (event: EventType) => void
// This event listener allows an event to be attached which updates the handler function
// that gets called. This means that handler function will have the updated props in the render thread
function useEventListener(
    eventName: string,
    handler: CombinedEventListener,
    ref: React.RefObject<HTMLElement>
) {
    // Create a ref that stores our passed in handler
    const savedHandler = useRef<CombinedEventListener>()

    // Update ref.current if the handler changes
    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        if (ref.current === null) return

        const eventListener: EventListener = (event: any) => {
            savedHandler.current && savedHandler.current(event)
        }

        // const eventListener: EventCallback = (event: EventType) =>
        //     (savedHandler.current && savedHandler.current(event)) || null

        ref.current.addEventListener(eventName, eventListener)

        // Remove on cleanup
        return () => {
            if (ref.current !== null) {
                ref.current.removeEventListener(eventName, eventListener)
            }
        }
    }, [ref.current, eventName])
}

export default useEventListener
