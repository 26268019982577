import React from "react"
import styled, { css } from "styled-components"
import Portal from "../../Portal"

interface UnderlayProps {
    className?: string
    zIndex?: number
    onClick?: () => void
    onMouseOver?: () => void
}

const Underlay: React.FC<UnderlayProps> = ({
    className,
    onClick,
    onMouseOver,
}) => {
    return (
        <Portal>
            <div
                className={className}
                data-testid="underlay"
                onClick={onClick}
                onMouseOver={onMouseOver}
            />
        </Portal>
    )
}

export default styled(Underlay)<UnderlayProps>`
    ${({ zIndex = 5000 }) => css`
        position: fixed;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${zIndex};
    `}
`
