import { useMemo } from "react"
import { useCollectionConfig } from "./useCollectionConfig"

export function useCollectionWorkflowState(
    collectionUUID: string | null,
    noteType: string | null,
    workflowState: string | null
) {
    const config = useCollectionConfig(collectionUUID)

    const workflowStateConfig = useMemo(() => {
        const noteTypeConfig =
            config?.noteTypes.find((nt) => nt.name === noteType) || null
        if (noteTypeConfig === null) {
            return null
        }

        const workflowName = noteTypeConfig.workflow
        if (workflowName === null) {
            return null
        }

        // Now find the workflow
        const workflow =
            config?.workflows.find((w) => w.name === workflowName) || null
        if (workflow === null) {
            return null
        }

        // Now match the state
        const state =
            workflow.states.find((state) => state.name === workflowState) ||
            null

        if (state === null && workflow.states.length > 0) {
            return workflow.states[0]
        } else {
            return state
        }
    }, [noteType, workflowState, config?.noteTypes, config?.workflows])

    return workflowStateConfig
}
