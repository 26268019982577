import React from "react"
import styled, { css } from "styled-components"

const _Thumb = styled.div`
    ${({ theme }) => css`
        height: 10px;
        width: 10px;
        background-color: ${theme.slider.thumbColor};
        border-radius: 50%;
        cursor: grab;
        position: relative;
        z-index: 1;
        top: -2px;
    `}
`

const Thumb = (props: React.HTMLProps<HTMLDivElement>) => {
    // @ts-ignore Unsure how to get types compatible with styled div
    return <_Thumb {...props} />
}

export default Thumb
