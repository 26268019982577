import React from "react"
import { Menu, MenuItem, Icon } from "ui"
import { useWebLink } from "./WebLinkProvider"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import { useNote } from "../../Providers/NoteProvider"

const WebLinkContextMenu: React.FC = () => {
    const notesManager = useNotesManager()
    const { state: noteState } = useNote()
    const { state, dispatch } = useWebLink()

    function handleClose() {
        dispatch({ type: `hideWebLinkMenu` })
    }

    function handleNewTab() {
        dispatch({ type: `hideWebLinkMenu` })
        Object.assign(document.createElement(`a`), {
            target: `_blank`,
            rel: `noreferrer noopener`,
            href: state.url,
        }).click()
    }

    function handlePreview() {
        dispatch({ type: `hideWebLinkMenu` })
        if (notesManager !== null && noteState.note !== null) {
            notesManager.setNotePreview({
                uuid: noteState.note.uuid,
                url: state.url,
            })
        }
    }

    return (
        <Menu
            visible={state.show}
            x={state.left}
            y={state.top}
            onClose={handleClose}
        >
            <MenuItem
                icon={<Icon name="external-link" />}
                onClick={handleNewTab}
            >
                Open url in new tab
            </MenuItem>
            <MenuItem icon={<Icon name="article" />} onClick={handlePreview}>
                Open preview
            </MenuItem>
        </Menu>
    )
}

export default WebLinkContextMenu
