import React, { useRef, useState } from "react"
import styled from "styled-components"
import Menu from "../Menu"
import { useElementBounds } from "../../../Hooks"

const Control = styled.div`
    display: inline-block;
`

interface ButtonMenuProps {
    button: React.ReactElement
    maxWidth?: number
    hover?: boolean
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({
    button,
    maxWidth,
    hover = false,
    children,
}) => {
    const controlRef = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const controlBounds = useElementBounds(controlRef, menuOpen)

    function handleMenuButtonClick() {
        setMenuOpen(true)
    }

    function handleMenuClose() {
        setMenuOpen(false)
    }

    function handleChildClick(passthroughClick?: () => void) {
        setMenuOpen(false)
        passthroughClick && passthroughClick()
    }

    function handleChildClose() {
        setMenuOpen(false)
    }

    const menuItems = React.Children.map(children, (child: any) => {
        return React.cloneElement(child as any, {
            onClick: () => handleChildClick(child.props.onClick),
            onClose: () => handleChildClose(),
        })
    })

    const MenuButton = React.cloneElement(button, {
        ref: controlRef,
        onClick: handleMenuButtonClick,
    })

    return (
        <Control>
            {MenuButton}
            {controlBounds && (
                <Menu
                    visible={menuOpen}
                    onClose={handleMenuClose}
                    x={controlBounds.x}
                    y={controlBounds.y + controlBounds.height}
                    maxWidth={maxWidth}
                    maxHeight={350}
                >
                    {menuItems}
                </Menu>
            )}
        </Control>
    )
}

export default ButtonMenu
