import React from "react"
import styled from "styled-components"
import { FormField, TextInput, Icon, Button, Text } from "ui"
import { useAccountForm } from "../../Hooks"
import Link from "../../../Link"
import routes from "../../../../routes"
import { useOnEnter } from "../../../../Hooks"

const CreateButton = styled(Button)`
    width: 100%;
`

const LegalCopy = styled(Text)`
    margin-top: 40px;
`

const Email: React.FC = () => {
    const [{ processing, email }, dispatch] = useAccountForm()

    useOnEnter(() => {
        _handleCreate()
    })

    let icon = null

    function _handleEmailChange(value: string) {
        dispatch({
            type: `set`,
            field: `email`,
            value: value,
        })
    }

    function _handleCreate() {
        dispatch({ type: `createAccount` })
    }

    function _handleEmailBlur() {
        dispatch({ type: `setFieldBlur`, field: `email` })
    }

    if (email.status === `processing`) {
        icon = (
            <Icon
                name="spinner-quarter"
                size="18px"
                subtle
                spin
                spinSpeed={1}
            />
        )
    } else if (email.status === `success`) {
        icon = <Icon name="check" size="18px" success />
    } else if (email.error !== null) {
        icon = <Icon name="error" size="18px" danger />
    }

    let createButton
    if (processing) {
        createButton = (
            <CreateButton
                primary
                cta
                disabled
                icon={
                    <Icon
                        name="spinner-circle"
                        size="18px"
                        subtle
                        spin
                        spinSpeed={1}
                    />
                }
            >
                Creating your account
            </CreateButton>
        )
    } else {
        createButton = (
            <CreateButton primary cta disabled={false} onClick={_handleCreate}>
                Continue
            </CreateButton>
        )
    }

    return (
        <>
            <h1>Create your account</h1>
            <Text>
                Distraction free workflow that puts your voice at the heart of
                your content.
            </Text>

            <FormField label="Email address" error={email.error}>
                <TextInput
                    name="email"
                    value={email.value}
                    onChange={_handleEmailChange}
                    icon={icon}
                    disabled={processing}
                    focus={email.focus}
                    onBlur={_handleEmailBlur}
                />
            </FormField>
            {createButton}

            <LegalCopy secondary>
                By clicking the button, you agree to our{` `}
                <a href={routes.terms} target="_blank" rel="noreferrer">
                    Terms of Service
                </a>
                {` `}
                and have read and acknowledge our{` `}
                <a href={routes.privacy} target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                .
            </LegalCopy>

            <Text secondary>
                Already have an account?{` `}
                <Link to={routes.signin}>Sign in here</Link>
            </Text>
        </>
    )
}

export default Email
