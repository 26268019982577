import React, { useMemo } from "react"

import Underlay from "../Underlay"
import MenuList from "./MenuList"

import { useOnEscape } from "../../../Hooks"

interface Props {
    visible?: boolean
    onClose?: () => void
    minWidth?: number
    maxWidth?: number
    maxHeight?: number
    x: number
    y: number
    zIndex?: number
}

const Menu: React.FC<Props> = ({
    children,
    x,
    y,
    minWidth,
    maxWidth,
    maxHeight,
    visible = false,
    zIndex = 5000,
    onClose,
}) => {
    function _handleClose() {
        onClose && onClose()
    }

    useOnEscape(_handleClose)

    return (
        <div data-testid="menu">
            {visible && <Underlay zIndex={zIndex} onClick={_handleClose} />}
            {visible && (
                <MenuList
                    x={x}
                    y={y}
                    minWidth={minWidth}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    zIndex={zIndex + 1}
                >
                    {children}
                </MenuList>
            )}
        </div>
    )
}

export default Menu
