import React, { useMemo } from "react"
import { createPortal } from "react-dom"
import usePortal from "./usePortal"
import { v4 as uuid } from "uuid"

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */
const Portal = ({
    id,
    children,
}: {
    id?: string
    children: React.ReactNode
}) => {
    const portalID = useMemo(() => {
        if (id === undefined) {
            return `portal-` + uuid()
        } else {
            return id
        }
    }, [id])
    const target = usePortal(portalID)
    return createPortal(children, target)
}

export default Portal
