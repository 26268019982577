import { useContext, useEffect, useState } from "react"
import { UUID } from "../../Data/UUID"
import { Note } from "../../Data/Note"
import { FilteredNotesStateContext } from "./FilteredNotesProvider"

export function useFilteredNote(uuid: UUID) {
    // TODO, see if we can default it from the cache
    const state = useContext(FilteredNotesStateContext)
    const [note, setNote] = useState<Note | null>(null)

    useEffect(() => {
        if (state.view === null) {
            return
        }

        state.view.get(uuid).then(setNote)
        return state.view.watchItem(uuid, setNote)
    }, [state.view, uuid])

    return note
}
