import React, { useState, useReducer, useEffect, useRef } from "react"
import {
    EditorState,
    DraftEditorCommand,
    DraftHandleValue,
    SelectionState,
} from "draft-js"
import escapeRegExp from "lodash/escapeRegExp"

import { SuggestionStore } from "../Store"
import { decodeOffsetKey, getSearchText } from "../Utils"

import { addMention } from "../Modifiers"

import {
    RegisterKeyBindingFn,
    OnEditorStateChange,
    LookupSuggestionsProps,
} from "../types"

// TODO: Next step is to pass the key binding events down to the child component

interface LookupSuggestionState {
    visible: boolean
    selectedIndex: number
}

const initialState: LookupSuggestionState = {
    visible: false,
    selectedIndex: -1,
}

type LookupSuggestionAction = { type: "SHOW" } | { type: "HIDE" }

function reducer(state: LookupSuggestionState, action: LookupSuggestionAction) {
    if (action.type === `SHOW`) {
        return { ...state, visible: true }
    } else if (action.type === `HIDE`) {
        return { ...state, visible: false }
    }
    throw new Error(`Unknown action`)
}
const LookupSuggestions: React.FC<LookupSuggestionsProps> = ({
    store,
    suggestionTrigger,
    // mentionPrefix,
    // entityMutability,
    registerEditorStateChangeCallback,
    registerKeyBindingCallback,
    renderComponent: RenderComponent,
}) => {
    const [{ visible }, dispatch] = useReducer(reducer, initialState)
    const activeOffsetKeyRef = useRef<string | null>(null)
    const lastSearchValueRef = useRef<string | null>(null)
    const [position, setPosition] = useState<{ left: number; top: number }>({
        left: 0,
        top: 0,
    })

    // Bind to the editor state change
    useEffect(() => {
        const deregister = registerEditorStateChangeCallback(
            handleEditorStateChange
        )

        return () => {
            deregister()
        }
    }, [suggestionTrigger, activeOffsetKeyRef])

    function handleEditorStateChange(editorState: EditorState) {
        // Get all the searches
        const searches = store.getAllSearches()

        // // If there's no searches, there's no @mentions
        // if (searches.size === 0) {
        //     console.log(`<< no searches`)
        //     return editorState
        // }

        // Get the selection
        const selection = editorState.getSelection()
        const anchorKey = selection.getAnchorKey()
        const anchorOffset = selection.getAnchorOffset()

        // console.log({ anchorKey, anchorOffset })

        // // The list should not be visible if a range is selected or the editor has no focus
        if (!selection.isCollapsed() || !selection.getHasFocus()) {
            dispatch({ type: `HIDE` })
            return editorState
        }

        // Identify the start & end positon of each search-text
        const offsetDetails = searches.map((offsetKey) =>
            decodeOffsetKey(offsetKey || ``)
        )

        // a leave can be empty when it is removed due event.g. using backspace
        // do not check leaves, use full decorated portal text
        const leaves = offsetDetails
            .filter((offsetDetail) => offsetDetail!.blockKey === anchorKey)
            .map((offsetDetail) =>
                editorState
                    .getBlockTree(offsetDetail!.blockKey)
                    .getIn([offsetDetail!.decoratorKey])
            )

        // // if all leaves are undefined the popover should be removed
        if (leaves.every((leave) => leave === undefined)) {
            dispatch({ type: `HIDE` })
            return editorState
        }

        // Checks that the cursor is after the @ character but still somewhere in
        // the word (search term). Setting it to allow the cursor to be left of
        // the @ causes troubles due selection confusion.
        const plainText = editorState.getCurrentContent().getPlainText()
        const selectionIsInsideWord = leaves
            .filter((leave) => leave !== undefined)
            .map(
                ({ start, end }) =>
                    (start === 0 &&
                        anchorOffset === suggestionTrigger.length &&
                        plainText.charAt(anchorOffset) !== suggestionTrigger &&
                        new RegExp(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            String.raw({
                                // @ts-ignore raw is readonly
                                raw: `${escapeRegExp(suggestionTrigger)}`,
                            }),
                            `g`
                        ).test(plainText) &&
                        anchorOffset <= end) || // @ is the first character
                    (anchorOffset >= start + suggestionTrigger.length &&
                        anchorOffset <= end) // @ is in the text or at the end
            )

        if (selectionIsInsideWord.every((isInside) => isInside === false)) {
            dispatch({ type: `HIDE` })
            return editorState
        }

        const lastActiveOffsetKey = activeOffsetKeyRef.current

        activeOffsetKeyRef.current = selectionIsInsideWord
            .filter((value) => value === true)
            .keySeq()
            .first()

        // console.log(`Offset details`, activeOffsetKeyRef.current)

        handleSearchChange(
            editorState,
            selection,
            activeOffsetKeyRef.current,
            lastActiveOffsetKey
        )

        // Show the dropdown

        if (activeOffsetKeyRef.current) {
            const rect = store.getPortalClientRect(activeOffsetKeyRef.current)
            if (rect) {
                setPosition({
                    left: rect.left,
                    top: rect.top + rect.height,
                })
                // console.log(`rect`, rect)
                dispatch({ type: `SHOW` })
            }
        }

        // console.log(`All searches`, searches)
        // We'll stop here for now - need to break down the onEditorStateChange method in MentionSuggestions

        // const activeOffsetKey = selectionIsInsideWord
        //     .filter((value) => value === true)
        //     .keySeq()
        //     .first()

        //const activeOffsetKey = `???`
        // Update the position
        //const decoratorRect = store.getPortalClientRect(activeOffsetKey)

        return editorState
    }

    function handleSearchChange(
        editorState: EditorState,
        selection: SelectionState,
        activeOffsetKey: string | undefined,
        lastActiveOffsetKey: string | null
    ): void {
        console.log(`Search changed`)
        const { matchingString: searchValue } = getSearchText(
            editorState,
            selection,
            suggestionTrigger
        )

        if (
            lastSearchValueRef.current !== searchValue ||
            activeOffsetKey !== lastActiveOffsetKey
        ) {
            lastSearchValueRef.current = searchValue
            // this.props.onSearchChange({ value: searchValue });
        }
    }

    // function handleConfirm(mention: MentionData) {
    //     console.log(`confirming`, mention.name)

    //     dispatch({type: 'HIDE'})

    //     const newEditorState = addMention(
    //         store.getEditorState(),
    //         mention,
    //         mentionPrefix,
    //         suggestionTrigger,
    //         entityMutability
    //     )
    //     store.setEditorState(newEditorState)
    // }

    // function handleMentionFocus(index) {
    //     setSelectedIndex(index)
    // }

    function handleClose() {
        dispatch({ type: `HIDE` })
    }

    return (
        <RenderComponent
            visible={visible}
            left={position.left}
            top={position.top}
            searchValue={lastSearchValueRef.current}
            registerKeyBindingCallback={registerKeyBindingCallback}
            store={store}
            onClose={handleClose}
        ></RenderComponent>
        // <MentionBox visible={visible} left={position.left} top={position.top}>
        //     {users.map((mention, index) => (
        //         <Item
        //             key={mention.username}
        //             index={index}
        //             selected={selectedIndex === index}
        //             onConfirm={handleConfirm}
        //             onMentionFocus={handleMentionFocus}
        //             mention={mention}
        //             searchValue={lastSearchValueRef.current}
        //         />
        //     ))}
        // </MentionBox>
    )
}

export default LookupSuggestions
