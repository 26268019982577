import React, { useMemo, useEffect } from "react"
import { DatabaseContext } from "./Context"
import FlowtelicDatabase from "./FlowtelicDatabase"

interface ProviderProps {
    name?: string
}

const Provider: React.FC<ProviderProps> = ({
    children,
    name = `flowtelic`,
}) => {
    const db = useMemo(() => {
        const db = new FlowtelicDatabase(name)
        return db
    }, [name])

    // Shutdown the db when we unmount
    useEffect(() => {
        return () => {
            db.shutdown()
        }
    }, [])

    return (
        <DatabaseContext.Provider value={db}>
            {children}
        </DatabaseContext.Provider>
    )
}

export default Provider
