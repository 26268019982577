import { FlowtelicDatabase, SubscriptionEvent } from "../../Data/Database"
import {
    Collection,
    CollectionViewSort,
    FocusSessionConfig,
} from "../../Data/Collection"
import { UUID } from "../../Data/UUID"
import { Note, NoteBody, NoteType } from "../../Data/Note"
import { WebPreviewFormat } from "../../Data/WebPreview"
import CollectionView from "./CollectionView"
import NotesView, { NotesFilter } from "./NotesView"
import NoteView from "./NoteView"
import FocusModeSessionView from "./FocusModeSessionView"
import FocusModeView from "./FocusModeView"
import { convertToRaw, ContentState, genKey } from "draft-js"
import { extractMetadataFromNoteBody } from "../../Data/Database/Lib/Utils/"
import { v4 as uuidv4 } from "uuid"

type ObserverUnsubscribe = () => void
class NotesManager {
    private db: FlowtelicDatabase

    // private eventListeners: Map<string, EventListener<any>[]>
    private observerUnusbscribes: Array<ObserverUnsubscribe> = []

    public collections: CollectionView
    private notesViews: NotesView[] = []
    private noteViews: NoteView[] = []

    public focusMode: FocusModeView
    private focusModeSessionViews: FocusModeSessionView[] = []

    public constructor(db: FlowtelicDatabase) {
        this.db = db

        // Only pay attention to changes from another instance of the database (e.g. another tab)
        // This is because the notes manager will sync local changes automatically and not
        // need to use the database observable to do it

        this.observerUnusbscribes.push(
            db.subscribe(`collections`, (event: SubscriptionEvent) => {
                if (event.isSelf === false) {
                    this.handleCollectionChange(event)
                }
            })
        )

        this.observerUnusbscribes.push(
            db.subscribe(`notes`, (event: SubscriptionEvent) => {
                if (event.isSelf === false) {
                    this.handleNoteChange(event)
                }
            })
        )

        this.observerUnusbscribes.push(
            db.subscribe(`noteBody`, (event: SubscriptionEvent) => {
                if (event.isSelf === false) {
                    this.handleNoteBodyChange(event)
                }
            })
        )

        this.collections = new CollectionView(db)
        this.focusMode = new FocusModeView(this)
    }

    handleCollectionChange(event: SubscriptionEvent) {
        if (event.type === `CREATED`) {
            this.collections.insert(event.obj, false)
        } else if (event.type === `UPDATED`) {
            this.collections.update(event.obj, false)
        } else if (event.type === `DELETED`) {
            this.collections.remove(event.key, false)
        }
    }

    handleNoteChange(event: SubscriptionEvent) {
        if (event.type === `CREATED`) {
            this.notesViews.forEach((view) => view.insert(event.obj, false))
            this.focusModeSessionViews.forEach((view) =>
                view.insert(event.obj, false)
            )
        } else if (event.type === `UPDATED`) {
            this.notesViews.forEach(
                (view) => view.put(event.obj, false) || null
            )
            this.focusModeSessionViews.forEach(
                (view) => view.put(event.obj, false) || null
            )
            this.noteViews.forEach(
                (view) =>
                    view.uuid === event.key &&
                    view.id !== event.view &&
                    view.update({ note: event.obj }, false)
            )
        } else if (event.type === `DELETED`) {
            this.notesViews.forEach((view) => view.remove(event.key, false))
            this.focusModeSessionViews.forEach((view) =>
                view.remove(event.key, false)
            )
            this.noteViews.forEach(
                (view) =>
                    view.uuid === event.key && view.remove(event.key, false)
            )
        }
    }

    handleNoteBodyChange(event: SubscriptionEvent) {
        if (event.type === `UPDATED`) {
            this.noteViews.forEach(
                (view) =>
                    view.uuid === event.key &&
                    view.update({ noteBody: event.obj }, false)
            )
        } else if (event.type === `DELETED`) {
            this.noteViews.forEach(
                (view) =>
                    view.uuid === event.key && view.remove(event.key, false)
            )
        }
    }

    public shutdown() {
        this.observerUnusbscribes.forEach((fn) => fn())
    }

    public async addCollection({
        view = null,
        collection,
    }: {
        view?: UUID | null
        collection: Collection
    }) {
        await this.collections.insert(collection, true)
        await this.db.putCollection({ view, collection })
    }

    public async updateCollection({
        view = null,
        collection,
    }: {
        view?: UUID | null
        collection: Collection
    }) {
        await this.collections.update(collection, true)
        await this.db.putCollection({ view, collection })
    }

    public async deleteCollection({
        uuid,
        view = null,
    }: {
        uuid: UUID
        view?: UUID | null
    }) {
        await this.collections.remove(uuid, true)
        await this.db.deleteCollection({ uuid, view })
    }

    public async putNote({
        view = null,
        note,
        noteBody,
    }: {
        view?: UUID | null
        note: Note
        noteBody?: NoteBody
    }) {
        // Do the DB work first, as the notes view requeries the database
        await this.db.putNote({ view, note, noteBody })

        // Update the collection/note body view
        await Promise.all(this.notesViews.map((view) => view.put(note, true)))
        this.noteViews.forEach(
            (view) =>
                view.uuid === note.uuid && view.update({ note, noteBody }, true)
        )
        this.focusModeSessionViews.forEach((view) => {
            view.put(note, true)
        })
    }

    public async setNotePreview({
        uuid,
        view = null,
        url,
        format,
    }: {
        uuid: UUID
        view?: UUID | null
        url?: string | null
        format?: WebPreviewFormat
    }) {
        const noteBody = await this.db.loadNoteBody({ uuid })
        if (noteBody !== null) {
            const newNoteBody: NoteBody = {
                ...noteBody,
                previewUrl: url === undefined ? noteBody.previewUrl : url,
                previewFormat:
                    format === undefined ? noteBody.previewFormat : format,
            }
            // Now update it again with the preview URL
            await this.db.putNote({
                view,
                noteBody: newNoteBody,
            })

            // Notify the note views
            this.noteViews.forEach(
                (view) =>
                    view.uuid === uuid &&
                    view.update({ noteBody: newNoteBody }, true)
            )
        }
    }

    public async deleteNote({
        uuid,
        view = null,
    }: {
        uuid: UUID
        view?: UUID | null
    }) {
        await this.db.deleteNote({ uuid, view })
        await Promise.all(
            this.notesViews.map((view) => view.remove(uuid, true))
        )
        this.noteViews.forEach(
            (view) => view.uuid === uuid && view.remove(uuid, true)
        )
        this.focusModeSessionViews.forEach((view) => {
            view.remove(uuid, true)
        })
    }

    public removeNotesView(view: NotesView) {
        this.notesViews = this.notesViews.filter((v) => v !== view)
    }

    public removeFocusModeSessionView(view: FocusModeSessionView) {
        this.focusModeSessionViews = this.focusModeSessionViews.filter(
            (v) => v !== view
        )
    }

    public createNotesView(
        filter: NotesFilter,
        sort: CollectionViewSort,
        limit: number,
        offset: number
    ) {
        const view = new NotesView(this.db, this, filter, sort, limit, offset)
        this.notesViews.push(view)
        return view
    }

    public createNoteView(uuid: UUID) {
        const view = new NoteView(this.db, this, uuid)
        this.noteViews.push(view)
        return view
    }

    public createFocusModeSessionView(
        collectionUUID: UUID,
        focusSessionConfig: FocusSessionConfig
    ) {
        const view = new FocusModeSessionView(
            this.db,
            this,
            collectionUUID,
            focusSessionConfig
        )
        this.focusModeSessionViews.push(view)
        return view
    }

    public removeNoteView(view: NoteView) {
        this.noteViews = this.noteViews.filter((v) => v !== view)
    }

    public async findNotesByTitle(collectionUUID: UUID, title: string) {
        const notes = await this.db.findNotesByTitle({
            collectionUUID,
            title,
        })
        return notes
    }

    public async createNote({
        collectionUUID,
        view = null,
        noteType,
        title,
        workflowState = null,
    }: {
        collectionUUID: UUID
        view?: UUID | null
        noteType: NoteType
        title?: string
        workflowState: string | null
    }) {
        const newNote: Note = {
            uuid: uuidv4(),
            type: noteType,
            title: title || ``,
            preview: ``,
            updated: new Date(),
            collectionUUID: collectionUUID,
            workflowState: workflowState,
        }

        const newNoteBody: NoteBody = {
            uuid: newNote.uuid,
            body: {
                blocks: [
                    {
                        key: genKey(),
                        text: title || ``,
                        type: `header-one`,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {},
                    },
                    {
                        key: genKey(),
                        text: ``,
                        type: `unstyled`,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {},
                    },
                ],
                entityMap: {},
            },
        }

        await this.db.putNote({ view, note: newNote, noteBody: newNoteBody })

        // Update the notes views
        this.notesViews.forEach((view) => view.put(newNote, true))
        this.focusModeSessionViews.forEach((view) => {
            view.insert(newNote, true)
        })

        return { note: newNote, noteBody: newNoteBody }
    }

    public async updateNoteContentState({
        uuid,
        view = null,
        contentState,
    }: {
        uuid: UUID
        view?: UUID | null
        contentState: ContentState
    }) {
        const loadedNote = await this.db.loadNote({
            uuid,
        })

        if (
            loadedNote === null ||
            loadedNote.note === undefined ||
            loadedNote.noteBody === undefined
        ) {
            return
        }

        // Convert the state to a raw state
        const body = convertToRaw(contentState)

        // Update the content for the note and the note body
        const meta = extractMetadataFromNoteBody(body)

        loadedNote.note.title = meta.title
        loadedNote.note.preview = meta.preview
        loadedNote.note.updated = new Date()
        loadedNote.noteBody.body = body

        await this.db.putNote({
            view,
            note: loadedNote.note,
            noteBody: loadedNote.noteBody,
        })

        // Update the note views, do it inline so we don't have to check for undefined
        for (let i = 0; i < this.noteViews.length; i++) {
            this.noteViews[i].update(
                {
                    note: loadedNote.note,
                    noteBody: loadedNote.noteBody,
                },
                view === this.noteViews[i].id
            )
        }

        // Update the collection/note body view
        for (let i = 0; i < this.notesViews.length; i += 1) {
            this.notesViews[i].put(loadedNote.note, true)
        }
    }
}

export { NotesManager }
