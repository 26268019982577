import { css } from "styled-components"

export default css`
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
        border: 0px none #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.5);
    }
    ::-webkit-scrollbar-thumb:active {
        background: rgba(255, 255, 255, 0.7);
    }
    ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.3);
        border: 0px none #ffffff;
        border-radius: 1px;
    }
    ::-webkit-scrollbar-track:hover {
        background: rgba(0, 0, 0, 0.3);
    }
    ::-webkit-scrollbar-track:active {
        background: rgba(0, 0, 0, 0.3);
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0.3);
`
