import { useEffect, useState } from "react"
import { WebPreviewFormat, WebPreview } from "./types"
import { useDatabase } from "../Database"
import config from "../../Config"

export function useWebPreview(url: string, format: WebPreviewFormat) {
    const db = useDatabase()
    const [content, setContent] = useState<string | null>(null)

    useEffect(() => {
        ;(async () => {
            if (db === null) {
                return
            }

            // Do we have the content already in the database
            const result = await db.webPreviews
                .where(`[url+format]`)
                .equals([url, format])
                .first()

            if (result === undefined) {
                // Generate the preview
                setContent(null) // Show the loader
                const response = await fetch(
                    config.PREVIEW_SERVER +
                        `/preview?url=` +
                        encodeURIComponent(url)
                )
                if (response.status === 200) {
                    const text = await response.text()
                    const content = JSON.parse(text) as WebPreview

                    // Save each one now
                    if (content.article.content) {
                        let articleContent: string
                        if (content.article.title) {
                            articleContent = `<h1>${content.article.title}</h1>${content.article.content}`
                        } else {
                            articleContent = content.article.content
                        }
                        await db.webPreviews.put({
                            url,
                            format: `Readability`,
                            content: articleContent,
                        })
                    }

                    if (content.small.svg) {
                        await db.webPreviews.put({
                            url,
                            format: `SmallSvg`,
                            content: content.small.svg,
                        })
                    }

                    if (content.large.svg) {
                        await db.webPreviews.put({
                            url,
                            format: `LargeSvg`,
                            content: content.large.svg,
                        })
                    }

                    // Now look it up again
                    const result = await db.webPreviews
                        .where(`[url+format]`)
                        .equals([url, format])
                        .first()

                    if (result !== undefined) {
                        setContent(result.content)
                    }
                }
            } else {
                setContent(result.content)
            }
        })()
    }, [url, format])

    return content
}
