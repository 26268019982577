import React, { useEffect } from "react"

export default function useOnFocusOut(
    ref: React.RefObject<HTMLElement>,
    onFocusOut: () => void,
    enabled = true
) {
    useEffect(() => {
        const el = ref.current

        if (el === null || !enabled) {
            return
        }

        let focused = true

        function _handleFocusOut() {
            focused = false // We defocus
            // But only process it if we stay defocused (i.e. we didn't change control in the element)
            setTimeout(() => {
                if (!focused) {
                    // Still not focused
                    onFocusOut && onFocusOut()
                }
            }, 0)
        }

        function _handleFocusIn() {
            // We're focused in again
            focused = true
        }

        el.addEventListener(`focusout`, _handleFocusOut)
        el.addEventListener(`focusin`, _handleFocusIn)
        el.addEventListener(`click`, _handleFocusIn) // Same as focusing a child control

        return () => {
            el.removeEventListener(`focusout`, _handleFocusOut)
            el.removeEventListener(`focusin`, _handleFocusIn)
            el.removeEventListener(`click`, _handleFocusIn)
        }
    }, [ref.current, enabled])
}
