import { useEffect, useRef } from "react"

function useDelayedEffect(
    callback: () => void,
    delay: number,
    dependencies: any[]
) {
    const timeout = useRef<NodeJS.Timeout | undefined>(undefined)

    useEffect(() => {
        // Set up the timeout
        timeout.current = setTimeout(() => {
            timeout.current = undefined
            callback()
        }, delay)

        // Return the cleanup if the dependnecies change
        return () => {
            if (timeout.current !== undefined) {
                clearTimeout(timeout.current)
            }
        }
    }, dependencies)
}

export default useDelayedEffect
