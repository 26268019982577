import React, { useEffect } from "react"

interface AttributeObject {
    [key: string]: string
}

type AttributeObjectFactory = (el: HTMLElement) => AttributeObject

export default function useElementAttributes(
    ref: React.RefObject<HTMLElement>,
    fn: AttributeObject | AttributeObjectFactory
) {
    useEffect(() => {
        if (ref.current === null) return

        const attributes =
            typeof fn === `function`
                ? (fn as AttributeObjectFactory)(ref.current)
                : (fn as AttributeObject)

        const keys = Object.keys(attributes)
        for (let i = 0; i < keys.length; i += 1) {
            ref.current.setAttribute(keys[i], attributes[keys[i]])
        }
    }, [ref.current, fn])
}
