function isYoutubeUrl(url: string) {
    if (url) {
        let regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        if (url.match(regExp)) {
            return true
        }
    }
    return false
}

function getYouTubeVideoIDFromURL(url: string) {
    if (!isYoutubeUrl(url)) {
        return null
    }

    const parts = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    return parts[2] !== undefined ? parts[2].split(/[^0-9a-z_\-]/i)[0] : null
}

export default getYouTubeVideoIDFromURL
