import React, { useRef } from "react"
import styled, { css } from "styled-components"
import Portal from "../../../Portal"

import scrollbars from "../../../../Styles/scrollbars"
import { useEnsureOnScreen, useNoDocumentScrollbars } from "../../../../Hooks"

interface Props {
    className?: string
    minWidth?: number
    maxWidth?: number
    maxHeight?: number
    x: number
    y: number
    zIndex?: number
}

const MenuList: React.FC<Props> = ({ className, children }) => {
    const ref = useRef(null)
    useNoDocumentScrollbars(true)
    useEnsureOnScreen(ref, 8)

    // FIXME: Need a hook that will position this where the bottom of the parent element is
    // e.g. the ref of a button or select control

    return (
        <Portal>
            <div ref={ref} className={className}>
                {children}
            </div>
        </Portal>
    )
}

export default styled(MenuList)<Props>`
    ${({
        theme,
        minWidth = null,
        maxWidth = null,
        maxHeight = null,
        x,
        y,
        zIndex = 5001,
    }) => {
        return css`
            ${theme.menu.panel};

            ${maxHeight &&
            css`
                max-height: ${maxHeight}px;
            `}

            overflow-y: auto;

            ${minWidth &&
            css`
                min-width: ${minWidth}px;
            `}

            ${maxWidth &&
            css`
                max-width: ${maxWidth}px;
            `}
            ${theme.menu.backgroundBlur &&
            css`
                @supports (
                    backdrop-filter: blur(${theme.menu.backgroundBlur})
                ) {
                    backdrop-filter: blur(${theme.menu.backgroundBlur});
                    background-color: ${theme.menu.blurBackgroundColor};
                }
            `}

            position: fixed;
            left: ${x}px;
            top: ${y}px;
            z-index: ${zIndex};

            display: block;
            opacity: 1;

            ${scrollbars}
        `
    }}
`
