import { gql, useMutation } from "@apollo/client"

const SIGNUP = gql`
    mutation signup($user: UserInput!) {
        signup(user: $user) {
            uuid
            email
            username
        }
    }
`

function useSignup() {
    const [signup, state] = useMutation(SIGNUP)
    return {
        signup,
        error: state.error || null,
        loading: state.loading,
        user: state.data?.signup || null,
    }
}

export default useSignup
