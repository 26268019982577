import React from "react"
import styled from "styled-components"

import CollectionsSidebar from "../../Collections/Sidebar"

// import { useNote } from "../../Data/Note"
import { useCollections } from "../../../Data/Collections"
import { Provider as CollectionsViewProvider } from "../../../Providers/CollectionsViewProvider"
import { NotesImportProvider } from "../../../Providers/NoteImportProvider"
import { CollectionViewProvider } from "../../../Providers/CollectionViewProvider"
import ImportModal from "../../Collections/ImportModal"
import CollectionView from "../CollectionView"

const Layout = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 50px auto;
`

const Sidebar = styled.div`
    display: block;
    height: 100%;
`

const CollectionsView: React.FC = () => {
    const collections = useCollections()

    return (
        <CollectionsViewProvider>
            <NotesImportProvider>
                <Layout>
                    <Sidebar>
                        <CollectionsSidebar />
                    </Sidebar>
                    {collections.map((collection) => (
                        <CollectionViewProvider
                            key={collection.uuid}
                            collectionUUID={collection.uuid}
                        >
                            <CollectionView />
                        </CollectionViewProvider>
                    ))}
                </Layout>
                <ImportModal />
            </NotesImportProvider>
        </CollectionsViewProvider>
    )
}

export default CollectionsView
