import { useContext, useEffect } from "react"
import { StateContext, DispatchContext } from "../Data/Context"
import { State, Dispatch } from "../Data/types"

import { isValidEmail, isValidUsername } from "../../../Validation"

import useIsEmailSignedUp from "./useIsEmailSignedUp"
import useIsUsernameTaken from "./useIsUsernameTaken"

function useEmailChecker(state: State, dispatch: Dispatch) {
    const { isSignedUp, loading, error } = useIsEmailSignedUp({
        email: state.email.value,
    })

    useEffect(() => {
        if (!isValidEmail(state.email.value)) {
            dispatch({ type: `setPending`, field: `email` })
            return
        }

        if (error) {
            console.error(`Unable to check if email address is taken`, error)
            return
        }

        if (loading) {
            // If we are starting the processing
            dispatch({ type: `setProcessing`, field: `email` })
        } else {
            // If we are finished processing and and to show the result
            dispatch({
                type: `resolveProcessing`,
                field: `email`,
                error: isSignedUp ? `Email already signed up` : null,
            })
        }
    }, [state.email.value, loading, error, isSignedUp])
}

function useUsernameChecker(state: State, dispatch: Dispatch) {
    const { isUsernameTaken, loading, error } = useIsUsernameTaken({
        username: state.username.value,
    })

    useEffect(() => {
        if (!isValidUsername(state.username.value)) {
            dispatch({ type: `setPending`, field: `username` })
            return
        }

        if (error) {
            console.error(`Unable to check if username is taken`, error)
            return
        }

        if (loading) {
            // If we are starting the processing
            dispatch({ type: `setProcessing`, field: `username` })
        } else {
            // If we are finished processing and and to show the result
            dispatch({
                type: `resolveProcessing`,
                field: `username`,
                error: isUsernameTaken ? `Username already taken` : null,
            })
        }
    }, [state.username.value, loading, error, isUsernameTaken])
}

// function useCreateAccount(state: State, dispatch: Dispatch) {
//     const action = state.query.action

//     useEffect(() => {
//         async function doAction() {
//             dispatch({ type: `setProcessing` })

//         }

//         if (action === `createAccount`) {
//             doAction()
//         }
//     }, [action])
// }

function useAccountForm(): [State, Dispatch] {
    const state = useContext(StateContext)
    const dispatch = useContext(DispatchContext)

    useEmailChecker(state, dispatch)
    useUsernameChecker(state, dispatch)

    return [state, dispatch]
}

export default useAccountForm
