import React from "react"
import styled, { css } from "styled-components"

import Button from "../../Button"
import Icon from "../../Icon"

const ButtonLayout = styled.div<{ hasIcon: boolean; showCheckmark: boolean }>`
    ${({ hasIcon, showCheckmark }) => css`
        display: grid;

        ${showCheckmark &&
        css`
            ${hasIcon &&
            css`
                grid-template-columns: 14px max-content auto;
            `}
            ${!hasIcon &&
            css`
                grid-template-columns: 14px auto;
            `}
        `}

        ${!showCheckmark &&
        css`
            ${hasIcon &&
            css`
                grid-template-columns: max-content auto;
            `}
            ${!hasIcon &&
            css`
                grid-template-columns: auto;
            `}
        `}
        align-items: center;
        grid-gap: 10px;
    `}
`

// Fixme - theme any

interface Props {
    className?: string
    selected?: boolean
    onClick?: () => void
    icon?: JSX.Element | null
    maxWidth?: number
    showSelectedCheckmark?: boolean
}

const MenuItem: React.FC<Props> = ({
    children,
    className,
    onClick,
    icon,
    selected = false,
    showSelectedCheckmark = false,
}) => {
    const checkmark = selected ? <Icon name="check" size="14px" /> : <span />

    return (
        <Button className={className} onClick={onClick}>
            <ButtonLayout
                hasIcon={icon !== null}
                showCheckmark={showSelectedCheckmark}
            >
                {showSelectedCheckmark && checkmark}
                {icon}
                {children}
            </ButtonLayout>
        </Button>
    )
}

export default styled(MenuItem)<Props>`
    ${({ theme, selected = null, maxWidth = null }) => css`
        text-align: left;
        width: 100%;
        display: block;

        ${theme.menu.item};

        ${maxWidth &&
        css`
            max-width: ${maxWidth}px;
            overflow: hidden;
        `}

        > ${ButtonLayout} {
            ${selected !== null &&
            css`
                display: grid;
                grid-template-columns: max-content auto;
                grid-gap: 5px;
                align-items: center;

                > ${Icon} {
                    visibility: ${selected ? `visible` : `hidden`};
                }
            `}

            ${selected === null &&
            css`
                > span {
                    padding-left: 5px;
                    display: flex;
                    align-items: center;
                }
            `}

            > span {
                ${Icon} {
                    display: inline-block;
                    margin-right: 4px;
                }
            }
        }
    `}
`
