import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { UUID } from "../../../Data/UUID"
import { useCollection } from "../../../Data/Collection"
import { FormField, TextInput, Button } from "ui"
import { useOnEscape } from "../../../Hooks"
import { useNotesManager } from "../../../Providers/NotesManagerProvider"

const Layout = styled.div`
    padding: 20px;
`

const DialogButtons = styled.div`
    margin-top: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    ${Button} {
        width: 100%;
    }
`

interface EditCollectionDialogProps {
    uuid: UUID
    onClose?: () => void
}

export const EditCollectionDialog: React.FC<EditCollectionDialogProps> = ({
    uuid,
    onClose,
}) => {
    const collection = useCollection(uuid)
    const [name, setName] = useState(collection?.name || ``)
    const notesManager = useNotesManager()

    useEffect(() => {
        setName(collection?.name || ``)
    }, [collection])

    useOnEscape(() => {
        onClose && onClose()
    })

    function handleCancel() {
        onClose && onClose()
    }

    function handleNameChange(value: string) {
        setName(value)
    }

    function handleSubmit() {
        if (collection !== null && notesManager !== null) {
            notesManager.updateCollection({
                collection: { ...collection, name },
            })
            onClose && onClose()
        }
    }

    return (
        <Layout>
            <FormField label="Collection name">
                <TextInput
                    placeholder="Collection name"
                    value={name}
                    focus={true}
                    onChange={handleNameChange}
                    onEnter={(name !== `` && handleSubmit) || undefined}
                />
            </FormField>
            <DialogButtons>
                <Button primary disabled={name === ``} onClick={handleSubmit}>
                    Save
                </Button>
                <Button secondary onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogButtons>
        </Layout>
    )
}

export default EditCollectionDialog
