import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import scrollbars from "../../../../Styles/scrollbars"

import {
    useEnsureOnScreen,
    useNoDocumentScrollbars,
    useOnFocusOut,
} from "../../../../Hooks"

import Input from "../../Input/index"
import Icon from "../../Icon"
import MediaGallery, { MediaType } from "../../MediaGallery"

interface Props {
    className?: string
    photos: MediaType[]
    x: number
    y: number
    onClose: () => void
}

const GalleryPopup: React.FC<Props> = ({ className, photos, onClose }) => {
    const ref = useRef(null)
    const [search, setSearch] = useState(``)
    useNoDocumentScrollbars(true)
    useEnsureOnScreen(ref, 8)

    // Handle if the user tabs away and close the popup
    useOnFocusOut(ref, () => {
        onClose()
    })

    function _handleSearchChange(search: string) {
        setSearch(search)
    }

    return (
        <div ref={ref} className={className}>
            <Input
                placeholder="Search media gallery"
                value={search}
                icon={<Icon name="search" size="18px" />}
                iconPosition="left"
                focus={true}
                onChange={_handleSearchChange}
            />
            <MediaGallery photos={photos} />
        </div>
    )
}

export default styled(GalleryPopup)<Props>`
    ${({ theme, x, y }) => css`
        background-color: ${theme.menu.backgroundColor};
        border: ${theme.menu.border};
        font-size: ${theme.menu.fontSize};
        color: ${theme.menu.color};

        display: grid;
        grid-template-rows: max-content auto;

        width: 350px;
        height: 350px;

        ${`` /* padding: 20px; */}

        ${theme.menu.backgroundBlur &&
        css`
            @supports (backdrop-filter: blur(${theme.menu.backgroundBlur})) {
                backdrop-filter: blur(${theme.menu.backgroundBlur});
                background-color: ${theme.menu.blurBackgroundColor};
            }
        `}

        position: fixed;
        left: ${x}px;
        top: ${y}px;
        z-index: 5001;
        opacity: 1;

        > ${MediaGallery} {
            overflow-y: auto;
            ${scrollbars};
        }
    `}
`
