import React from "react"
import styled, { css } from "styled-components"

import Button from "../../Button"
import Icon from "../../Icon"

export interface Props {
    className?: string
    icon: string
    selected?: boolean
    tooltip?: string
    onClick?: () => void
}

const Item: React.FC<Props> = ({ className, icon, tooltip, onClick }) => {
    return (
        <Button className={className} tooltip={tooltip} onClick={onClick}>
            <Icon name={icon} size="18px" />
        </Button>
    )
}

export default styled(Item)<Props>`
    ${({ theme, selected }) => css`
        padding: 5px;

        background-color: ${theme.iconToolbar[
            (selected ? `selected` : `deselected`) + `BackgroundColor`
        ]};
        color: ${theme.iconToolbar[
            (selected ? `selected` : `deselected`) + `ForegroundColor`
        ]};
    `}
`
