import React, { ComponentType, ReactNode } from "react"
import styled from "styled-components"
import linkifyIt from "linkify-it"
import tlds from "tlds"
import { useWebLink } from "./WebLinkProvider"

const linkify = linkifyIt()
linkify.tlds(tlds)

export interface ComponentProps {
    children?: ReactNode
    href: string
    target: string
    rel: string
}

export interface WebLinkProps {
    decoratedText?: string
    className?: string
    children?: ReactNode
    component?: ComponentType<ComponentProps>
    target?: string
    rel?: string

    //removed props
    dir?: unknown
    entityKey?: unknown
    getEditorState?: unknown
    offsetKey?: unknown
    setEditorState?: unknown
    contentState?: unknown
    blockKey?: unknown
}

const WebLinkDecorator: React.FC<WebLinkProps> = (props) => {
    const { dispatch } = useWebLink()
    const {
        decoratedText = ``,
        component,
        rel = `noreferrer noopener`,
        target = `_blank`,
        dir, // eslint-disable-line @typescript-eslint/no-unused-vars
        entityKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        getEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
        offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        setEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
        contentState, // eslint-disable-line @typescript-eslint/no-unused-vars
        blockKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...otherProps
    } = props

    const links = linkify.match(decoratedText)
    const href = links && links[0] ? links[0].url : ``

    function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault()
        const left = e.pageX
        const top = e.pageY

        dispatch({ type: `showWebLinkMenu`, left, top, url: href })
    }

    const linkProps = {
        ...otherProps,
        href,
        target,
        rel,
        onClick: handleClick,
    }

    if (component) {
        return React.createElement(component, linkProps)
    } else {
        return <a {...linkProps}>{otherProps.children}</a>
    }
}

export default styled(WebLinkDecorator)`
    color: #5e93c5;
    cursor: pointer;
`
