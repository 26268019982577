import { useState, useEffect } from "react"
import { Note } from "../Note"
import { useDatabase } from "../Database"
import { useCollectionViewState } from "../../Providers/CollectionViewProvider"

function useBacklinks(title: string | null = null): Note[] {
    const [notes, setNotes] = useState<Note[]>([])
    const db = useDatabase()
    const collectionView = useCollectionViewState()
    const collectionUUID = collectionView?.collectionUUID || null

    useEffect(() => {
        async function loadBacklinks() {
            setNotes([])
            if (
                title === null ||
                title === `` ||
                db === null ||
                collectionUUID === null
            ) {
                return
            }

            const notes = await db.findBacklinks({
                collectionUUID,
                title,
            })
            setNotes(notes)
        }
        loadBacklinks()
    }, [collectionUUID, title])

    return notes
}

export default useBacklinks
