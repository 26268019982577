import React from "react"
import { Dialog, Button, Icon } from "ui"
import { UUID } from "../../../../Data/UUID"
import { useCollection } from "../../../../Data/Collection"

interface Props {
    uuid: UUID
    onCancel: () => void
    onDelete: () => void
}

const DeleteCollectionConfirmation: React.FC<Props> = ({
    uuid,
    onCancel,
    onDelete,
}) => {
    const collection = useCollection(uuid)

    if (collection === null) {
        return null
    }

    return (
        <Dialog
            visible={true}
            title="Delete collection?"
            maxWidth={400}
            backgroundClose={true}
            closeButton={
                <Button
                    subtle
                    icon={<Icon name="close" size="32px" />}
                    onClick={onCancel}
                />
            }
            buttons={[
                <Button key="delete" danger onClick={onDelete}>
                    Delete
                </Button>,
                <Button key="cancel" secondary onClick={onCancel} focus>
                    Cancel
                </Button>,
            ]}
            onClose={onCancel}
        >
            Are you sure you want to delete the collection
            <br />
            <strong>{collection.name}</strong>?
        </Dialog>
    )
}

export default DeleteCollectionConfirmation
