import React, { useReducer, useContext } from "react"

import produce, { Draft } from "immer"

interface WebLinkState {
    show: boolean
    left: number
    top: number
    url: string
}

const initialState: WebLinkState = {
    show: false,
    top: 0,
    left: 0,
    url: ``,
}

type Action =
    | { type: "showWebLinkMenu"; top: number; left: number; url: string }
    | { type: "hideWebLinkMenu" }
type Dispatch = (action: Action) => void

const WebLinkStateContext = React.createContext<WebLinkState>(initialState)
const WebLinkDispatchContext = React.createContext<Dispatch>(() => {
    return
})

const reducer = (draft: Draft<WebLinkState>, action: Action) => {
    if (action.type === `showWebLinkMenu`) {
        draft.show = true
        draft.left = action.left
        draft.top = action.top
        draft.url = action.url
    } else if (action.type === `hideWebLinkMenu`) {
        draft.show = false
    }
}

const immerReducer = produce(reducer)

export const WebLinkProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(immerReducer, initialState)

    return (
        <WebLinkStateContext.Provider value={state}>
            <WebLinkDispatchContext.Provider value={dispatch}>
                {children}
            </WebLinkDispatchContext.Provider>
        </WebLinkStateContext.Provider>
    )
}

export const useWebLink = () => {
    const state = useContext(WebLinkStateContext)
    const dispatch = useContext(WebLinkDispatchContext)

    return { state, dispatch }
}
