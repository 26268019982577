import { useLayoutEffect } from "react"

export default function useNoDocumentScrollbars(enabled = true) {
    useLayoutEffect(() => {
        if (!enabled) {
            return
        }

        const doc = document.querySelector(`body`) as HTMLBodyElement
        const originalValue = doc.style.overflow
        doc.style.overflow = `hidden`

        return () => {
            doc.style.overflow = originalValue
        }
    }, [enabled])
}
