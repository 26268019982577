import React from "react"
import styled, { css } from "styled-components"
import YouTubeEmbed from "../YouTubeEmbed"
import VimeoEmbed from "../VimeoEmbed"
import WebPreview from "../WebPreview"

import {
    getYouTubeVideoIDFromURL,
    getVimeoVideoIDFromURL,
} from "../../../Utils"

import scrollbars from "../../../Styles/scrollbars"

const ContentContainer = styled.div<{ show: boolean; width?: number | null }>`
    ${({ show = true, width = null }) => css`
        ${show && `width: ${width === null ? `auto` : `${width}px`};`}
        ${!show && `width: 0px;`}
        transition: width 0.3s ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        ${scrollbars};
    `}
`

interface ContentPreviewProps {
    url?: string | null
}

const ContentPreview: React.FC<ContentPreviewProps> = ({ url = null }) => {
    let type: "youtube" | "vimeo" | "web" | null = null

    if (url === null) {
        return <ContentContainer show={false} />
    }

    const youTubeVideoID = getYouTubeVideoIDFromURL(url)
    let width: number | null = null
    if (youTubeVideoID !== null) {
        type = `youtube`
        width = 640
    } else {
        type = `web`
    }

    const vimeoVideoID = getVimeoVideoIDFromURL(url)
    if (vimeoVideoID !== null) {
        type = `vimeo`
    }

    return (
        <ContentContainer show={true} width={width}>
            {type === `youtube` && youTubeVideoID !== null && (
                <YouTubeEmbed videoID={youTubeVideoID} />
            )}
            {type === `vimeo` && vimeoVideoID !== null && (
                <VimeoEmbed videoID={vimeoVideoID} />
            )}
            {type === `web` && <WebPreview url={url} />}
        </ContentContainer>
    )
}

export default ContentPreview
