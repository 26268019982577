import { useMemo } from "react"
import { useCollectionConfig } from "./useCollectionConfig"

export function useCollectionNoteType(
    collectionUUID: string | null,
    noteType: string | null
) {
    const config = useCollectionConfig(collectionUUID)

    const collectionNoteType = useMemo(() => {
        return config?.noteTypes.find((nt) => nt.name === noteType) || null
    }, [noteType, config?.noteTypes])

    return collectionNoteType
}
