import React, { useState, useEffect, useRef, useMemo } from "react"
import { useDelayedEffect } from "../../../Hooks"
import styled, { css } from "styled-components"
import {
    // Editor,
    AtomicBlockUtils,
    EditorState,
    RichUtils,
    ContentBlock,
    DraftEditorCommand,
    CompositeDecorator,
    convertToRaw,
    convertFromRaw,
    RawDraftContentState,
    DraftDecorator,
    ContentState,
} from "draft-js"
import Editor from "@draft-js-plugins/editor"
// import { MultiDecorator } from "@draft-js-plugins/editor"
import Portal from "../../Portal"
import { Note } from "../../../Data/Note"

import {
    useMention,
    useNoteLink,
    useHashtag,
    useWebLink,
    useMarkdownHeading,
    NoteLinkProvider,
    CreateNoteLinkContextMenu,
    WebLinkContextMenu,
    WebLinkProvider,
} from "../../../EditorPlugins"

import MultiDecorator from "./MultiDecorator"

interface NoteEditorProps {
    className?: string
    focusTrigger: number
    reloadTrigger: number
    state?: RawDraftContentState
    readOnly?: boolean
    onChangeDelay?: number
    onChange?: (contentState: ContentState) => void
}

const NoteEditor: React.FC<NoteEditorProps> = ({
    className,
    reloadTrigger = 0,
    focusTrigger = 0,
    readOnly = false,
    state,
    onChangeDelay = 300,
    onChange,
}) => {
    const hashtagPlugin = useHashtag()
    // const {
    //     LookupSuggestions: MentionsSuggestions,
    //     ...mentionPlugin
    // } = useMention()
    const {
        LookupSuggestions: NoteLinkSuggestions,
        ...noteLinkPlugin
    } = useNoteLink()
    const markdownHeadingPlugin = useMarkdownHeading()
    const webLinkPlugin = useWebLink()

    const plugins = useMemo(() => {
        return [
            // hashtagPlugin,
            noteLinkPlugin,
            // mentionPlugin,
            markdownHeadingPlugin,
            webLinkPlugin,
        ]
    }, [])

    // See https://github.com/draft-js-plugins/draft-js-plugins/issues/401
    // We need to create this composite decorate when loading the state again
    // Turns out that if you load it when in read-only mode, then enable it, it sort of keeps the decorator plugins (e.g. hashtag)
    // But if you create a new one, it breaks the decorators for all future uses
    // The solution is to create a multi-decorator of a composite decorator, then when converting from raw, use it
    // Turns out, if you don't use the MultiDecorator, React bombs out with a maximum call stack error
    const compositeDecorator = useMemo(() => {
        let decorators: Array<DraftDecorator> = []
        plugins.forEach((plugin) => {
            plugin.decorators?.forEach((decorator: any) => {
                // FIXME any
                decorators.push(decorator)
            })
        })
        //return new CompositeDecorator(decorators)
        // @ts-ignore
        return new MultiDecorator([new CompositeDecorator(decorators)])
    }, [])

    const [editorState, setEditorState] = useState(() => {
        if (state) {
            const newContentState = convertFromRaw(state)
            // https://github.com/draft-js-plugins/draft-js-plugins/issues/1665
            // Avoid putting in the decorators here, it appears that they will work
            // on an initial load anyway, you only need it when reloading (e.g. on a change event from dexie)
            const newState = EditorState.createWithContent(
                newContentState,
                compositeDecorator // Preserve the plugin decorators
            )
            return newState
        } else {
            return EditorState.createEmpty()
        }
    })
    // Keep track of the content state, this is what will be send in the on change event
    const [contentState, setContentState] = useState<ContentState | null>(null)
    const editorRef = useRef<Editor>(null)

    useEffect(() => {
        if (reloadTrigger !== 0 && state !== undefined) {
            const newContentState = convertFromRaw(state)
            const newState = EditorState.createWithContent(
                newContentState,
                // https://github.com/draft-js-plugins/draft-js-plugins/issues/1665
                // This might cause an infinite loop, might need a new solution to reload the whole
                // editor if the state changes from something remote
                compositeDecorator // Preserve the plugin decorators
            )
            setEditorState(newState)
        }
    }, [reloadTrigger])

    // Watch for focus
    useEffect(() => {
        if (focusTrigger !== 0 && editorRef.current !== null) {
            editorRef.current.focus()
        }
    }, [focusTrigger])

    useDelayedEffect(
        () => {
            if (contentState !== null) {
                onChange && onChange(contentState)
            }
        },
        onChangeDelay,
        [contentState]
    )

    function _handleChange(state: EditorState) {
        setEditorState(state)

        const newContentState = state.getCurrentContent()
        const oldContentState = editorState.getCurrentContent()

        if (newContentState !== oldContentState) {
            // Save the new content state which can trigger the delayed onchange
            setContentState(newContentState)
        }
    }

    // Example getting the state to raw
    // useEffect(() => {
    //     const contentState = editorState.getCurrentContent()
    //     const rawState = convertToRaw(contentState)
    //     console.log(JSON.stringify(rawState))
    // }, [editorState])

    // function _handleFocus() {
    //     onFocus && onFocus()
    // }

    // function _addBlock() {
    //     const contentState = editorState.getCurrentContent()
    //     const contentStateWithEntity = contentState.createEntity(
    //         `myBlock`,
    //         `IMMUTABLE`,
    //         {
    //             prop: `test`,
    //         }
    //     )
    //     const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    //     const newEditorState = EditorState.set(editorState, {
    //         currentContent: contentStateWithEntity,
    //     })

    //     setEditorState(
    //         AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ` `)
    //     )
    // }

    function _handleKeyCommand(
        command: DraftEditorCommand,
        editorState: EditorState
    ) {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            setEditorState(newState)
            return `handled`
        }
        return `not-handled`
    }

    function _handleClick() {
        // Focus the editor
        if (editorRef.current !== null) {
            console.log(`focus`)
            editorRef.current.focus()
        }
    }

    return (
        <div className={className} onClick={_handleClick}>
            <WebLinkProvider>
                <NoteLinkProvider>
                    <Editor
                        // className={className}
                        ref={editorRef}
                        readOnly={readOnly}
                        spellCheck={true}
                        editorState={editorState}
                        plugins={plugins}
                        // plugins={[highlightPlugin]}
                        onChange={_handleChange}
                        // onFocus={_handleFocus}
                        handleKeyCommand={_handleKeyCommand}
                        // blockRendererFn={myBlockRenderer}
                    />
                    {/* <Portal id="mention-suggestions">
                <MentionsSuggestions />
            </Portal> */}
                    <Portal id="note-link-suggestions">
                        <NoteLinkSuggestions />
                    </Portal>
                    <Portal id="create-note-link">
                        <CreateNoteLinkContextMenu />
                    </Portal>
                    <Portal id="web-link">
                        <WebLinkContextMenu />
                    </Portal>
                </NoteLinkProvider>
            </WebLinkProvider>
        </div>
    )
}

export default styled(NoteEditor)`
    ${({ theme }) => css`
        min-height: 200px;
        width: 100%;
        cursor: text;

        .editor {
            box-sizing: border-box;
            border: 1px solid #ddd;
            cursor: text;
            padding: 16px;
            border-radius: 2px;
            margin-bottom: 2em;
            box-shadow: inset 0px 1px 8px -3px #ababab;
            background: #fefefe;
        }

        .editor :global(.public-DraftEditor-content) {
            min-height: 140px;
        }

        // Add the content styles
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0.7em;
            margin-bottom: 0.7em;

            display: grid;
            grid-template-columns: 25px auto;
            margin-left: -25px;
            align-items: baseline;
            width: auto;
            line-height: 1.1em;

            &:before {
                display: block;
                width: 25px;
                content: "";
                font-size: 12px;
                opacity: 0.2;
                font-weight: bold;
                ${theme.note.decorator};
            }
        }

        h1 {
            ${theme.note.h1};
            &:before {
                content: "h1";
            }
        }

        h2 {
            ${theme.note.h2};
            &:before {
                content: "h2";
            }
        }

        h3 {
            ${theme.note.h3};
            &:before {
                content: "h3";
            }
        }

        h4 {
            ${theme.note.h4};
            &:before {
                content: "h4";
            }
        }

        h5 {
            ${theme.note.h5};
            &:before {
                content: "h5";
            }
        }

        h6 {
            ${theme.note.h6};
            &:before {
                content: "h6";
            }
        }
    `}
`
