import React from "react"

import Underlay from "../Underlay"
import Panel from "./Panel"

import { useOnEscape } from "../../../Hooks"

interface InlineDialogProps {
    visible?: boolean
    onClose?: () => void
    minWidth?: number
    maxWidth?: number
    minHeight?: number
    maxHeight?: number
    left?: number
    top?: number
    right?: number
    bottom?: number
}

const InlineDialog: React.FC<InlineDialogProps> = ({
    children,
    left,
    top,
    right,
    bottom,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    visible = false,
    onClose,
}) => {
    function _handleClose() {
        onClose && onClose()
    }

    useOnEscape(_handleClose)

    return (
        <>
            {visible && <Underlay onClick={_handleClose} />}
            {visible && (
                <Panel
                    left={left}
                    right={right}
                    top={top}
                    bottom={bottom}
                    minWidth={minWidth}
                    maxWidth={maxWidth}
                    minHeight={minHeight}
                    maxHeight={maxHeight}
                >
                    {children}
                </Panel>
            )}
        </>
    )
}

export default InlineDialog
