import React from "react"
import styled, { css } from "styled-components"
import { Button, StepList } from "ui"

const HeaderBar = styled.div`
    ${({ theme }) => css`
        ${theme.header.panel};

        display: grid;
        grid-template-columns: auto max-content;
        justify-content: right;
        grid-gap: 20px;
        padding: 5px;

        width: 100%;
        align-items: center;
        position: relative;
        z-index: 1;
    `}
`

interface FocusModeHeaderProps {
    item?: number
    items: number
    batchSize?: number
    onStop: () => void
    onItemChange: (item: number) => void
}

const FocusModeHeader: React.FC<FocusModeHeaderProps> = ({
    item = 1,
    items,
    batchSize = 5,
    onStop,
    onItemChange,
}) => {
    // We want to show them in batches of 5
    const batches = Math.ceil(items / batchSize)
    let renderSize = batchSize
    const lastBatchUnfilledCount = batchSize * batches - items
    // Do we have an odd number and is it in the last page
    if (
        lastBatchUnfilledCount > 0 &&
        item - 1 + (batchSize - lastBatchUnfilledCount) >= items
    ) {
        // We have a partial batch size
        renderSize = batchSize - lastBatchUnfilledCount
    }
    const step = 1 + ((item - 1) % batchSize) // Go from index 1 to index 0 for the %, then back to 1-5

    // debugger
    function handleNext() {
        onItemChange(item + 1)
    }

    function handlePrevious() {
        onItemChange(item - 1)
    }

    return (
        <HeaderBar>
            <StepList
                step={step}
                total={renderSize}
                leftEnabled={item > 1}
                rightEnabled={item < items}
                onNext={handleNext}
                onPrevious={handlePrevious}
            />
            {/* <Button outline>Skip</Button> */}
            <Button secondary onClick={onStop}>
                Stop
            </Button>
        </HeaderBar>
    )
}

export default FocusModeHeader
