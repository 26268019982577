import React from "react"
import CollectionsView from "../CollectionsView"
import SingleNoteFocusView from "../SingleNoteFocusView"
import OrganizeNoteFocusView from "../OrganizeNoteFocusView"
import { UUID } from "../../../Data/UUID"
import { CollectionViewProvider } from "../../../Providers/CollectionViewProvider"

import {
    useFocusSession,
    useFocusSessionDispatch,
} from "../../../Providers/FocusModeViewProvider"

const ViewMode: React.FC = () => {
    const focusSession = useFocusSession()
    const focusModeDispatch = useFocusSessionDispatch()

    function handleOnStop() {
        focusModeDispatch({ type: `exitFocusSession` })
    }

    function handleNoteChange(uuid: UUID) {
        focusModeDispatch({ type: `setActiveNoteUUID`, uuid })
    }

    if (focusSession === null || focusSession.session === null) {
        return <CollectionsView />
    } else {
        if (focusSession.collectionUUID === null) {
            return null
        }

        if (focusSession.session.config.viewMode === `single`) {
            return (
                <CollectionViewProvider
                    collectionUUID={focusSession.collectionUUID}
                >
                    <SingleNoteFocusView
                        focusSession={focusSession.session}
                        activeNoteUUID={focusSession.activeNoteUUID}
                        onStop={handleOnStop}
                        onNoteChange={handleNoteChange}
                    />
                </CollectionViewProvider>
            )
        } else {
            return (
                <CollectionViewProvider
                    collectionUUID={focusSession.collectionUUID}
                >
                    <OrganizeNoteFocusView
                        focusSession={focusSession.session}
                        activeNoteUUID={focusSession.activeNoteUUID}
                        onStop={handleOnStop}
                        onNoteChange={handleNoteChange}
                    />
                </CollectionViewProvider>
            )
        }
    }
}

export default ViewMode
