import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"
import { Note, NoteBody } from "../../Note"
import { extractLinksFromNoteBody, titleToKey } from "./Utils"

export interface PutNoteProps {
    db: FlowtelicDatabase
    view?: UUID | null
    note?: Note
    noteBody?: NoteBody
}

export async function putNote({
    db,
    view = null,
    note,
    noteBody,
}: PutNoteProps) {
    let links: string[] | null = null
    if (noteBody !== undefined) {
        links = extractLinksFromNoteBody(noteBody)
    }

    await db.transaction(`rw`, db.notes, db.noteBody, db.noteLinks, (tx) => {
        // @ts-ignore
        tx.source = view !== null ? `${db.source}:${view}` : db.source
        if (note !== undefined) {
            db.notes.put({ ...note, titleKey: titleToKey(note.title) })
            if (links !== null) {
                db.noteLinks.put({
                    uuid: note.uuid,
                    linkTitles: links,
                    collectionUUID: note.collectionUUID,
                })
            }
        }

        if (noteBody !== undefined) {
            db.noteBody.put(noteBody)
        }
    })
}
