import React from "react"
import styled, { css } from "styled-components"

const Panel = styled.div`
    ${({ theme }) => css`
        ${theme.dragDrop.overlayPanel};
        height: 100%;
        padding: 20px;
    `}
`

const Message = styled.div`
    ${({ theme }) => css`
        ${theme.dragDrop.overlayMessage};
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`

const DropNotesOverlay: React.FC = () => {
    return (
        <Panel>
            <Message>Drop your files here to import</Message>
        </Panel>
    )
}

export default DropNotesOverlay
