import React, { useReducer, useContext, useEffect } from "react"
import produce, { Draft } from "immer"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import {
    useCollectionViewState,
    useCollectionViewDispatch,
} from "../../Providers/CollectionViewProvider"
import { useNote } from "../../Providers/NoteProvider"
import { useCollection, useCollectionConfig } from "../../Data/Collection"
interface NoteLinkState {
    createNote: {
        confirm: boolean
        show: boolean
        title: string
        top: number
        left: number
    }
}

const initialState = {
    createNote: {
        confirm: false,
        show: false,
        title: ``,
        top: 0,
        left: 0,
    },
}

const reducer = (draft: Draft<NoteLinkState>, action: Action) => {
    switch (action.type) {
        case `showCreateNote`:
            draft.createNote = {
                confirm: false,
                show: true,
                title: action.title,
                left: action.left,
                top: action.top,
            }
            break
        case `hideCreateNote`:
            draft.createNote.confirm = false
            draft.createNote.show = false
            break
        case `confirmCreateNote`:
            draft.createNote.confirm = true
            draft.createNote.show = false
            break
        default:
            console.error(`Unknown action`, action)
            throw new Error(`Unknown action, see console got details`)
    }
}

const immerReducer = produce(reducer)

type Action =
    | {
          type: "showCreateNote"
          title: string
          top: number
          left: number
      }
    | { type: "hideCreateNote" }
    | { type: "confirmCreateNote" }

type Dispatch = (action: Action) => void

const NoteLinkStateContext = React.createContext<NoteLinkState>(initialState)
const NoteLinkDispatchContext = React.createContext<Dispatch>(() => {
    return
})

export const NoteLinkProvider: React.FC = ({ children }) => {
    const notesManager = useNotesManager()
    const collectionView = useCollectionViewState()
    const collectionViewDispatch = useCollectionViewDispatch()
    const [state, dispatch] = useReducer(immerReducer, initialState)
    const { state: noteState } = useNote()
    const collection = useCollection(noteState.note?.collectionUUID || null)
    const collectionConfig = useCollectionConfig(
        noteState.note?.collectionUUID || null
    )

    const collectionUUID = collectionView?.collectionUUID || null

    useEffect(() => {
        ;(async () => {
            if (notesManager === null || collectionUUID === null) {
                return
            }

            if (state.createNote.confirm) {
                // We want to find out what type of note to create from the workflow
                // For example, Index notes create Permanent notes, but Idea notes create more Idea notes
                const sourceNoteType = noteState.note?.type

                // Find the note type config
                const noteTypeConfig = collectionConfig?.noteTypes.find(
                    (nt) => nt.name === sourceNoteType
                )

                if (!noteTypeConfig) {
                    return // Now config found, don't know what type to create
                }
                // FIXME: Look up the default workflow state for the new item as we create it

                // First create the new note
                const { note } = await notesManager.createNote({
                    collectionUUID,
                    noteType: noteTypeConfig.createLinkedNoteType,
                    title: state.createNote.title,
                    workflowState: null,
                })

                collectionViewDispatch({ type: `openNote`, uuid: note.uuid })
            }
        })()
    }, [
        notesManager,
        state.createNote.confirm,
        collectionUUID,
        noteState.note?.type,
        collectionConfig?.noteTypes,
    ])

    if (collectionUUID === null || notesManager === null) {
        return null
    }

    return (
        <NoteLinkStateContext.Provider value={state}>
            <NoteLinkDispatchContext.Provider value={dispatch}>
                {children}
            </NoteLinkDispatchContext.Provider>
        </NoteLinkStateContext.Provider>
    )
}

export const useNoteLinkState = () => {
    const state = useContext(NoteLinkStateContext)
    const dispatch = useContext(NoteLinkDispatchContext)

    return { state, dispatch }
}
