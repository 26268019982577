import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const QuoteCentered = styled.div`
    grid-area: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Quote = styled.div`
    max-width: 220px;
    font-family: "Merriweather", serif;
    color: #ffffff;
    font-size: 12px;
    line-height: 23px;
`

const QuoteTitle = styled.h2`
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
`
const QuoteText = styled.p``
const QuoteAuthor = styled.span`
    display: block;
    width: 100%;
    text-align: right;
`

const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
}

const Quotes: React.FC = () => {
    const [firstQuote, setFirstQuote] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            setFirstQuote(!firstQuote)
        }, 10000)

        return () => {
            clearInterval(interval)
        }
    }, [firstQuote, setFirstQuote])

    const flowQuote = (
        <motion.div
            variants={variants}
            initial="hidden"
            animate={firstQuote ? `visible` : `hidden`}
            transition={{ duration: 2 }}
        >
            <Quote>
                <QuoteTitle>Flow</QuoteTitle>
                <QuoteText>
                    the state of concentration and engagement that can be
                    achieved
                </QuoteText>
            </Quote>
        </motion.div>
    )

    const autotelicQuote = (
        <motion.div
            variants={variants}
            initial="hidden"
            animate={!firstQuote ? `visible` : `hidden`}
            transition={{ duration: 2 }}
        >
            <Quote>
                <QuoteTitle>Autotelic</QuoteTitle>
                <QuoteText>
                    the activity that consumes us becomes intrinsically
                    rewarding
                </QuoteText>
                <QuoteAuthor>&mdash; Mihaly Csikszentmihalyi</QuoteAuthor>
            </Quote>
        </motion.div>
    )

    return (
        <>
            <QuoteCentered>{flowQuote}</QuoteCentered>
            <QuoteCentered>{autotelicQuote}</QuoteCentered>
        </>
    )
}

export default Quotes
