import React from "react"
import styled from "styled-components"

import ReactAvatar from "react-avatar"

interface AvatarProps {
    className?: string
    name: string
    email?: string
    size?: string
    round?: boolean
}

const Avatar: React.FunctionComponent<AvatarProps> = ({
    className,
    name,
    email,
    size = `35px`,
    round = true,
}) => {
    return (
        <div className={className} data-testid="avatar">
            <ReactAvatar name={name} email={email} size={size} round={round} />
        </div>
    )
}

export default styled(Avatar)<AvatarProps>`
    display: inline-block;
    padding: 2px;
`
