import { useDatabase } from "../Database"
import { UUID } from "../UUID"
import { Note } from "../Note"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import { exportNoteToMarkdown } from "../../Utils"

export function useMarkdownExport() {
    const db = useDatabase()

    async function exportCollection(uuid: UUID) {
        if (db === null) {
            return
        }

        const zip = new JSZip()

        // Get the collection
        const collection = await db.getCollection({ uuid })
        if (!collection) {
            return
        }

        let lastKey = null
        let notes: Note[] = []
        let note: Note | null = null

        do {
            if (lastKey !== null) {
                notes = await db.notes
                    .where(`:id`)
                    .above(lastKey)
                    .and((item) => item.collectionUUID === uuid)
                    .limit(1)
                    .toArray()
            } else {
                notes = await db.notes
                    .filter((item) => item.collectionUUID === uuid)
                    .limit(1)
                    .toArray()
            }

            if (notes.length > 0) {
                note = notes[0]
                lastKey = note.uuid

                // Get the note body
                const noteBody = await db.loadNoteBody({ uuid: note.uuid })

                zip.file(
                    `notes/` + note.title + `.md`,
                    exportNoteToMarkdown(note, noteBody),
                    {
                        date: note.updated,
                        compression: `DEFLATE`,
                    }
                )
            }
        } while (notes.length > 0)

        // Generate the zip file
        const content = await zip.generateAsync({ type: `blob` })

        saveAs(content, collection.name + `.zip`)
    }

    return exportCollection
}
