import React, { ReactNode } from "react"

// @deprecated
export interface DecoratorProps {
    className?: string
    children?: ReactNode

    //removed props
    decoratedText?: unknown
    dir?: unknown
    entityKey?: unknown
    getEditorState?: unknown
    offsetKey?: unknown
    setEditorState?: unknown
    contentState?: unknown
    blockKey?: unknown

    renderComponent: React.ComponentType
}

function createDecorator(RenderComponent: React.ComponentType) {
    const Decorator: React.FC<DecoratorProps> = (props) => {
        const {
            decoratedText, // eslint-disable-line @typescript-eslint/no-unused-vars
            dir, // eslint-disable-line @typescript-eslint/no-unused-vars
            entityKey, // eslint-disable-line @typescript-eslint/no-unused-vars
            getEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
            offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
            setEditorState, // eslint-disable-line @typescript-eslint/no-unused-vars
            contentState, // eslint-disable-line @typescript-eslint/no-unused-vars
            blockKey, // eslint-disable-line @typescript-eslint/no-unused-vars
            ...otherProps
        } = props

        return <RenderComponent {...otherProps} />
    }

    return Decorator
}

export { createDecorator }
