import styled, { css } from "styled-components"
import scrollbars from "../../../../../Styles/scrollbars"

interface MenuProps {
    visible: boolean
    left: number
    top: number
}

const Menu = styled.div<MenuProps>`
    ${({ theme, visible, left, top }) => {
        return css`
            ${theme.menu.panel};

            position: fixed;
            z-index: 1000;
            left: ${left}px;
            top: ${top}px;
            display: ${visible ? `block` : `none`};
            width: 300px;
            max-height: 260px;

            overflow-y: auto;
            ${scrollbars};
        `
    }}
`

export default Menu
