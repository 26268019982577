import React, { useState } from "react"
import styled, { css } from "styled-components"

const Control = styled.label<{ "data-testid"?: string; disabled: boolean }>`
    ${({ theme, disabled = false }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${theme.toggle.label};

        ${disabled && theme.toggle.disabled};
    `}
`

const HiddenCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

const ToggleLabel = styled.span`
    margin-right: 10px;
`

const ToggleControl = styled.span<{ focused: boolean; checked: boolean }>`
    ${({ theme, focused = false, checked = false }) => css`
        padding: 0;
        width: 40px;
        height: 24px;
        box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        overflow: hidden;

        ${checked &&
        css`
            background-image: linear-gradient(90deg, #404351 0%, #14ae48 99%);

            ${ToggleButton} {
                transform: translateX(15px);
            }
        `}

        ${!checked &&
        css`
            background-image: linear-gradient(90deg, #404351 0%, #343a47 99%);
            ${ToggleButton} {
                transform: translateX(0px);
            }

            ${EnabledBackground} {
                background-color: rgba(20, 174, 72, 0);
            }
        `}

        ${focused && theme.controlFocus}
    `}
`

const EnabledBackground = styled.span`
    opacity: 1;
    transition: background-color 0.3s ease-out;
    background-color: rgba(20, 174, 72, 1);
    display: block;
    width: 100%;
    height: 100%;
`

const ToggleButton = styled.span`
    display: block;
    width: 24px;
    height: 24px;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: transform 0.3s ease-out;
`

interface ToggleProps {
    ref?: React.Ref<HTMLLabelElement> | null
    forwardedRef?: React.Ref<HTMLLabelElement> | null
    className?: string
    onChange?: (selected: boolean) => void
    name?: string
    label?: string
    disabled?: boolean
    checked?: boolean
    testId?: string
}

const Toggle: React.FC<ToggleProps> = ({
    forwardedRef,
    className,
    checked = false,
    name,
    label = null,
    onChange,
    disabled = false,
    testId = `toggle`,
}) => {
    const [focused, setFocused] = useState(false)

    function _handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange && onChange(event.target.checked)
    }

    function _handleFocus() {
        setFocused(true)
    }

    function _handleBlur() {
        setFocused(false)
    }

    return (
        <Control
            ref={forwardedRef}
            className={className}
            data-testid={testId}
            disabled={disabled}
        >
            <HiddenCheckbox
                name={name}
                type="checkbox"
                checked={checked}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                disabled={disabled}
            />
            {label && <ToggleLabel>{label}</ToggleLabel>}
            <ToggleControl focused={focused} checked={checked}>
                <EnabledBackground>
                    <ToggleButton />
                </EnabledBackground>
            </ToggleControl>
        </Control>
    )
}

const _Toggle: React.ComponentType<ToggleProps> = React.forwardRef(
    (props: ToggleProps, ref?: React.Ref<HTMLLabelElement>) => (
        <Toggle {...props} forwardedRef={ref} />
    )
)

export default styled(_Toggle)<ToggleProps>``
