import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    selected?: number
    disabled?: boolean
    onChange?: (index: number) => void
}

const TabBar: React.FC<Props> = ({
    className,
    children,
    selected = 0,
    disabled = false,
    onChange,
}) => {
    const [selectedIndex, setSelectedIndex] = useState(selected)

    useEffect(() => {
        setSelectedIndex(selected)
    }, [selected])

    function _handleTabClick(index: number) {
        setSelectedIndex(index)
        onChange && onChange(index)
    }

    // Clone the children adding a global disabled property, selected and onclick property
    const _children = React.Children.map(children, (child: any, index) =>
        React.cloneElement(child, {
            onClick: child.props.onClick || (() => _handleTabClick(index)),
            selected: selectedIndex === index,
            disabled: disabled || child.props.disabled === true,
        })
    )

    return (
        <div className={className} data-testid="tab-bar">
            {_children}
        </div>
    )
}

export default styled(TabBar)<Props>`
    ${({ theme }) => css`
        background-color: ${theme.tabBar.backgroundColor};
        display: block;
        width: 100%;
    `}
`
