import { FlowtelicDatabase } from "../../../Data/Database"
import Dexie, { Collection as DexieCollection } from "dexie"
import { ObservableItemList } from "../../../Observable"
import { Note, NoteType } from "../../../Data/Note"
import { UUID } from "../../../Data/UUID"
import { Collection } from "../../../Data/Collection"
import { DataLoader } from "../../../Utils"
import { NotesManager } from "../NotesManager"
import { FocusSessionConfig } from "../../../Data/Collection"
import { FocusModeSessionView } from "../FocusModeSessionView"
import collectionPresets from "../../../CollectionPresets"

function getCollectionConfig(collection: Collection) {
    if (collection.userConfig !== null) {
        return collection.userConfig
    } else {
        const preset =
            collectionPresets.find((p) => p.name === collection.configPreset) ||
            null
        if (preset) {
            return preset.config
        }
    }

    return null
}

interface FocusModeViewCache {
    sessions: FocusModeSessionView[]
}

class FocusModeView extends ObservableItemList<
    FocusModeSessionView,
    FocusModeSessionView
> {
    private notesManager: NotesManager
    private cache: DataLoader<FocusModeViewCache>
    // TODO: Collection view, initialise in constructor and watch for updates
    // then reload the views accordingly
    //private collectionsView: CollectionView

    public constructor(notesManager: NotesManager) {
        super()
        this.notesManager = notesManager

        this.cache = new DataLoader<FocusModeViewCache>(
            async () => {
                const collectionEntries = await notesManager.collections.list()

                const sessionViews: FocusModeSessionView[] = []

                // For each collection, create a focus mode view for each session config
                for (let i = 0; i < collectionEntries.length; i += 1) {
                    const uuid = collectionEntries[i].uuid
                    const collection = await notesManager.collections.get(uuid)

                    if (collection === null) {
                        continue
                    }

                    const config = getCollectionConfig(collection)
                    if (config === null) {
                        continue
                    }

                    // Create a view for each focus session
                    config.focusSessions.forEach((session) => {
                        const sessionView = notesManager.createFocusModeSessionView(
                            uuid,
                            session
                        )
                        sessionViews.push(sessionView)
                    })
                }

                return {
                    sessions: sessionViews,
                }
            },
            (data: FocusModeViewCache) => {
                // Cleanup when removing
                // Dispose of all the sessions
                data.sessions.forEach((sessionView) => sessionView.dispose())
            }
        )
    }

    public async sessions() {
        const data = await this.cache.get()
        return data.sessions
    }

    public async sessionByCollection(collectionUUID: UUID) {
        const sessions = await this.sessions()
        return sessions.filter(
            (session) => session.collectionUUID === collectionUUID
        )
    }

    public async sessionByName(collectionUUID: UUID, sessionName: string) {
        const sessions = await this.sessions()
        return sessions.find(
            (session) =>
                session.collectionUUID === collectionUUID &&
                session.focusSessionConfig.name === sessionName
        )
    }

    public updateNote({ note }: { note: Note }, isSelfUpdate: boolean) {
        const data = this.cache.getData()
        if (data === undefined) {
            return
        }

        data.sessions.forEach((session) => {
            session.put(note, isSelfUpdate)
        })
    }

    public removeNote(uuid: UUID, isSelfUpdate: boolean) {
        const data = this.cache.getData()
        if (data === undefined) {
            return
        }

        data.sessions.forEach((session) => {
            session.remove(uuid, isSelfUpdate)
        })
    }

    // public async collectionAdded(
    //     collection: Collection,
    //     isSelfUpdate: boolean
    // ) {
    //     // Reset the data store
    //     this.cache.reset()
    //     const data = await this.cache.get()
    //     this.fireListChangeEvent(data.sessions, isSelfUpdate)
    // }

    // public async collectionRemoved(
    //     collectionUUID: UUID,
    //     isSelfUpdate: boolean
    // ) {
    //     this.cache.reset()
    //     const data = await this.cache.get()
    //     this.fireListChangeEvent(data.sessions, isSelfUpdate)
    // }

    // public async collectionModified(
    //     collection: Collection,
    //     isSelfUpdate: boolean
    // ) {
    //     this.cache.reset()
    //     const data = await this.cache.get()
    //     this.fireListChangeEvent(data.sessions, isSelfUpdate)
    // }
}

export { FocusModeView }
export default FocusModeView
