import React from "react"
import styled from "styled-components"
import { FormField, TextInput, Icon, Button, Text } from "ui"
import { useAccountForm } from "../../Hooks"
import routes from "../../../../routes"

const NextButton = styled(Button)`
    width: 100%;
`

const BackButton = styled(Button)`
    width: 100%;
`

const Buttons = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto;
`

const ChangePassword: React.FC = () => {
    const [{ password, confirmPassword }, dispatch] = useAccountForm()

    let nextEnabled = true
    let passwordIcon = null

    if (password.status === `success`) {
        passwordIcon = <Icon name="check" size="18px" success />
    } else if (password.status === `error`) {
        nextEnabled = false
        passwordIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    let confirmPasswordIcon = null

    if (confirmPassword.status === `success`) {
        confirmPasswordIcon = <Icon name="check" size="18px" success />
    } else if (confirmPassword.status === `error`) {
        nextEnabled = false
        confirmPasswordIcon = <Icon name="error" size="18px" danger />
    } else {
        nextEnabled = false
    }

    function _handlePasswordChange(value: string) {
        dispatch({ type: `set`, field: `password`, value })
    }

    function _handleConfirmPasswordChange(value: string) {
        dispatch({ type: `set`, field: `confirmPassword`, value })
    }

    function _handleBack() {
        dispatch({ type: `setContext`, formType: `signin` })
    }

    function _handleNext() {
        // TODO
        return
    }

    return (
        <>
            <h1>Change your password</h1>

            <Text>
                Briliant! Now you can set a new password for you to use to
                access your account.
            </Text>

            <FormField label="Password" error={password.error}>
                <TextInput
                    name="password"
                    type="password"
                    value={password.value}
                    onChange={_handlePasswordChange}
                    icon={passwordIcon}
                />
            </FormField>

            <FormField label="Confirm password" error={confirmPassword.error}>
                <TextInput
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword.value}
                    onChange={_handleConfirmPasswordChange}
                    icon={confirmPasswordIcon}
                />
            </FormField>

            <Buttons>
                <BackButton secondary cta onClick={_handleBack}>
                    Back
                </BackButton>
                <NextButton
                    primary
                    cta
                    onClick={_handleNext}
                    disabled={!nextEnabled}
                >
                    Next
                </NextButton>
            </Buttons>

            <Text secondary centered>
                <a href={routes.signin}>Return to sign in</a>
            </Text>
        </>
    )
}

export default ChangePassword
