import { EditorState } from "draft-js"
import { Map } from "immutable"

type SetEditorState = (editorState: EditorState) => void
type GetEditorState = () => EditorState

interface ClientRectFunction {
    (): DOMRect | undefined
}

class SuggestionStore {
    private _getEditorState?: GetEditorState
    private _setEditorState?: SetEditorState
    private isOpen = false

    // What are searches? and how do they relate to offset keys? Why is it <offsetKey,offsetKey>?
    // Searches are the searches for the entity
    private searches: Map<string, string> = Map()
    // Not sure what to use this for yet
    private clientRectFunctions: Map<string, ClientRectFunction> = Map()

    public init({
        getEditorState,
        setEditorState,
    }: {
        getEditorState: GetEditorState
        setEditorState: SetEditorState
    }) {
        this._getEditorState = getEditorState
        this._setEditorState = setEditorState
    }

    public getEditorState() {
        return (this._getEditorState && this._getEditorState()) || null
    }

    public setEditorState(editorState: EditorState) {
        this._setEditorState && this._setEditorState(editorState)
    }

    public rerender() {
        const editorState = this.getEditorState()
        if (editorState) {
            this.setEditorState(editorState)
        }
    }

    public registerOffsetKey(offsetKey: string) {
        this.searches = this.searches.set(offsetKey, offsetKey)
    }

    public deregisterOffsetKey(offsetKey: string) {
        this.searches = this.searches.delete(offsetKey)
        this.clientRectFunctions = this.clientRectFunctions.delete(offsetKey)
    }

    public updatePortalClientRect(offsetKey: string, func: ClientRectFunction) {
        this.clientRectFunctions = this.clientRectFunctions.set(offsetKey, func)
    }

    public getPortalClientRect(offsetKey: string) {
        return this.clientRectFunctions.get(offsetKey)()
    }

    public getIsOpen() {
        return this.isOpen
    }

    public setIsOpen(isOpen: boolean) {
        this.isOpen = isOpen
    }

    public getAllSearches() {
        return this.searches
    }
}

export default SuggestionStore
