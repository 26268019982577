import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Theme from "../../Theme"
import routes from "../../routes"
import { ClientProvider } from "../../API/Client"

import AppView from "../Views/AppView"
import Account from "../Account"

const App: React.FC = () => {
    return (
        <Router>
            <ClientProvider>
                <Theme>
                    <Switch>
                        <Route path="/">
                            <AppView />
                        </Route>
                        <Route path={routes.signup}>
                            <Account activeForm="signup" />
                        </Route>
                        <Route path={routes.signin}>
                            <Account activeForm="signin" />
                        </Route>
                    </Switch>
                </Theme>
            </ClientProvider>
        </Router>
    )
}

export default App
