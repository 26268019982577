import React, { useState, useEffect } from "react"

export default function useDimensions(ref: React.RefObject<HTMLElement>) {
    const [dimensions, setDimensions] = useState([0, 0])
    useEffect(() => {
        if (ref.current == null) return

        const bounds = ref.current.getBoundingClientRect()
        setDimensions([bounds.width, bounds.height])
    }, [ref.current])

    return dimensions
}
