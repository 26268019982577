import React, { useLayoutEffect, useState, useRef } from "react"
import styled, { css } from "styled-components"
import { useEventListener } from "../../Hooks"

const Container = styled.div`
    height: 100%;
`

interface OverlayProps {
    bounds: DOMRect
    visible: boolean
}

const Overlay = styled.div<OverlayProps>`
    ${({ bounds, visible }) => css`
        position: absolute;
        top: ${bounds.top}px;
        left: ${bounds.left}px;
        width: ${bounds.width}px;
        height: ${bounds.height}px;

        ${!visible &&
        css`
            opacity: 0;
            pointer-events: none;
            user-select: none;
        `}
        ${visible &&
        css`
            opacity: 1;
        `}
        transition: opacity 0.2s linear;
    `}
`

interface DropContainerProps {
    className?: string
    hintComponent: React.ReactElement
    overlayComponentRef: React.MutableRefObject<HTMLDivElement | null>
    onDrop: (files: FileList) => void
}

const DropContainer: React.FC<DropContainerProps> = ({
    className,
    children,
    hintComponent,
    overlayComponentRef,
    onDrop,
}) => {
    const dropRef = useRef<HTMLDivElement | null>(null)
    const dragCounter = useRef(0)
    const [bounds, setBounds] = useState<DOMRect | null>(null)
    const [visible, setVisible] = useState(false)

    useLayoutEffect(() => {
        if (!overlayComponentRef.current) {
            return
        }

        // Use a resize observer to recalculate the bounds when the viewport changes
        // @ts-ignore - ResizeObserver isn't recognised in typescript
        const ro = new ResizeObserver(() => {
            if (overlayComponentRef.current !== null) {
                setBounds(overlayComponentRef.current.getBoundingClientRect())
            }
        })
        ro.observe(overlayComponentRef.current)

        // Cleanup
        return () => {
            ro.disconnect()
        }
    }, [overlayComponentRef.current])

    function handleDragEnter(e: DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current++
        setVisible(true)
    }

    function handleDragLeave(e: DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current--
        if (dragCounter.current === 0) {
            setVisible(false)
        }
    }

    function handleDragOver(e: DragEvent) {
        e.preventDefault()
        e.stopPropagation()
    }

    function handleDrop(e: DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        setVisible(false)
        const files = e.dataTransfer?.files
        if (files && files.length > 0) {
            onDrop(files)
            e.dataTransfer?.clearData()
            dragCounter.current = 0
        }
    }

    useEventListener(`dragenter`, handleDragEnter, dropRef)
    useEventListener(`dragleave`, handleDragLeave, dropRef)
    useEventListener(`dragover`, handleDragOver, dropRef)
    useEventListener(`drop`, handleDrop, dropRef)

    return (
        <Container className={className} ref={dropRef}>
            {bounds && (
                <Overlay bounds={bounds} visible={visible}>
                    {hintComponent}
                </Overlay>
            )}
            {children}
        </Container>
    )
}

export default DropContainer
