import escapeRegExp from "lodash/escapeRegExp"
import { NoteBody } from "../../../Note"
function replaceLinkInText(text: string, oldText: string, newText: string) {
    const regexp = new RegExp(escapeRegExp(`[[${oldText}]]`), `g`)
    return text.replaceAll(regexp, `[[${newText}]]`)
}

interface ReplaceLinkInNoteBodyProps {
    noteBody: NoteBody
    oldTitle: string
    newTitle: string
}

export default function replaceLinkInNoteBody({
    noteBody,
    oldTitle,
    newTitle,
}: ReplaceLinkInNoteBodyProps): NoteBody {
    const updatedBody = {
        ...noteBody,
        body: {
            ...noteBody.body,
            blocks: noteBody.body.blocks.map((block) => {
                return {
                    ...block,
                    text: replaceLinkInText(block.text, oldTitle, newTitle),
                }
            }),
        },
    }

    return updatedBody
}
