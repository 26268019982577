import React from "react"
import styled from "styled-components"
import { State } from "./Data/types"
import Provider from "./Data/Provider"
import { FormType } from "./Data/types"

import Modal from "./Modal"
import { Steps as _Steps } from "ui"
import EmailSignup from "./Signup/Email"
import SignupSetup1 from "./Signup/Setup1"
import SignupSetup2 from "./Signup/Setup2"
import SignupSetup3 from "./Signup/Setup3"
import EmailSignin from "./Signin/Email"
import SendReset from "./PasswordReset/SendReset"
import ResetCode from "./PasswordReset/ResetCode"
import ChangePassword from "./PasswordReset/ChangePassword"

const Steps = styled(_Steps)`
    margin: 0 0 20px 0;
`

interface Props {
    activeForm: FormType
}

const Account: React.FC<Props> = ({ activeForm }) => {
    function _handleCreate() {
        return
    }

    return (
        <Provider activeForm={activeForm}>
            {(state: State) => (
                <Modal>
                    {state.formStage && (
                        <Steps total={3} step={state.formStage} />
                    )}
                    {state.activeForm === `signup` && <EmailSignup />}
                    {state.activeForm === `signupUsernamePassword` && (
                        <SignupSetup1 />
                    )}
                    {state.activeForm === `signupSiteName` && <SignupSetup2 />}
                    {state.activeForm === `signupInterests` && <SignupSetup3 />}
                    {state.activeForm === `signin` && <EmailSignin />}
                    {state.activeForm === `sendPasswordReset` && <SendReset />}
                    {state.activeForm === `passwordResetCode` && <ResetCode />}
                    {state.activeForm === `resetPassword` && <ChangePassword />}
                </Modal>
            )}
        </Provider>
    )
}

export default Account
