import { ItemEventCallback } from "../types"
import { UUID } from "../../Data/UUID"
import { v4 as uuid } from "uuid"

abstract class ObservableItem<TItem> {
    private itemListeners: Set<ItemEventCallback<TItem>> = new Set()
    private viewID: UUID

    public constructor() {
        this.viewID = uuid()
    }

    get id() {
        return this.viewID
    }

    public watchItem(callback: ItemEventCallback<TItem>) {
        this.itemListeners.add(callback)
        return () => {
            this.itemListeners.delete(callback)
        }
    }

    public fireItemUpdatedEvent(item: TItem, isSelfUpdate: boolean) {
        // Clone the items coming out of here so React can update
        const clonedItem = { ...item }
        this.itemListeners.forEach((cb) =>
            cb(clonedItem, `updated`, isSelfUpdate)
        )
    }

    public fireItemDeletedEvent(item: TItem, isSelfUpdate: boolean) {
        // Clone the items coming out of here so React can update
        const clonedItem = { ...item }
        this.itemListeners.forEach((cb) =>
            cb(clonedItem, `deleted`, isSelfUpdate)
        )
    }
}

export { ObservableItem }
