import FlowtelicDatabase from "../FlowtelicDatabase"
import { UUID } from "../../UUID"
import { NoteType } from "../../Note"
import { titleToKey } from "./Utils"

export interface FindNotesByTitleProps {
    collectionUUID: UUID
    title: string
    db: FlowtelicDatabase
}

export function findNotesByTitle({
    db,
    collectionUUID,
    title,
}: FindNotesByTitleProps) {
    return db.notes
        .where({ collectionUUID, titleKey: titleToKey(title) })
        .toArray()
}
