import React, { useState } from "react"

import { ButtonDialog, SidebarButton, Icon } from "ui"
import AddCollectionDialog from "../AddCollectionDialog"

interface AddCollectionButtonProps {
    place?: "above" | "below"
}

const AddCollectionButton: React.FC<AddCollectionButtonProps> = ({
    place = `below`,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    function handleButtonClick() {
        setIsOpen(true)
    }

    function handleClose() {
        setIsOpen(false)
    }

    return (
        <ButtonDialog
            isOpen={isOpen}
            button={
                <SidebarButton
                    onClick={handleButtonClick}
                    icon={<Icon name="add" />}
                    subtle
                ></SidebarButton>
            }
            onClose={handleClose}
            place={place}
            maxHeight={76}
        >
            <AddCollectionDialog onClose={handleClose} />
        </ButtonDialog>
    )
}

export default AddCollectionButton
