import React from "react"
import styled, { css } from "styled-components"
import { LookupSuggestionsModalProps } from "../../../RelatedEntity"

interface DialogProps {
    visible: boolean
    left: number
    top: number
}

const Dialog = styled.div<DialogProps>`
    ${({ visible, left, top }) => {
        return css`
            position: absolute;
            left: ${left}px;
            top: ${top}px;
            border: 1px solid green;
            display: ${visible ? `block` : `none`};
        `
    }}
`

// TODO: Callback to add the entity after it's been selected
const Suggestions: React.FC<LookupSuggestionsModalProps> = ({
    visible,
    left,
    top,
    searchValue,
}) => {
    return (
        <Dialog visible={visible} left={left} top={top}>
            Searching for {searchValue}
        </Dialog>
    )
}

export default Suggestions
