import { ConfigInterface } from "./types"

let config: ConfigInterface = {
    API_SERVER_HTTP_URL: ``,
    API_SERVER_WS_URL: ``,
    PREVIEW_SERVER: `http://localhost:8080`,
}

//@ts-ignore
if (window.FLOWTELIC_APP_CONFIG !== undefined) {
    //@ts-ignore
    config = { ...config, ...window.FLOWTELIC_APP_CONFIG }
}

export default config
