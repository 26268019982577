import React, { useState } from "react"
import styled from "styled-components"

import { Collection } from "../../../Data/Collection"
import { TextInput, Button } from "ui"
import { useOnEscape } from "../../../Hooks"
import { v4 as uuidv4 } from "uuid"
import { useNotesManager } from "../../../Providers/NotesManagerProvider"
import collectionPresets from "../../../CollectionPresets"

const Layout = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: 200px max-content;
    grid-gap: 10px;
`

interface AddCollectionDialogProps {
    collection?: Collection
    onClose?: () => void
}

export const AddCollectionDialog: React.FC<AddCollectionDialogProps> = ({
    collection = null,
    onClose,
}) => {
    const notesManager = useNotesManager()
    const [name, setName] = useState(collection?.name || ``)

    useOnEscape(() => {
        onClose && onClose()
    })

    function handleNameChange(value: string) {
        setName(value)
    }

    function handleSubmit() {
        if (notesManager === null) {
            return
        }

        const collection: Collection = {
            uuid: uuidv4(),
            name: name,
            deleted: false,
            configPreset: collectionPresets[0].name,
            userConfig: null,
        }

        notesManager.addCollection({ collection })
        onClose && onClose()
    }

    return (
        <Layout>
            <TextInput
                placeholder="Collection name"
                value={name}
                focus={true}
                onChange={handleNameChange}
                onEnter={(name !== `` && handleSubmit) || undefined}
            />
            <Button primary disabled={name === ``} onClick={handleSubmit}>
                {collection ? `Update` : `Create`}
            </Button>
        </Layout>
    )
}

export default AddCollectionDialog
