import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import Heading from "./Heading"
import Content from "./Content"

interface Props {
    className?: string
    title: string
    secondary?: boolean
    isOpen?: boolean
    onOpenToggle?: (isOpen: boolean) => void
    testId?: string
}

const Section: React.FC<Props> = ({
    className,
    title,
    children,
    isOpen = true,
    secondary = false,
    onOpenToggle,
    testId = `section`,
}) => {
    const [_isOpen, _setIsOpen] = useState(isOpen)

    useEffect(() => {
        _setIsOpen(isOpen)
    }, [isOpen])

    function _handleOpenChange(isOpen: boolean) {
        _setIsOpen(isOpen)
        onOpenToggle && onOpenToggle(isOpen)
    }

    return (
        <div className={className} data-testid={testId}>
            <Heading
                isOpen={_isOpen}
                onOpenChange={_handleOpenChange}
                secondary={secondary}
            >
                {title}
            </Heading>
            <Content visible={_isOpen} secondary={secondary}>
                {children}
            </Content>
        </div>
    )
}

export default styled(Section)<Props>`
    ${(props) => css``}
`
