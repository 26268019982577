import React from "react"
import styled, { css } from "styled-components"
import Spaceman from "../../../Assets/spaceman.svg"

const Card = styled.div`
    ${({ theme }) => css`
        ${theme.card};

        max-width: 600px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        padding: 40px;
    `}
`

const Image = styled.img`
    max-height: 400px;
    padding: 20px;
`

const Message = styled.div`
    font-size: 16px;
    opacity: 0.7;
    font-style: italic;
`

const NoteDeletedCard: React.FC = () => {
    return (
        <Card>
            <Image src={Spaceman} />
            <Message>
                This note appears to be lost in space
                <br />
                (or you deleted it)
            </Message>
        </Card>
    )
}

export default NoteDeletedCard
