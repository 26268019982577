import React from "react"
import { Dialog, Button, Icon } from "ui"

interface Props {
    onCancel: () => void
    onDelete: () => void
}

const DeleteNoteConfirmation: React.FC<Props> = ({ onCancel, onDelete }) => {
    return (
        <Dialog
            visible={true}
            title="Delete note?"
            maxWidth={400}
            backgroundClose={true}
            closeButton={
                <Button
                    subtle
                    icon={<Icon name="close" size="32px" />}
                    onClick={onCancel}
                />
            }
            buttons={[
                <Button key="delete" danger onClick={onDelete}>
                    Delete
                </Button>,
                <Button key="cancel" secondary onClick={onCancel} focus>
                    Cancel
                </Button>,
            ]}
            onClose={onCancel}
        >
            Are you sure you want to delete this note?
        </Dialog>
    )
}

export default DeleteNoteConfirmation
