import { zettelkastenConfig } from "./zettelkasten"
import { CollectionConfigPreset } from "./types"

const presets: CollectionConfigPreset[] = [
    {
        name: `zettelkasten`,
        label: `Zettelkasten`,
        config: zettelkastenConfig,
    },
]

export default presets
