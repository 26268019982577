import React from "react"
import styled, { css } from "styled-components"

interface Props {
    className?: string
    children: React.ReactNode
    secondary?: boolean
    centered?: boolean
}

const Text: React.FC<Props> = ({ className, children }) => {
    return <p className={className}>{children}</p>
}

export default styled(Text)<Props>`
    ${({ theme, secondary = false, centered = false }) => css`
        ${centered &&
        css`
            text-align: center;
        `}
        ${!secondary && theme.text.primary};
        ${secondary && theme.text.secondary};

        * {
            &:focus {
                outline: none;
                ${theme.controlFocus}
            }
        }
    `}
`
