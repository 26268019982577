import React from "react"
import styled, { css } from "styled-components"

import _Circle from "./Circle"
import _Line from "./Line"

const Circle = styled(_Circle)`
    ${({ fill = false }) => {
        return css`
            fill: rgba(161, 161, 163, ${fill ? 1 : 0});
            stroke: rgba(161, 161, 163, 1);
            stroke-width: 1px;
            transition: fill 0.3s linear;
        `
    }}
`

const Line = styled(_Line)`
    stroke: #3d3d3d;
    stroke-width: 1px;
`

interface Props {
    className?: string
    total: number
    step?: number
    testId?: string
}

const stepWidth = 100

const Steps: React.FC<Props> = ({
    className,
    total,
    step = 1,
    testId = `steps`,
}) => {
    const elements = []

    // Add each step except the final one
    for (let i = 1; i <= total; i += 1) {
        const circleX = (i - 1) * stepWidth + 5 // 5px indented
        elements.push(
            <Circle key={`c${i}`} cx={circleX} cy={5} r={5} fill={i <= step} />
        )

        // Add the line if we're not the last
        if (i < total) {
            elements.push(
                <Line
                    key={`l${i}`}
                    x1={circleX + 20}
                    y1={5}
                    x2={circleX + 80}
                    y2={5}
                />
            )
        }
    }

    return (
        <div className={className} data-testid={testId}>
            <svg
                viewBox={`0 0 ${(total - 1) * stepWidth + 12} 12`}
                xmlns="http://www.w3.org/2000/svg"
            >
                {elements}
            </svg>
        </div>
    )
}

export default styled(Steps)`
    ${({ total }: { total: number }) => css`
        display: flex;
        justify-content: center;

        > svg {
            width: ${(total - 1) * stepWidth + 12}px;
            height: 12px;
        }
    `}
`
