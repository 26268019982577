import React, { useRef, useState } from "react"
import styled, { css } from "styled-components"

import Control from "./Control"
import Menu, { Item } from "../../Menu"
import Icon from "../../Icon"

import { useElementBounds } from "../../../../Hooks"

interface Props {
    className?: string
}

const AddButton: React.FC<Props> = ({ className }) => {
    const controlRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const controlBounds = useElementBounds(controlRef, isOpen)

    function _handleOpen() {
        setIsOpen(true)
    }

    function _handleClose() {
        setIsOpen(false)
    }

    return (
        <div className={className} data-testid="add-button">
            <Control ref={controlRef} onClick={_handleOpen} />
            {controlBounds && (
                <Menu
                    x={controlBounds.x}
                    y={controlBounds.y + controlBounds.height}
                    minWidth={80}
                    visible={true}
                    onClose={_handleClose}
                >
                    <Item>
                        <Icon name="upload-cloud" size="14px" />
                        Upload
                    </Item>
                    <Item>
                        <Icon name="link" size="14px" />
                        URL
                    </Item>
                </Menu>
            )}
        </div>
    )
}

export default styled(AddButton)<Props>`
    ${(props) => css``}
`
