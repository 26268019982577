import React from "react"
import styled, { css } from "styled-components"
import TextInput, { TextInputProps } from "../TextInput"
import Icon from "../Icon"

const StyledTextInput = styled(TextInput)`
    ${({ theme }) => css`
        ${theme.searchbox};
    `}
`

const SearchBox: React.FC<TextInputProps> = (props) => {
    return (
        <StyledTextInput
            icon={<Icon name="search" size="18px" subtle />}
            {...props}
        />
    )
}

export default SearchBox
