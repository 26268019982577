import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"

require(`./flowtelic/style.css`)
// require(`remixicon/fonts/remixicon.css`)

// import flowtelicIcons from "./flowtelicIcons.json"

interface Props {
    className?: string
    name: string
    size?: string
    color?: string
    solid?: boolean
    success?: boolean
    danger?: boolean
    subtle?: boolean
    spin?: boolean
    spinSpeed?: number
}

const Icon: React.FC<Props> = ({
    className,
    name,
    size = `24px`,
    spin = false,
    spinSpeed = 2,
}) => {
    const iconClassName = `flowtelic-icon-${name}`

    const icon = <i data-testid="icon" className={iconClassName}></i>

    if (spin) {
        return (
            <div className={className}>
                <motion.div
                    style={{ width: size, height: size }}
                    animate={{ rotate: 360 }}
                    transition={{
                        loop: Infinity,
                        ease: `linear`,
                        duration: spinSpeed,
                    }}
                >
                    {icon}
                </motion.div>
            </div>
        )
    } else {
        return <span className={className}>{icon}</span>
    }
}

export default styled(Icon)<Props>`
    ${({
        theme,
        size = `24px`,
        color = null,
        success = false,
        danger = false,
        subtle = false,
    }) => css`
        font-size: ${size};
        line-height: 0;

        ${color &&
        css`
            color: ${color};
        `}

        ${success &&
        css`
            ${theme.icon.success}
        `}

        ${danger &&
        css`
            ${theme.icon.danger}
        `}

        ${subtle &&
        css`
            ${theme.icon.subtle}
        `}
    `}
`
