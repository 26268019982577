import React from "react"
import { Menu, MenuItem, Icon } from "ui"
import { useNoteLinkState } from "../../NoteLinkState"

const CreateNoteContextMenu: React.FC = () => {
    const { state, dispatch } = useNoteLinkState()

    function handleClose() {
        dispatch({ type: `hideCreateNote` })
    }

    function handleNewNote() {
        dispatch({ type: `confirmCreateNote` })
    }

    return (
        <Menu
            visible={state.createNote.show}
            x={state.createNote.left}
            y={state.createNote.top}
            onClose={handleClose}
        >
            <MenuItem
                icon={<Icon name="draft" size="20px" />}
                onClick={handleNewNote}
            >
                Create "{state.createNote.title}"
            </MenuItem>
        </Menu>
    )
}

export default CreateNoteContextMenu
