import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"

import { NativeTypes } from "react-dnd-html5-backend"
import { useDrop } from "react-dnd"

import NoMedia from "../NoMedia"

interface Props {
    className?: string
}

const UploaderPreview: React.FC<Props> = ({ className }) => {
    const [droppedFile, setDroppedFile] = useState(null)

    const _handleDropFile = useCallback((item, monitor) => {
        if (monitor) {
            const files = monitor.getItem().files

            if (files.length > 0) {
                console.log(`File`, files[0])
            }
        }
    }, [])

    const _collect = useCallback((monitor) => {
        return {
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }
    }, [])

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: _handleDropFile,
        collect: _collect,
    })

    return (
        <div ref={drop} className={className} data-testid="uploader-preview">
            <NoMedia />
        </div>
    )
}

export default styled(UploaderPreview)<Props>`
    ${({ theme }) => css`
        border: 1px dashed ${theme.mediaUploader.borderColor};
        width: 100%;
        height: 170px;
    `}
`
