import React from "react"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import { createGlobalStyle, css } from "styled-components"

const palette = {
    textDefault: `#ffffff`,
    textSecondary: `#ABABAD`,
    focusOutline: `#007AFF`,
    focusOutlinePrimary: `#ffffff`, // Used when a primary prop is set
    danger: `#FF3B3B`,
    dangerBackground: `#6F3737`,
    dangerForeground: `#ffffff`,
    controlPanel: `#3A3E44`,
    controlPanelDisabled: `#292C31`,
    controlForeground: `#ffffff`,
    placeholder: `#767676`,
    success: `#58D515`,
    subtle: `#767676`,

    link: `#5e93c5`,

    cardGradientBase: `#23252d`,
    cardGradientHighlight: `#232731`,

    navActive: `#DBDBDB`,
    navInactive: `#A1A1A3`,

    blockOutline: `rgba(255, 255, 255, 0.1)`,
}

const theme = {
    palette,
    // New Theme
    webfonts: [
        `https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap`,
        `https://fonts.googleapis.com/css2?family=Merriweather&display=swap`,
        `https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap`,
        `https://fonts.googleapis.com/css2?family=Fira+Code&display=swap`,
    ],
    font: {
        family: `'Nunito', sans-serif`,
        size: `12px`,
        color: `#ffffff`,
    },
    text: {
        h1: css`
            font-size: 1.7em;
            font-weight: 700;
            color: ${palette.textDefault};
            margin: 10px 0;
        `,
        p: css`
            font-size: 16px;
            font-weight: normal;
            color: ${palette.textDefault};
            margin: 10px 0;
        `,
        a: css`
            color: ${palette.link};
        `,
        primary: css`
            margin: 14px 0;
            font-size: 14px;
            color: ${palette.textDefault};

            a {
                color: ${palette.textDefault};
            }
        `,
        secondary: css`
            margin: 10px 0;
            font-size: 13px;
            color: ${palette.textSecondary};

            a {
                color: ${palette.textSecondary};
            }
        `,
    },
    controlFocus: css`
        ${({ primary = false }: { primary: boolean }) => css`
            box-shadow: 0 0 1px 1px
                ${primary ? palette.focusOutlinePrimary : palette.focusOutline};
        `}
    `,
    body: css`
        background-image: radial-gradient(
            100% 100% at top,
            rgba(35, 36, 46, 1) 0%,
            rgba(21, 23, 28, 1) 100%
        );
    `,
    card: css`
        background-image: linear-gradient(
            90deg,
            ${palette.cardGradientBase} 0%,
            ${palette.cardGradientHighlight} 100%
        );
        box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
    `,
    header: {
        panel: css`
            background-image: linear-gradient(90deg, #1b1c20 0%, #272a30 100%);
            box-shadow: 0 10px 16px -9px rgba(0, 0, 0, 0.5);
        `,
        navLinkActive: css`
            font-size: 14px;
            color: ${palette.navActive};
            border: 1px solid rgba(255, 255, 255, 0.3);
        `,
        navLinkInactive: css`
            font-size: 14px;
            color: ${palette.navInactive};
        `,
        navLinkHover: css`
            border: 1px solid rgba(255, 255, 255, 0.3);
        `,
    },
    note: {
        decorator: css`
            opacity: 0.2;
        `,
        h1: css`
            font-size: 26px;
            margin-top: 0.2em;
        `,
        h2: css`
            font-size: 24px;
        `,
        h3: css`
            font-size: 22px;
        `,
        h4: css`
            font-size: 20px;
        `,
        h5: css`
            font-size: 18px;
        `,
        h6: css`
            font-size: 17px;
        `,
    },
    focus: {
        recommendationPanel: css`
            background-image: linear-gradient(90deg, #23252d 0%, #232731 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
        `,
        sessionCard: css`
            background-image: linear-gradient(90deg, #343743 0%, #2b2f3a 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
        `,
    },
    h5: css`
        font-size: 17px;
    `,
    modal: {
        // Deprecate?
        panel: css`
            background: #282828;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0px 24px 39px -24px rgba(0, 0, 0, 0.75);
        `,
    },
    dialog: {
        panel: css`
            background-image: radial-gradient(
                100% 184%,
                #272a30 0%,
                #1b1c20 100%
            );
            box-shadow: 0 10px 16px -9px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
        `,
    },
    nav: {
        subMenuPanel: css`
            background-image: linear-gradient(90deg, #343743 0%, #2b2f3a 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            border: none;
            padding: 10px 10px 10px 15px;
        `,
    },
    panel: {
        primary: css`
            background-image: radial-gradient(
                100% 184%,
                rgba(39, 42, 48, 1) 0%,
                rgba(27, 28, 32) 100%
            );
            box-shadow: 0 10px 16px -9px rgba(0, 0, 0, 0.5);
        `,
    },
    form: {
        label: css`
            font-size: 14px;
            font-weight: bold;
            color: ${palette.textDefault};
        `,
        error: css`
            font-size: 12px;
            font-weight: bold;
            color: ${palette.danger};
        `,
    },
    input: {
        control: css`
            background-color: ${palette.controlPanel};
            border-radius: 4px;
            padding: 3px 10px;
        `,
        value: css`
            font-size: 14px;
        `,
        suffix: css`
            font-weight: bold;
            font-size: 14px;
        `,
        placeholder: css`
            color: ${palette.placeholder};
        `,
        error: css`
            border: 1px solid ${palette.danger};
        `,
        disabled: css`
            background-color: ${palette.controlPanel};

            > * {
                opacity: 0.35;
            }
        `,
    },
    searchbox: css`
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
        border-radius: 17.5px;
    `,
    toggle: {
        label: css`
            font-size: 14px;
        `,
        disabled: css`
            opacity: 0.35;
        `,
    },
    checkbox: {
        control: css`
            background-color: ${palette.controlPanel};
            width: 16px;
            height: 16px;
            border-radius: 4px;
        `,
        checkmark: css`
            transition: transform 0.3s ease-in-out;
            background-color: ${palette.controlForeground};
            border-radius: 2px;
            width: 8px;
            height: 8px;

            ${({ checked = false }: { checked: boolean }) => css`
                transform: scale(${checked ? 1 : 0});
            `}
        `,
        label: css`
            font-size: 14px;
        `,
        disabled: css`
            opacity: 0.35;
        `,
    },
    radioButton: {
        control: css`
            background-color: ${palette.controlPanel};
            width: 16px;
            height: 16px;
            border-radius: 50%;
        `,
        radiomark: css`
            transition: transform 0.3s ease-in-out;
            background-color: ${palette.controlForeground};
            border-radius: 50%;
            width: 8px;
            height: 8px;

            ${({ checked = false }: { checked: boolean }) => css`
                transform: scale(${checked ? 1 : 0});
            `}
        `,
        label: css`
            font-size: 14px;
        `,
        disabled: css`
            opacity: 0.35;
        `,
    },
    icon: {
        success: css`
            color: ${palette.success};
        `,
        danger: css`
            color: ${palette.danger};
        `,
        subtle: css`
            color: ${palette.subtle};
        `,
    },
    select: {
        default: css`
            background-image: linear-gradient(90deg, #343743 0%, #2b2f3a 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            border: none;
            padding: 10px 10px 10px 15px;

            &:focus {
                outline: 1px solid ${palette.focusOutline};
            }

            color: ${palette.textDefault};
        `,
        inlineLabel: css`
            font-size: 11px;
            color: #848484;
            font-weight: bold;
        `,
        label: css`
            font-size: 14px;
            color: ${palette.textDefault};
        `,
    },
    button: {
        default: css`
            color: #ffffff;

            * {
                color: #ffffff;
            }
            font-size: 14px;
            font-weight: 500;

            padding: 10px 15px;
            text-align: center;
        `,
        primary: css`
            background-image: linear-gradient(90deg, #007aff 0%, #0063cf 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
        `,
        secondary: css`
            background-image: linear-gradient(90deg, #343743 0%, #2b2f3a 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
        `,
        danger: css`
            background: #c10000;
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
        `,
        cta: css`
            font-size: 16px;
            padding: 15px 25px;
        `,
        subtle: css`
            * {
                color: ${palette.subtle};
            }
        `,
        iconOnly: css`
            padding: 4px;
        `,
        outline: css`
            border: 1px solid ${palette.blockOutline};
        `,
        hover: css`
            ${({
                primary = false,
                secondary = false,
                subtle = false,
            }: {
                primary: boolean
                secondary: boolean
                subtle: boolean
            }) => css`
                ${primary &&
                css`
                    background-image: linear-gradient(
                        90deg,
                        #208bff 0%,
                        #1870d0 100%
                    );
                `}

                ${secondary &&
                css`
                    background-image: linear-gradient(
                        90deg,
                        #404351 0%,
                        #343a47 99%
                    );
                `}

                ${subtle &&
                css`
                    * {
                        color: #ffffff;
                    }
                `}
            `}
        `,
    },
    noteList: {
        listFocus: css`
            background-color: rgba(255, 255, 255, 0.02);
            outline: none;

            // Slightly lighter gradient to the noteList.selected gradient
            .note-list__item--selected {
                background-image: linear-gradient(
                    90deg,
                    #14151a 0%,
                    #181a20 100%
                );
            }
        `,
        title: css`
            font-size: 14px;
            color: #ffffff;
            font-weight: bold;
        `,
        titlePlaceholder: css`
            color: ${palette.subtle};
        `,
        preview: css`
            font-size: 13px;
            color: #848484;
            font-weight: normal;
        `,
        age: css`
            margin-top: 2px; // Shift it down a little to align with the title's 14px
            font-size: 12px;
            color: #a1a1a3;
        `,
        noteInfo: css`
            font-size: 10px;
            font-weight: bold;
            color: #848484;
            text-transform: uppercase;
        `,
        itemBorder: css`
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        `,
        listBorder: css`
            border-right: 1px solid rgba(255, 255, 255, 0.05);
        `,
        selected: css`
            border-left: 3px solid ${palette.focusOutline};
            background-image: linear-gradient(90deg, #111115 0%, #14161c 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
        `,
        hover: css`
            background-image: linear-gradient(90deg, #111115 0%, #14161c 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
        `,
    },
    errorMessage: `
        border-left: 3px solid ${palette.danger};
        background-color: ${palette.dangerBackground};
        color: ${palette.dangerForeground};
        font-size: 14px;
        display: block;
        padding: 10px;
    `,
    menu: {
        panel: css`
            background-image: radial-gradient(
                100% 184%,
                #272a30 0%,
                #1b1c20 100%
            );
            box-shadow: 0 10px 16px -9px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
        `,
        item: css`
            font-size: 14px;
            color: ${palette.textDefault};
            letter-spacing: 0;
            font-weight: normal;
            margin: 5px 0px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }
        `,

        // @deprecated
        blurBackgroundColor: `rgba(0, 0, 0, 0.8)`,
        backgroundColor: `rgba(0, 0, 0, 0.8)`,
        border: `1px solid #6D6D6D`,
        fontSize: `12px`,
        color: `#ffffff`,
        secondaryColor: `#979797`,
        padding: `8px 10px 8px 4px`,
        maxHeight: `350px`,
        hoverBackgroundColor: `#1C65BC`,
        backgroundBlur: `6px`,
    },
    dragDrop: {
        overlayPanel: css`
            background-color: rgba(0, 0, 0, 0.8);
        `,
        overlayMessage: css`
            border: 2px dashed rgba(255, 255, 255, 0.3);
            padding: 30px;
            border-radius: 8px;
            text-align: center;
        `,
    },
    progressBar: {
        container: css`
            background-image: linear-gradient(90deg, #383b3d 0%, #3f4447 100%);
            box-shadow: 0 3px 7px -4px rgba(0, 0, 0, 0.5);
            border-radius: 2px;
            height: 2px;
        `,
        progressBar: css`
            background-image: linear-gradient(90deg, #007aff 0%, #0063cf 100%);
            border-radius: 2px;
            height: 100%;
        `,
    },
    // Old Theme
    heading: {
        color: `#ffffff`,
    },
    // xbutton: {
    //     color: `#A1A1A0`,
    //     activeColor: `#007AFF`,
    //     hover: `#ffffff`,
    // },
    // input: {
    //     errorBorderColor: `#FF0000`,
    //     errorTextColor: `#FF0000`,
    //     disabledBackgroundColor: `#3B3B3B`,
    //     disabledTextColor: `#7A7A7A`,
    //     iconColor: `#A1A1A3`,
    //     iconColorActive: `#ffffff`,
    //     focusOutlineColor: `#007AFF`,
    // },
    radio: {
        color: `#232324`,
        selectedColor: `#ffffff`,
        border: `1px solid #979797`,
        size: `16px`,
        borderRadius: `50%`,
    },
    // checkbox: {
    //     color: `#232324`,
    //     selectedColor: `#ffffff`,
    //     border: `1px solid #979797`,
    //     size: `16px`,
    //     borderRadius: `2px`,
    // },
    slider: {
        thumbColor: `#ffffff`,
        trackValueColor: `rgba(255, 255, 255, 0.7)`,
        trackNonValueColor: `rgba(255, 255, 255, 0.2)`,
    },
    xselect: {
        backgroundColor: `#46464B`,
        border: `1px solid #232324`,
        fontSize: `12px`,
        color: `#ffffff`,
        controlColor: `#A1A1A0`,
        controlPadding: `8px 5px 8px 10px`,
        errorBorder: `1px solid #FF0000`,
        errorTextColor: `#FF0000`,
    },

    colorPicker: {
        thumbBorderColor: `#E6E6E6`,
    },
    separator: {
        color: `#2E2E2E`,
        width: `1px`,
    },
    headerProps: {
        height: `55px`,
        buttonTextSize: `14px`,
    },
    section: {
        primaryBackgroundColor: `#232324`,
        secondaryBackgroundColor: null,
        primaryColor: `#BFBFBF`,
        secondaryColor: `#007AFF`,
        primaryFontSize: `14px`,
        secondaryFontSize: `14px`,
    },
    sidebar: {
        backgroundColor: `#2E2E30`,
        width: `200px`,
        borderColor: `#2E2E2E`,
        borderWdith: `1px`,
        inputFontSize: `14px`,
        inputPlaceholderColor: `#7A7A7A`,
        inputColor: `#ffffff`,
        headingBackgroundColor: `#232324`,
        headingColor: `#BFBFBF`,
        fontSize: `12px`,
        color: `#ffffff`,
    },
    componentTree: {
        item: {
            backgroundColor: `#28282a`,
            color: `#e9e9e9`,
            fontSize: `10px`,
            height: `37px`,
            hoverBackgroundColor: `#313134`,
        },
    },
    editorToolbar: {
        backgroundColor: `#464647`,
        height: `40px`,
        borderTop: `#575757`,
    },
    tabBar: {
        height: `45px`,
        color: `#A1A1A0`,
        activeColor: `#ffffff`,
        backgroundColor: `#232324`,
        activeBackgroundColor: `#2E2E30`,
    },
    iconToolbar: {
        borderColor: `#46464B`,
        borderRadius: `2px`,
        selectedBackgroundColor: `#007AFF`,
        deselectedBackgroundColor: `rgba(0,0,0,0)`,
        selectedForegroundColor: `#ffffff`,
        deselectedForegroundColor: `#A1A1A3`,
    },
    properties: {
        labelColor: `#D9D9D8`,
        labelFontSize: `12px`,
        labelSize: `80px`,
        padding: `10px`,
        inputBorderColor: `#232324`,
        inputBackgroundColor: `#46464B`,
        lockedColor: `#7A7A7A`,
        unlockedColor: `#ffffff`,
        segmentDeselected: `#464646`,
        segmentSelected: `#007AFF`,
        segementHover: `#A0A0A0`,
    },
    mediaUploader: {
        borderColor: `#46464B`,
        labelColor: `#D3D3D3`,
    },
}

const webfonts = theme.webfonts
    .map((url) => `@import url('${url}');`)
    .join(`\n`)

const GlobalStyle = createGlobalStyle`
    *{
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
    }

    html {
        min-height: 100%;

        // Prevent overscrolling
        overflow: hidden;
        height: 100%;
    }

    body {
        overscroll-behavior-y: none;
        height: 100%;
        overflow: auto;

        font-family: ${theme.font.family};
        color: ${theme.font.color};
        size: ${theme.font.size};
        min-height: 100%;

        ${theme.body};
    }

    h1 {
        ${theme.text.h1};
    }

    p {
        ${theme.text.p};
    }

    a {
        ${theme.text.a};
    }
`

const Theme: React.FunctionComponent = ({ children }) => {
    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`
                        ${webfonts}
                    `}
                </style>
            </Helmet>
            <GlobalStyle />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </>
    )
}

export default Theme
