import React, { useRef } from "react"
import styled, { css } from "styled-components"
import Portal from "../../../Portal"
import { v4 as uuid } from "uuid"

import scrollbars from "../../../../Styles/scrollbars"
import { useEnsureOnScreen, useNoDocumentScrollbars } from "../../../../Hooks"

interface PanelProps {
    className?: string
    minWidth?: number
    maxWidth?: number
    minHeight?: number
    maxHeight?: number
    left?: number
    right?: number
    bottom?: number
    top?: number
}

const Panel: React.FC<PanelProps> = ({ className, children }) => {
    const ref = useRef<HTMLDivElement>(null)
    useNoDocumentScrollbars(true)
    useEnsureOnScreen(ref, 8)

    // FIXME: Need a hook that will position this where the bottom of the parent element is
    // e.g. the ref of a button or select control

    return (
        <Portal>
            <div ref={ref} className={className}>
                {children}
            </div>
        </Portal>
    )
}

Panel.displayName = `Panel`

export default styled(Panel)<PanelProps>`
    ${({
        theme,
        minWidth = null,
        maxWidth = null,
        minHeight = null,
        maxHeight = null,
        left,
        right,
        top,
        bottom,
    }) => {
        return css`
            ${theme.menu.panel};

            overflow-y: auto;

            ${minWidth &&
            css`
                min-width: ${minWidth}px;
            `}

            ${maxWidth &&
            css`
                max-width: ${maxWidth}px;
            `}

            ${minHeight &&
            css`
                min-height: ${minHeight}px;
            `}

            ${maxHeight &&
            css`
                max-height: ${maxHeight}px;
            `}
            
            ${theme.menu.backgroundBlur &&
            css`
                @supports (
                    backdrop-filter: blur(${theme.menu.backgroundBlur})
                ) {
                    backdrop-filter: blur(${theme.menu.backgroundBlur});
                    background-color: ${theme.menu.blurBackgroundColor};
                }
            `}

            position: fixed;

            ${left !== undefined &&
            css`
                left: ${left}px;
            `}
            ${right !== undefined &&
            css`
                right: ${right}px;
            `}
            ${top !== undefined &&
            css`
                top: ${top}px;
            `}
            ${bottom !== undefined &&
            css`
                bottom: ${bottom}px;
            `}
            z-index: 5001;

            display: block;
            opacity: 1;

            ${scrollbars}
        `
    }}
`
