import React from "react"
import styled, { css } from "styled-components"
import Icon from "../Icon"

const ButtonContent = styled.span``

export interface ButtonProps {
    ref?: React.Ref<HTMLButtonElement> | null
    forwardedRef?: React.Ref<HTMLButtonElement> | null
    className?: string
    children?: React.ReactNode
    onClick?: () => void
    tooltip?: string
    disabled?: boolean
    tabIndex?: number
    testId?: string
    focus?: boolean

    primary?: boolean
    secondary?: boolean
    cta?: boolean
    subtle?: boolean
    icon?: JSX.Element
    iconOnly?: boolean
    outline?: boolean
    danger?: boolean
}

const Button: React.FC<ButtonProps> = ({
    forwardedRef,
    className,
    children,
    onClick,
    tooltip,
    icon,
    disabled = false,
    tabIndex,
    testId = `button`,
    focus = false,
}) => {
    function _handleClick() {
        onClick && onClick()
    }

    return (
        <button
            ref={forwardedRef}
            data-testid={testId}
            className={className}
            onClick={_handleClick}
            title={tooltip}
            disabled={disabled}
            tabIndex={tabIndex}
            autoFocus={focus}
        >
            {icon}
            {children && <ButtonContent>{children}</ButtonContent>}
        </button>
    )
}

const _Button: React.ComponentType<ButtonProps> = React.forwardRef(
    (props: ButtonProps, ref?: React.Ref<HTMLButtonElement>) => (
        <Button {...props} forwardedRef={ref} />
    )
)

export default styled(_Button)<ButtonProps>`
    ${({
        theme,
        primary = false,
        secondary = false,
        cta = false,
        disabled = false,
        icon = null,
        subtle = false,
        outline = false,
        iconOnly = false,
        danger = false,
    }) => css`
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        outline: none;
        vertical-align: middle;

        ${icon &&
        css`
            display: flex;
            justify-content: center;

            ${ButtonContent} {
                margin-left: 10px;
            }
        `}

        ${theme.button.default};

        &:focus {
            ${theme.controlFocus};
        }

        ${!disabled &&
        css`
            &:hover {
                ${theme.button.hover};
            }
        `}

        ${primary && theme.button.primary}
        ${secondary && theme.button.secondary}
        ${cta && theme.button.cta}
        ${subtle && theme.button.subtle}
        ${iconOnly && theme.button.iconOnly}
        ${outline && theme.button.outline}
        ${danger && theme.button.danger}

        ${disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}

        transition: opacity 0.2s linear;
    `}
`
