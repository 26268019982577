import { gql, useLazyQuery } from "@apollo/client"
import { useDelayedEffect } from "../../../Hooks"

const IS_EMAIL_SIGNED_UP = gql`
    query isEmailSignedUp($email: String!) {
        isEmailSignedUp(email: $email)
    }
`

interface DataResult {
    isEmailSignedUp?: boolean
}

function useIsEmailSignedUp({ email }: { email: string | null }) {
    const [query, state] = useLazyQuery<DataResult>(IS_EMAIL_SIGNED_UP)

    useDelayedEffect(
        () => {
            if (email !== null) {
                query({
                    variables: {
                        email,
                    },
                })
            }
        },
        300,
        [email]
    )

    return {
        error: state.error || null,
        loading: state.loading,
        isSignedUp: state.data?.isEmailSignedUp === true,
    }
}

export default useIsEmailSignedUp
