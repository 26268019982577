import React, { useState } from "react"
import styled, { css } from "styled-components"

interface IControl {
    testId: string
    disabled: boolean
}

const Control = styled.label<{ "data-testid"?: string; disabled?: boolean }>`
    ${({ theme, disabled = false }) => css`
        display: inline-flex;
        align-items: center;
        ${theme.radioButton.label};

        ${disabled && theme.radioButton.disabled};
    `}
`

const HiddenRadioButton = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

const RadioButtonLabel = styled.span`
    margin-left: 10px;
`

const RadioButtonControl = styled.span<{ focused: boolean }>`
    ${({ theme, focused = false }) => css`
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        ${theme.radioButton.control};

        ${focused && theme.controlFocus}
    `}
`

const Radiomark = styled.span<{ checked: boolean }>`
    ${({ theme }) => css`
        display: block;
        ${theme.radioButton.radiomark};
    `}
`

interface RadioButtonProps {
    ref?: React.Ref<HTMLLabelElement> | null
    forwardedRef?: React.Ref<HTMLLabelElement> | null
    className?: string
    onChange?: (selected: string) => void
    name?: string
    value?: string
    label?: string
    disabled?: boolean
    selected?: boolean
    testId?: string
}

const RadioButton: React.FC<RadioButtonProps> = ({
    forwardedRef,
    className,
    selected = false,
    name,
    value,
    label = null,
    onChange,
    disabled = false,
    testId = `radio-button`,
}) => {
    const [focused, setFocused] = useState(false)

    function _handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange && onChange(event.target.value)
    }

    function _handleFocus() {
        setFocused(true)
    }

    function _handleBlur() {
        setFocused(false)
    }

    return (
        <Control
            ref={forwardedRef}
            className={className}
            data-testid={testId}
            disabled={disabled}
        >
            <HiddenRadioButton
                name={name}
                type="radio"
                value={value}
                checked={selected}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                disabled={disabled}
            />
            <RadioButtonControl focused={focused}>
                <Radiomark checked={selected} />
            </RadioButtonControl>
            {label && <RadioButtonLabel>{label}</RadioButtonLabel>}
        </Control>
    )
}

const _RadioButton: React.ComponentType<RadioButtonProps> = React.forwardRef(
    (props: RadioButtonProps, ref?: React.Ref<HTMLLabelElement>) => (
        <RadioButton {...props} forwardedRef={ref} />
    )
)

export default styled(_RadioButton)<RadioButtonProps>``
