import { useCollectionViewState } from "../../Providers/CollectionViewProvider"
import { useCollectionConfig } from "./useCollectionConfig"
import { CollectionViewConfig } from "./types"

export function useCollectionView(): CollectionViewConfig | null {
    const collectionViewState = useCollectionViewState()
    const config = useCollectionConfig(
        collectionViewState?.collectionUUID || null
    )
    const findViewName = collectionViewState?.activeViewState?.viewName

    let activeView: CollectionViewConfig | null = null

    function findMatchingView(view: CollectionViewConfig) {
        if (view.name === findViewName) {
            activeView = view
        } else {
            // Search the sub views
            if (view.subViews) {
                view.subViews.forEach(findMatchingView)
            }
        }
    }

    // Find the active view
    config?.views?.forEach(findMatchingView)

    return activeView
}
