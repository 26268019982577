import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
// Add a save keybinding: https://draftjs.org/docs/advanced-topics-key-bindings/
import NoteEditor from "./NoteEditor"
import { Note as NoteType } from "../../Data/Note"
import { useNote } from "../../Providers/NoteProvider"
import { useNotesManager } from "../../Providers/NotesManagerProvider"
import { useBacklinks } from "../../Data/Backlinks"
import { useCollectionViewDispatch } from "../../Providers/CollectionViewProvider"
import scrollbars from "../../Styles/scrollbars"
import { ContentState } from "draft-js"
import { ButtonMenu, Button, Icon, MenuItem } from "ui"
import NoteMenuOverflow from "./NoteOverflowMenu"
import DeleteNoteConfirmation from "./DeleteNoteConfirmation"
import ContentPreview from "./ContentPreview"
import NoteDeletedCard from "./NoteDeletedCard"

const MoreButton = styled(Button)`
    opacity: 0.3;
    transition: opacity 0.2s linear;

    &:hover {
        opacity: 1;
    }
`

const BacklinkButton = styled(Button)`
    opacity: 0.3;
    transition: opacity 0.2s linear;

    &:hover {
        opacity: 1;
    }
`

const ButtonToolbar = styled.div`
    position: relative;
    z-index: 100;
    right: 0;
    height: 0;
    overflow: visible;
    text-align: right;
    // margin-bottom: -28px;

    ${MoreButton}, ${BacklinkButton} {
        display: inline-block;
        margin: 10px 10px 0px 0px;
    }
`

const NoteCard = styled.div<{ showPreview: boolean }>`
    ${({ theme, showPreview }) => css`
        ${theme.card};

        max-width: ${showPreview ? `1200px` : `600px`};
        width: 100%;

        display: inline-grid;
        grid-template-rows: auto;

        min-height: 300px;
        max-height: 100%;
        height: 100%; // This enables scrollbars on children to render

        overflow: hidden; // This makes the child scrollbars render and it not overflow
    `}
`

const ContentLayout = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: auto;
    overflow: hidden;
    // max-height: 100%;
    // overflow-y: auto;
`

const EditorPane = styled.div`
    overflow-y: auto;
    ${scrollbars};

    ${NoteEditor} {
        padding: 40px 40px 40px 50px;
    }
`

interface NoteProps {
    enablePreview?: boolean
}

const Note: React.FC<NoteProps> = ({ enablePreview = true }) => {
    const notesManager = useNotesManager()
    const { state } = useNote()
    const [focusTrigger, setFocusTrigger] = useState(0)
    const backlinks = useBacklinks(state.note?.title || null)
    const collectionViewDispatch = useCollectionViewDispatch()

    const [confirmDelete, setConfirmDelete] = useState(false)

    // const contentStateToReloadWith = useMemo(() => {
    //     if (note.state.triggers.reloadEditor !== 0) {
    //         console.log(`reloading with new body`)
    //         return note.state.noteBody.body
    //     } else {
    //         return null
    //     }
    // }, [note.state.triggers.reloadEditor])

    // Watch for external changes to the note body
    // useEffect(() => {
    //     if (state.reloadTrigger !== 0) {
    //         console.log(`>>> External change to note body`, state.reloadTrigger)
    //     }
    // }, [state.reloadTrigger])

    if (!state.noteBody || !state.note) {
        if (state.uuid !== null) {
            return <NoteDeletedCard />
        } else {
            return null
        }
    }

    function handleChange(contentState: ContentState) {
        if (
            notesManager === null ||
            state.note === null ||
            state.view === null
        ) {
            return
        }

        notesManager.updateNoteContentState({
            // Add the view so we don't get the event back and cause corruption
            view: state.view.id,
            uuid: state.note.uuid,
            contentState,
        })
    }

    function handleNoteClick() {
        setFocusTrigger(focusTrigger + 1)
    }

    // function handleDeleteNote() {
    //     if (notesManager === null || state.note === null) {
    //         return
    //     }

    //     notesManager.deleteNote(state.note.uuid)
    // }

    function navigateToNote(targetNote: NoteType) {
        collectionViewDispatch({ type: `openNote`, uuid: targetNote.uuid })
    }

    function handleDelete() {
        setConfirmDelete(true)
    }

    function handleDeleteCancel() {
        setConfirmDelete(false)
    }

    function handleDeleteConfirm() {
        if (notesManager !== null && state.note !== null) {
            notesManager.deleteNote({ uuid: state.note.uuid })
            setConfirmDelete(false)
        }
    }

    const showPreview = enablePreview && !!state.noteBody.previewUrl
    const previewUrl = (showPreview && state.noteBody.previewUrl) || null

    return (
        <NoteCard showPreview={showPreview}>
            <ContentLayout>
                <ContentPreview url={previewUrl} />

                <EditorPane onClick={handleNoteClick}>
                    <ButtonToolbar>
                        {backlinks.length > 0 && (
                            <ButtonMenu
                                button={
                                    <BacklinkButton
                                        icon={<Icon name="link" size="24px" />}
                                        secondary
                                        iconOnly
                                    ></BacklinkButton>
                                }
                            >
                                {backlinks.map((note) => (
                                    <MenuItem
                                        key={note.uuid}
                                        onClick={() => navigateToNote(note)}
                                        maxWidth={300}
                                    >
                                        {note.title}
                                    </MenuItem>
                                ))}
                            </ButtonMenu>
                        )}
                        <ButtonMenu
                            button={
                                <MoreButton
                                    icon={
                                        <Icon
                                            name="more-horizontal"
                                            size="24px"
                                        />
                                    }
                                    secondary
                                    iconOnly
                                ></MoreButton>
                            }
                        >
                            <NoteMenuOverflow onDelete={handleDelete} />
                        </ButtonMenu>
                    </ButtonToolbar>

                    <NoteEditor
                        key={state.note.uuid}
                        state={state.noteBody.body}
                        focusTrigger={focusTrigger}
                        reloadTrigger={state.reloadTrigger}
                        onChange={handleChange}
                    />
                </EditorPane>
            </ContentLayout>

            {confirmDelete && (
                <DeleteNoteConfirmation
                    onCancel={handleDeleteCancel}
                    onDelete={handleDeleteConfirm}
                />
            )}
        </NoteCard>
    )
}

export default Note
