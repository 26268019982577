import React, { useState, useRef } from "react"
import styled from "styled-components"

import { RGBAColor, ThumbProps } from "./types"

import _Thumb from "./Thumb"
import Picker from "./Picker"

import { useElementBounds } from "../../../Hooks"

interface Props {
    className?: string
    color: RGBAColor
    onChange?: (color: RGBAColor) => void
    Thumb?: React.FC<ThumbProps>
    testId?: string
}

const ColorPicker: React.FC<Props> = ({
    className,
    color: _color = { r: 0, g: 0, b: 0, a: 1 },
    onChange,
    Thumb = _Thumb,
    testId = `color-picker`,
}) => {
    const controlRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [color, setColor] = useState(_color)
    const controlBounds = useElementBounds(controlRef, isOpen)

    function _handleThumbClick() {
        setIsOpen(!isOpen)
    }

    function _handleClose() {
        setIsOpen(false)
    }

    function _handleChange(col: RGBAColor) {
        setColor(col)
        onChange && onChange(col)
    }

    return (
        <div className={className} data-testid={testId}>
            <Thumb ref={controlRef} onClick={_handleThumbClick} color={color} />
            {controlBounds && (
                <Picker
                    x={controlBounds.x}
                    y={controlBounds.y + controlBounds.height}
                    color={color}
                    visible={isOpen}
                    onClose={_handleClose}
                    onChange={_handleChange}
                />
            )}
        </div>
    )
}

export default styled(ColorPicker)<Props>`
    height: 100%;
`
