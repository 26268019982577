import React, { useState } from "react"
import styled from "styled-components"
import {
    FormField,
    TextInput,
    Icon,
    Button,
    Text,
    ErrorMessage as _ErrorMessage,
} from "ui"
import { useAccountForm } from "../../Hooks"
import Link from "../../../Link"
import routes from "../../../../routes"

const SendResetButton = styled(Button)`
    width: 100%;
`

const ErrorMessage = styled(_ErrorMessage)`
    margin-top: 20px;
`

const SendReset: React.FC = () => {
    const [{ email }, dispatch] = useAccountForm()

    function _handleReset() {
        // TODO
        return
    }

    function _handleEmailChange(value: string) {
        dispatch({
            type: `set`,
            field: `email`,
            value: value,
        })
    }

    let resetButton
    if (email.status === `processing`) {
        resetButton = (
            <SendResetButton
                primary
                cta
                disabled
                icon={
                    <Icon
                        name="spinner-circle"
                        size="18px"
                        subtle
                        spin
                        spinSpeed={1}
                    />
                }
            >
                Processing
            </SendResetButton>
        )
    } else {
        resetButton = (
            <SendResetButton primary cta onClick={_handleReset}>
                Send password reset email
            </SendResetButton>
        )
    }

    return (
        <>
            <h1>Forgot your password</h1>

            <Text>
                Enter your email address below and if we have an account on that
                email address, we’ll send instructions to reset your password.
            </Text>

            {email.error && <ErrorMessage>{email.error}</ErrorMessage>}

            <FormField label="Email address">
                <TextInput
                    name="email"
                    value={email.value}
                    onChange={_handleEmailChange}
                    disabled={email.status === `processing`}
                />
            </FormField>

            {resetButton}

            <Text secondary centered>
                <Link to={routes.signin}>Return to sign in</Link>
            </Text>
        </>
    )
}

export default SendReset
