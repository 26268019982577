import { RawDraftContentState } from "draft-js"

interface NoteMetadata {
    title: string
    preview?: string
}

export default function extractMetadataFromNoteBody(
    body: RawDraftContentState
): NoteMetadata {
    let title = ``
    let preview

    // The title is the first 'header-one' block type
    title = body.blocks.find((block) => block.type === `header-one`)?.text || ``

    // The preview is the first 1024 of the joined non header1 content
    preview = body.blocks
        .filter((block) => block.type !== `header-one`)
        .map((block) => block.text || ``)
        .join(` `)
        .substr(0, 1024)

    return { title, preview }
}
