import React, { useReducer, useEffect } from "react"
import { UUID } from "../../Data/UUID"
import produce, { Draft } from "immer"

import { useLocalStorage } from "../../Hooks"

interface State {
    noteListVisible: boolean
    selectedCollectionUUID: UUID | null
}

type Action =
    | { type: "showNoteList" }
    | { type: "hideNoteList" }
    | { type: "setSelectedCollection"; uuid: UUID }

type Dispatch = (action: Action) => void

const defaultState: State = {
    noteListVisible: true,
    selectedCollectionUUID: null,
}

export const StateContext = React.createContext<State>(defaultState)
export const DispatchContext = React.createContext<Dispatch>(() => {
    return
})

function reducer(draft: Draft<State>, action: Action) {
    switch (action.type) {
        case `showNoteList`:
            draft.noteListVisible = true
            break
        case `hideNoteList`:
            draft.noteListVisible = false
            break
        case `setSelectedCollection`:
            draft.selectedCollectionUUID = action.uuid
            break
        default:
            console.log(`Unknown action`, action)
            throw new Error(`Unknown action, see console for details`)
    }
    return draft
}

const immerReducer = produce(reducer)

interface Props {
    initialState?: State
}

export const Provider: React.FC<Props> = ({
    children,
    initialState = defaultState,
}) => {
    const [savedLayout, setSavedLayout] = useLocalStorage<State>(
        `savedLayout`,
        initialState
    )
    const [state, dispatch] = useReducer(immerReducer, savedLayout)

    // When the active collection changes, save it in the local storage so we can load it
    // again when we open the browser again
    useEffect(() => {
        setSavedLayout(state)
    }, [state])

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}
