function getVimeoVideoIDFromURL(url: string) {
    let videoID: string | null = null
    let regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/
    let match = url.match(regEx)
    if (match && match.length == 7) {
        videoID = match[6]
    }
    return videoID
}

export default getVimeoVideoIDFromURL
