import rawContentStateToMarkdown from "./rawContentStateToMarkdown"
import noteToYaml from "./noteToYaml"
import { Note, NoteBody } from "../Data/Note"

function exportNoteToMarkdown(note: Note, noteBody: NoteBody | null = null) {
    let output = ``
    const config = noteToYaml(note)
    if (config !== null) {
        output += `---\n`
        output += `${config}`
        output += `---\n\n`
    }

    if (noteBody !== null) {
        output += rawContentStateToMarkdown(noteBody.body)
    }

    return output
}

export default exportNoteToMarkdown
