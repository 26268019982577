import React from "react"
import styled, { css } from "styled-components"

// type Props = React.HTMLProps<HTMLDivElement> & {
//     index?: number
// }

interface Props {
    index: number
}

const _Track = styled.div<Props>`
    ${({ theme, index }) => css`
        top: 0;
        bottom: 0;
        background: ${index === 0
            ? theme.slider.trackValueColor
            : theme.slider.trackNonValueColor};
        border-radius: 2px;
    `}
`

const Track = (
    props: React.HTMLProps<HTMLDivElement>,
    state: { index: number; value: number | number[] }
) => {
    // @ts-ignore - can't get the props types to pass through correctly
    return <_Track {...props} index={state.index} />
}

export default Track
