import React, { useState, useRef, useEffect, useMemo } from "react"
import styled, { css } from "styled-components"
import { DraftHandleValue, DraftEditorCommand } from "draft-js"
import {
    LookupSuggestionsModalProps,
    RegisterKeyBindingFn,
    CleanupFn,
} from "../../../RelatedEntity"

import scrollbars from "../../../../Styles/scrollbars"

import List from "../../../../Components/List"
import { addNoteLink } from "../../Modifiers"
import { SuggestionStore } from "../../../RelatedEntity"

import Menu from "./Menu"
import NoteItem from "../../../../Components/NoteItem"

import { useFilteredNotes } from "../../../../Providers/FilteredNotesProvider"
import { UUID } from "../../../../Data/UUID"
import { Note } from "../../../../Data/Note"

// function reducer(state: State, action: Action) {
//     console.log(`action`, action)
//     if (action.type === `ON_SHOW`) {
//         // Deselect any items
//         return { ...state, selectedIndex: -1 }
//     } else if (action.type === `SELECT_PREVIOUS`) {
//         return { ...state, selectedIndex: Math.max(0, state.selectedIndex - 1) }
//     } else if (action.type === `SELECT_NEXT`) {
//         return {
//             ...state,
//             selectedIndex: Math.min(
//                 state.items.length - 1,
//                 state.selectedIndex + 1
//             ),
//         }
//     } else if (action.type === `SET_SELECTED_ITEMS`) {
//         return {
//             ...state,
//             selectedIndex: -1,
//             items: action.items,
//         }
//     } else if (action.type === `SET_SELECTED`) {
//         return {
//             ...state,
//             selectedIndex: Math.min(state.items.length - 1, action.index),
//         }
//     } else {
//         console.error(`Invalid reducer action`, action)
//         throw new Error(`Invalid reducer action, see console for details`)
//     }
// }

type NoteLinkSuggestionsModalProps = LookupSuggestionsModalProps

// TODO: Callback to add the entity after it's been selected
const Suggestions: React.FC<NoteLinkSuggestionsModalProps> = ({
    visible,
    left,
    top,
    searchValue,
    registerKeyBindingCallback,
    store,
    onClose,
}) => {
    const [selectedUUID, setSelectedUUID] = useState<UUID | null>(null)
    const filteredNotes = useFilteredNotes()

    const dialogRef = useRef<HTMLDivElement | null>(null)
    const stateRef = useRef<{ selectedUUID: string | null; notes: UUID[] }>({
        selectedUUID: null,
        notes: [],
    })

    // Keep the state ref in sync
    stateRef.current = {
        selectedUUID,
        notes: filteredNotes.list,
    }

    useEffect(() => {
        if (dialogRef.current === null) {
            return
        }

        let cleanup: CleanupFn | null = null

        if (visible) {
            cleanup = registerKeyBindingCallback(handleKey, handleKeyReturn)
            setSelectedUUID(null) // Deselect any item

            // Scroll the dialog to the top in case we're showing it a second time
            dialogRef.current.scrollTop = 0
        }

        return () => {
            cleanup && cleanup()
        }
    }, [visible])

    // Update the search value
    useEffect(() => {
        filteredNotes.dispatch({
            type: `setSearchText`,
            searchText: searchValue,
        })
    }, [searchValue, filteredNotes.dispatch])

    function handleKey(
        keyboardEvent: React.KeyboardEvent
    ): DraftEditorCommand | null {
        console.log(`Key`, keyboardEvent.key)
        if (keyboardEvent.key === `ArrowDown`) {
            handleDownArrow(keyboardEvent)
        } else if (keyboardEvent.key === `ArrowUp`) {
            handleUpArrow(keyboardEvent)
            keyboardEvent.preventDefault()
        } else if (keyboardEvent.key === `Escape`) {
            handleEscapeKey(keyboardEvent)
        } else if (keyboardEvent.key === `Tab`) {
            handleTabKey(keyboardEvent)
        }
        console.log(`return null`)
        return null
    }

    function handleDownArrow(keyboardEvent: React.KeyboardEvent) {
        keyboardEvent.preventDefault()

        const uuid = stateRef.current.selectedUUID
        const notes = stateRef.current.notes
        const selectedIndex = notes.findIndex((n) => n === uuid)
        if (selectedIndex < notes.length - 1) {
            setSelectedUUID(notes[selectedIndex + 1])
        }
    }

    function handleUpArrow(keyboardEvent: React.KeyboardEvent) {
        keyboardEvent.preventDefault()

        const uuid = stateRef.current.selectedUUID
        const notes = stateRef.current.notes
        const selectedIndex = notes.findIndex((n) => n === uuid)
        if (selectedIndex > 0) {
            setSelectedUUID(notes[selectedIndex - 1])
        }
    }

    function handleEscapeKey(keyboardEvent: React.KeyboardEvent) {
        keyboardEvent.preventDefault()
        onClose && onClose()
    }

    function handleTabKey(keyboardEvent: React.KeyboardEvent) {
        keyboardEvent.preventDefault()
    }

    function handleKeyReturn(event: React.KeyboardEvent): DraftHandleValue {
        if (commitSelection()) {
            // Commit the active selection
            console.log(`Returning handled`)
            return `handled`
        } else {
            console.log(`Returning not handled`)
            return `not-handled`
        }
    }

    // Removed as it's selecting the item if the mouse happened to be in the right position
    // Also confused scrolling
    // function handleHover(note: PermanentNote) {
    //     console.log(`hover!!!`, note)
    //     dispatch({ type: `SET_SELECTED`, index: state.items.indexOf(note) })
    // }

    function commitSelection() {
        // Can't reference state here because it's stale
        const editorState = store.getEditorState()
        if (stateRef.current.notes.length === 0 || editorState === null) {
            return false
        }

        const noteUUID =
            stateRef.current.notes.find(
                (n) => n === stateRef.current.selectedUUID
            ) || null

        if (noteUUID === null) {
            return false
        }

        // Now get the full note and create the link
        filteredNotes.state.view?.get(noteUUID).then((note) => {
            if (note !== null) {
                const newEditorState = addNoteLink({
                    editorState,
                    note,
                })
                store.setEditorState(newEditorState)
            }
        })

        return true
    }

    function handleNoteSelect(note: Note) {
        // Can't reference state here because it's stale
        const editorState = store.getEditorState()
        if (editorState === null) {
            return
        }

        const newEditorState = addNoteLink({
            editorState,
            note,
        })
        store.setEditorState(newEditorState)
    }

    return (
        <Menu ref={dialogRef} visible={visible} left={left} top={top}>
            <List>
                {filteredNotes.list.map((noteUUID) => (
                    <NoteItem
                        key={noteUUID}
                        uuid={noteUUID}
                        selected={noteUUID === selectedUUID}
                        onSelect={handleNoteSelect}
                    />
                ))}
            </List>
        </Menu>
    )
}

export default Suggestions
