import React, { useEffect, useState } from "react"
import { useNotesManager } from "../NotesManagerProvider"
import { Collection } from "../../Data/Collection"
import { FocusModeSession } from "../../Managers/NotesManager/FocusModeSessionView"

export const StateContext = React.createContext<FocusModeSession[]>([])

export const FocusModeSessionsProvider: React.FC = ({ children }) => {
    const notesManager = useNotesManager()
    const [sessions, setSessions] = useState<FocusModeSession[]>([])

    useEffect(() => {
        if (notesManager === null) {
            return
        }

        // Hack to avoid making it read only for the note manager
        function updateSessions(sessions: FocusModeSession[]) {
            setSessions(JSON.parse(JSON.stringify(sessions)))
        }

        const focusModeView = notesManager.focusMode

        const unsubscribes: Array<() => void> = []

        // Now get all the session views
        focusModeView.sessions().then((sessionViews) => {
            Promise.all(sessionViews.map((view) => view.session())).then(
                (sessions) => {
                    updateSessions(sessions)
                }
            )

            // Go through each view and watch the list. We'll just update the whole thing
            // again whenever it changes
            sessionViews.forEach((view) => {
                const unsubscribe = view.watchList(() => {
                    // Fetch all the session again
                    Promise.all(
                        sessionViews.map((view) => view.session())
                    ).then((sessions) => {
                        updateSessions(sessions)
                    })
                })

                unsubscribes.push(unsubscribe)
            })
        })

        return () => {
            unsubscribes.forEach((fn) => fn())
        }
    }, [notesManager])

    // TODO
    // This will query the notes manager for a view of all the focus sessions
    // This needs a focus session list
    // Each focus session list then has a view of each focus session (notes in that list)
    // That final list is stored using useState and passed down to the rendering components
    // A hook can be used to get the focus sessions for a given collection

    // const [collections, setCollections] = useState<Collection[]>([])

    // // Get the collections
    // useEffect(() => {
    //     if (notesManager === null) {
    //         return
    //     }
    //     const collections = notesManager.collections.then((list) => {})
    //     setCollections(collections || [])

    //     // Watch for changes
    //     return notesManager.collections.watchList((list) => {})
    // }, [notesManager])

    return (
        <StateContext.Provider value={sessions}>
            {children}
        </StateContext.Provider>
    )
}
