import { UUID } from "../../Data/UUID"
import { ListEventCallback, ItemEventCallback, Item } from "../types"
import { v4 as uuid } from "uuid"

abstract class ObservableItemList<TItem extends Item, TListEntry> {
    private listListeners: Set<ListEventCallback<TListEntry>> = new Set()
    private itemListeners: Map<UUID, Set<ItemEventCallback<TItem>>> = new Map()
    private viewID: UUID

    public constructor() {
        this.viewID = uuid()
    }

    get id() {
        return this.viewID
    }

    public watchList(callback: ListEventCallback<TListEntry>) {
        this.listListeners.add(callback)
        return () => {
            this.listListeners.delete(callback)
        }
    }

    public watchItem(uuid: UUID, callback: ItemEventCallback<TItem>) {
        let listenerSet = this.itemListeners.get(uuid)
        if (listenerSet === undefined) {
            listenerSet = new Set()
            this.itemListeners.set(uuid, listenerSet)
        }
        listenerSet.add(callback)
        return () => {
            listenerSet?.delete(callback)
            if (listenerSet?.size === 0) {
                // Cleanup the item completely
                this.itemListeners.delete(uuid)
            }
        }
    }

    public fireListChangeEvent(list: TListEntry[], isSelfUpdate: boolean) {
        // Clone the list coming out of here so React can update
        const clonedList = [...list]
        this.listListeners.forEach((cb) => cb(clonedList, isSelfUpdate))
    }

    public fireItemUpdatedEvent(item: TItem, isSelfUpdate: boolean) {
        // Clone the items coming out of here so React can update
        const clonedItem = { ...item }
        const callbacks = this.itemListeners.get(item.uuid)
        callbacks?.forEach((cb) => cb(clonedItem, `updated`, isSelfUpdate))
    }

    public fireItemDeletedEvent(item: TItem, isSelfUpdate: boolean) {
        // Clone the items coming out of here so React can update
        const clonedItem = { ...item }
        const callbacks = this.itemListeners.get(item.uuid)
        callbacks?.forEach((cb) => cb(clonedItem, `deleted`, isSelfUpdate))
    }
}

export { ObservableItemList }
