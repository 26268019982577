import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

import Input from "../../Input"
import Button from "../../Button"
import Icon from "../../Icon"

interface Props {
    ref?: React.Ref<HTMLDivElement> | null
    forwardedRef?: React.Ref<HTMLDivElement> | null
    className?: string
    placeholder?: string
    icon?: JSX.Element
    value?: string
    error?: boolean
    onChange: (value: string) => void
    onMenuOpen: () => void
}

const Control: React.FC<Props> = ({
    forwardedRef,
    className,
    placeholder,
    icon,
    value: _value = ``,
    onChange,
    onMenuOpen,
}) => {
    const [value, setValue] = useState(_value)

    useEffect(() => {
        if (value !== _value) {
            setValue(_value)
        }
    }, [_value])

    function _handleBlur() {
        onChange && onChange(value)
    }

    return (
        <div ref={forwardedRef} className={className}>
            <Input
                placeholder={placeholder}
                value={value}
                icon={icon}
                iconPosition="left"
                onChange={setValue}
                onBlur={_handleBlur}
            />
            <Button onClick={onMenuOpen}>
                <div>
                    <Icon name="arrow-up-s" solid size="16px" />
                    <Icon name="arrow-down-s" solid size="16px" />
                </div>
            </Button>
        </div>
    )
}

const _Control: React.ComponentType<Props> = React.forwardRef(
    (props: Props, ref?: React.Ref<HTMLDivElement>) => (
        <Control {...props} forwardedRef={ref} />
    )
)

export default styled(_Control)<Props>`
    ${({ theme, error = true }) => css`
        width: 100%;
        background-color: ${theme.select.backgroundColor};
        border: ${error ? theme.select.errorBorder : theme.select.border};
        font-size: ${theme.select.fontSize};
        color: ${theme.select.color};
        text-align: left;

        display: grid;
        grid-template-columns: auto max-content;

        ${Input} {
            padding: ${theme.select.controlPadding};
        }

        ${Button} {
            > div {
                color: ${theme.select.controlColor};

                ${Icon} {
                    display: block;
                    line-height: 6px;
                }
            }
        }
    `}
`
