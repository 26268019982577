import React, { useRef } from "react"
import styled from "styled-components"
import { DecoratorProps } from "../../../RelatedEntity"
import { useNoteLinkState } from "../../NoteLinkState"
import {
    useCollectionViewState,
    useCollectionViewDispatch,
} from "../../../../Providers/CollectionViewProvider"
import { useNotesManager } from "../../../../Providers/NotesManagerProvider"

const getNodeText = (node: any): string => {
    if (typeof node.props?.text === `string`) {
        return node.props.text
    }

    if (node instanceof Array) {
        return node.map(getNodeText).join(``)
    }

    if (typeof node === `object` && node) {
        // ts-ignore
        return getNodeText(node.props.children)
    }

    return ``
}

const DecoratorLink = styled.span`
    // color: #5e93c5;
    // cursor: pointer;
    // border: 1px solid rgba(255, 255, 255, 75);
    // border-radius: 14px;
    // display: inline-block;
    // padding: 5px;
`

const Brackets = styled.span`
    opacity: 0.2;
`

const Link = styled.a`
    color: #5e93c5;
    cursor: pointer;
`

const NoteLinkDecorator: React.FC<DecoratorProps> = ({
    children,
    entityKey,
    contentState,
}) => {
    const notesManager = useNotesManager()
    const viewState = useCollectionViewState()
    const viewDispatch = useCollectionViewDispatch()

    const collectionUUID = viewState?.collectionUUID || null

    const decorator = useRef<HTMLSpanElement>()
    const decoratorRef = (element: HTMLSpanElement): void => {
        decorator.current = element
    }
    const { dispatch: noteLinkDispatch } = useNoteLinkState()
    const linkText = getNodeText(children)

    function handleLinkClick(event: React.MouseEvent) {
        // When the link is clicked, we either want to open that referring note, or
        // prompt the user to create it on their behalf
        event.preventDefault()
        ;(async () => {
            if (notesManager === null || collectionUUID === null) {
                return
            }

            // Does the note exist?
            const notes = await notesManager.findNotesByTitle(
                collectionUUID,
                linkText
            )

            if (notes.length > 0) {
                // FIXME: If there's more then 1, ask the user to select the one they want
                viewDispatch({ type: `openNote`, uuid: notes[0].uuid })
            } else {
                // Note not found, so offer to create it
                if (decorator.current) {
                    const clientRect = decorator.current?.getBoundingClientRect()
                    noteLinkDispatch({
                        type: `showCreateNote`,
                        top: clientRect.bottom,
                        left: clientRect.left,
                        title: linkText,
                    })
                }
            }
        })()
    }

    return (
        <DecoratorLink ref={decoratorRef}>
            {/* <Brackets>[[</Brackets> */}
            <Link href="#" onClick={handleLinkClick}>
                {children}
            </Link>
            {/* <Brackets>]]</Brackets> */}
        </DecoratorLink>
    )
}

export default NoteLinkDecorator
