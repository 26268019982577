import React from "react"
import styled from "styled-components"

interface Props {
    className?: string
    children: React.ReactNode
}

const ErrorMessage: React.FC<Props> = ({ className, children }) => {
    return <div className={className}>{children}</div>
}

export default styled(ErrorMessage)`
    ${({ theme }) => theme.errorMessage}
`
