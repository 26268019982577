import {
    RawDraftContentState,
    genKey,
    RawDraftContentBlock,
    DraftBlockType,
} from "draft-js"
import extractConfigFromLines from "./extractConfigFromLines"
import { ImportedNote } from "./types"

class Block {
    private content: string
    private blockType: string

    constructor(content: string, blockType: string) {
        this.content = content
        this.blockType = blockType
    }

    addLine(line: string) {
        return false // Don't accept this line, it needs a new block
    }

    toBlock(genKeyFn?: () => string) {
        const block: RawDraftContentBlock = {
            key: genKeyFn === undefined ? genKey() : genKeyFn(),
            text: this.content,
            type: this.blockType,
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        }

        return block
    }
}

class HeadingBlock extends Block {
    private receivedMarginLine = false

    addLine(line: string) {
        if (line === ``) {
            if (this.receivedMarginLine) {
                return false // Already got our margin line
            } else {
                this.receivedMarginLine = true
                return true
            }
        } else {
            return false
        }
    }
}

// function createBlockFromLine(line: string, genKeyFn?: () => string) {
//     const { text, type, Block } = extractTextAndType(line)
//     const block = new Block(text, type)
//     // const block: RawDraftContentBlock = {
//     //     key: genKeyFn === undefined ? genKey() : genKeyFn(),
//     //     text: text,
//     //     type: type,
//     //     depth: 0,
//     //     inlineStyleRanges: [],
//     //     entityRanges: [],
//     //     data: {},
//     // }
//     return block
// }

function createBlockFromLine(line: string) {
    if (line.indexOf(`# `) === 0) {
        return new HeadingBlock(line.substr(2), `header-one`)
    } else if (line.indexOf(`## `) === 0) {
        return new HeadingBlock(line.substr(3), `header-two`)
    } else if (line.indexOf(`### `) === 0) {
        return new HeadingBlock(line.substr(4), `header-three`)
    } else if (line.indexOf(`#### `) === 0) {
        return new HeadingBlock(line.substr(5), `header-four`)
    } else if (line.indexOf(`##### `) === 0) {
        return new HeadingBlock(line.substr(6), `header-five`)
    } else if (line.indexOf(`###### `) === 0) {
        return new HeadingBlock(line.substr(7), `header-six`)
    } else {
        return new Block(line, `unstyled`)
    }
}

function importMarkdown(
    markdown: string,
    genKeyFn?: () => string
): ImportedNote {
    markdown = markdown.replace(/\r\n/g, `\n`)
    markdown = markdown.replace(/\r/g, `\n`)
    const lines = markdown.split(`\n`)

    const config = extractConfigFromLines(lines)

    let blocks: Block[] = []
    let currentBlock: Block | null = null
    lines.forEach((line) => {
        if (currentBlock !== null) {
            // Try to add line to the current block
            if (currentBlock.addLine(line)) {
                // The line was added to the current block
                return
            }
        }

        currentBlock = createBlockFromLine(line)
        blocks.push(currentBlock)
    })

    const content: RawDraftContentState = {
        blocks: blocks.map((block) => block.toBlock(genKeyFn)),
        entityMap: {},
    }

    return {
        config,
        content,
    }
}

export default importMarkdown
